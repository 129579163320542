.container {
  --progress-bar-width: 35px;
  --progress-bar-height: 35px;
  height: auto;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  .circular-progress {
    border-radius: 50%;
    display: flex;
    color: var(--red);
    align-items: center;
    justify-content: center;
    width: var(--progress-bar-width);
    height: var(--progress-bar-height);
  }
  .inner-circle {
    position: absolute;
    border-radius: 50%;
    background-color: lightgrey;
    width: calc(var(--progress-bar-width) - 10px);
    height: calc(var(--progress-bar-height) - 10px);
  }

  .percentage {
    position: relative;
    font-size: var(--fs-xs);
    color: rgb(0, 0, 0, 1);
  }

  .helper {
    top: 0;
    padding: 0px;
    position: absolute;
    cursor: pointer;
    width: 0px !important;
    background-color: gray;
    height: var(--progress-bar-height);
    border-top-right-radius: calc(var(--progress-bar-width) / 2);
    border-bottom-right-radius: calc(var(--progress-bar-width) / 2);
    left: calc(var(--progress-bar-width) - calc(var(--progress-bar-width) / 2));
    transition: all 0.2 ease-in-out;
    p {
      z-index: 2;
      display: none;
      color: var(--white);
      font-size: var(--fs-sm);
    }
  }

  .showHelper {
    display: flex;
    width: max-content !important;
    padding: 0px calc((var(--progress-bar-width) / 2) + 10px);
    justify-content: center;
    align-items: center;

    p {
      display: block;
    }
  }

  @media screen and (min-width: 641px) {
    --progress-bar-width: 50px;
    --progress-bar-height: 50px;

    .helper {
      font-size: var(--fs-sm);
    }
  }
}
