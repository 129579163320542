.padding-container {
  width: 100%;
  padding: 20px 10px;
  background-color: var(--second-white);
  padding-bottom: 0px !important;
  @media screen and (min-width: 641px) {
    padding: 20px;
  }
  @media screen and (min-width: 961px) {
    padding: 20px 40px;
  }
  @media screen and (min-width: 1281px) {
    padding: var(--gap-section) 60px;
  }
  @media screen and (min-width: 1400px) {
    padding: var(--gap-section) 80px;
  }
}

.blackBg {
  background-color: var(--bg-hero);
}

.primaryBg {
  background-color: var(--bg-primary);
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
}
