.tab-container {
  display: flex;
  z-index: 99;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: var(--border-radius);
  transform: translate(-50%, -50%) !important;
  color: var(--second-button-text) !important;
  background: var(--sub-heading) !important;
  box-shadow: 1px 2px 10px 1px rgb(128, 129, 145, 0.5) !important;
  .MuiAlert-message {
    width: 100%;
  }

  .MuiAlert-icon {
    align-items: center;
  }
  .tab-box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .tab-left {
      display: flex;
      max-width: 80%;
      font-weight: var(--fw-medium);
    }
    .tab-right {
      display: flex;
      min-width: 20%;
      .tab-btn {
        display: flex;
        width: 70px;
        height: 25px;
        margin: 0 5px;
        border: none;
        cursor: pointer;
        color: var(--white);
        align-items: center;
        justify-content: center;
        font-weight: var(--fw-medium);
        background: var(--brand-color);
        border-radius: var(--border-radius);

        &:hover {
          opacity: 0.8;
        }

        &:focus {
          outline: none;
        }
      }

      .tab-second-btn {
        color: var(--white);
        background: var(--menu-item);
      }
    }
  }
}
