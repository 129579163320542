.main {
  display: none;

  @media only screen and (min-width: 961px) {
    display: block;
  }

  @media screen and (min-width: 1025px) {
    display: block;
  }

  .drawer {
    width: 70px;
    height: 100vh;
    position: fixed;
    z-index: 99 !important;

    .drawer-open {
      width: 240px;
      padding: 0 10px;
      color: var(--text-color);
      background-color: var(--background);
    }

    .drawer-close {
      width: 70px;
      padding: 0 2px;
      overflow: hidden;
      color: var(--text-color);
      background-color: var(--background);
    }
  }
}
.list-item {
  display: flex;
  height: 50px;
  padding: 0px;
  color: inherit;
  padding: 0px !important;
  width: auto !important;
  margin: 5px 2px !important;
  justify-content: center !important;
  border-radius: var(--border-radius) !important;

  &:hover {
    background-color: var(--sidebar-tab-hover);
  }

  .item-icon {
    z-index: 1;
    align-items: center;
    color: inherit !important;
  }

  .item-text > span {
    display: flex;
    width: 100%;
    color: inherit;
    justify-content: space-between;
  }
}

.bottom {
  padding-left: 8px !important;
  padding-right: 8px !important;

  @media screen and (min-width: 641px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

.active {
  color: var(--white);
  background-color: var(--brand-color) !important;

  .item-icon {
    color: var(--white);
  }
}

.container {
  height: 100%;
  overflow: hidden;

  .top-list {
    overflow: hidden auto;
    height: calc(100% - 60px);

    ul {
      padding: 0 !important;
    }
  }
}

.item-icon {
  display: flex;
  line-height: 1;
  flex-direction: column;
  justify-content: center;
  color: inherit !important;
  align-items: center !important;

  .ms-ripple {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 33px;
    border-radius: 100%;
  }

  .ms-ripple span {
    position: relative;
  }

  .ms-ripple::after {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 3s;
    animation-delay: 0s;
    border: 8px solid #f3912e9c;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
  }

  .ms-ripple::before {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 3s;
    animation-delay: 0.3s;
    border: 8px solid rgba(240, 80, 70, 0.612);
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  }

  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale3d(0.75, 0.75, 1);
    }

    to {
      opacity: 0;
      transform: scale3d(1.5, 1.5, 1);
    }
  }

  > div > svg,
  > div > img {
    width: 18px;
    height: 18px;

    @media screen and (min-width: 1281px) {
      width: 20px;
      height: 20px;
    }
  }
}

.item-text {
  width: 55px;
  color: inherit;
  margin-top: 0px;
  line-height: 1.25;
  text-align: center;
  white-space: normal;
  padding-bottom: 3px;
  font-size: var(--fs-xxs);
}

.divider {
  width: 100%;
  height: 1px;
  margin-bottom: 8px;
  background-color: var(--border-color);
}

.divider-close {
  width: 55px;
  margin: 0 auto;
}

.heading {
  color: inherit;
  padding-left: 15px;
  font-size: var(--fs-sm);
  font-weight: var(--fw-medium);
}

.heading-close {
  display: flex;
  width: auto;
  height: auto;
  margin: 0 5px;
  color: inherit;
  padding: 3px 0;
  line-height: 1.5;
  overflow: hidden;
  text-align: center;
  align-items: center;
  white-space: normal;
  justify-content: center;
  font-size: var(--fs-xxs);
}

.creator-img {
  width: 25px !important;
  height: 25px !important;
  background-color: var(--avatar) !important;
}

.log-btn {
  display: flex;
  height: 60px;
  padding: 0px;
  cursor: pointer;
  align-items: center;
  width: auto !important;
  color: inherit !important;
  margin: 5px 2px !important;
  justify-content: center !important;
  border-radius: var(--border-radius) !important;
  border-top: 1px solid var(--border-color);
}

.navCard {
  display: grid;
  width: 100%;
  overflow: hidden;
  align-items: center;
  background: inherit;
  padding: 0 !important;
  box-shadow: var(--shadow);
  justify-content: space-evenly;
  grid-template-columns: repeat(5, auto);
  border: 1px solid var(--border-color);

  .item-icon {
    color: inherit !important;
    svg {
      width: 18px;
      color: inherit;
    }
  }

  &.landing-sidebar {
    color: var(--black);
    background-color: var(--white);
  }

  &.general-sidebar {
    color: var(--text-color);
    background-color: var(--background);
  }
}

.menu {
  display: flex;
  bottom: 30px;
  width: 100%;
  background: inherit;
  overflow: auto hidden;
  flex-direction: column;
  position: absolute !important;
  border-radius: 20px 20px 0 0;
  padding-bottom: 20px !important;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);

  &.landing-sidebar {
    color: var(--black);
    background-color: var(--white);
  }

  &.general-sidebar {
    color: var(--text-color);
    background-color: var(--background);
  }

  li {
    padding: 0;
    margin-top: -4px;
  }
}
