.react-datepicker-wrapper {
  background-color: var(--second-white);
  border-radius: var(--border-radius);
  border: none;
  width: 100%;
  /* z-index: 1000; */
}

.react-datepicker__year-wrapper {
  max-width: unset;
  /* z-index: 1000 !important; */
}

.react-datepicker__input-container {
  /* padding: 4px 4px; */
  display: flex;
  align-items: center;
  /* z-index: 1000 !important; */
}

.react-datepicker__input-container > svg {
  color: var(--background);
}

.react-datepicker__month,
.react-datepicker__year-text {
  /* padding: 2px; */
  width: max-content;
  /* z-index: 1000 !important; */
}

/* .react-datepicker__month {
  max-width: 280px;
  display: flex;
  flex-wrap: wrap;
} */

/* .react-datepicker__month-container {
} */

.react-datepicker-popper {
  z-index: 10000;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: red;
}
