.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  transition:
    visibility 0s linear 0s,
    opacity 0.25s 0s,
    transform 0.25s;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 320px;
    height: 350px;
    z-index: 9999;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    background: var(--background);
    transform: translate(-50%, -50%);

    .image-container {
      width: 100%;
      height: 59%;

      img {
        width: 100%;
        height: 100%;
        opacity: 1;
        object-fit: contain;
      }
    }

    .progressbar-container {
      width: 100%;
      height: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .progressbar {
        height: 100%;
        animation-duration: 4s;
        animation-name: progress;
        background-color: var(--brand-color);
      }

      @keyframes progress {
        from {
          width: 100%;
        }
        to {
          width: 0%;
        }
      }
    }

    .content {
      width: 100%;
      height: 40%;
      padding: 15px;

      h1 {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        color: var(--text-color);
        font-size: var(--fs-sm);
      }

      p {
        width: 100%;
        opacity: 0.8;
        text-align: center;
        color: var(--text-color2);

        > b {
          font-weight: var(--fw-medium);
        }
      }
    }

    .perks {
      width: 100%;
      margin-bottom: 10px;

      p {
        width: 100%;
        color: var(--text-color2);
        opacity: 0.6;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;

        .icon {
          color: greenyellow;
          margin-top: 3px;
        }
      }
    }

    button {
      width: 100%;
      padding: 10px;
      margin: 8px 0;
      border-radius: var(--border-radius);
      background-color: #fff3e0;
    }
  }
}
