.image-container {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: var(--background);
  border-bottom: 1px solid var(--light-shadow2);

  .dialog-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    transform: scale(0.9);
    // object-position: top left;
    // -webkit-box-shadow: inset 0px -3px 3px 0px var(--light-shadow);
    // -moz-box-shadow: inset 0px -3px 3px 0px var(--light-shadow);
    // box-shadow: inset 0px -3px 3px 0px var(--light-shadow);
  }
}

.progress-container {
  width: 100%;
  height: 1%;
  background-color: transparent;

  .progressbar {
    // width: 40%;
    height: 100%;
    background-color: var(--brand-color);
    transition: all 0.2s ease-in-out;
  }
}

.content {
  width: 100%;
  height: 35%;
  padding-top: 5px;

  .header {
    width: 100%;
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    justify-content: flex-start;

    h1 {
      font-size: 20px;
      text-transform: none;
    }
  }
  .points {
    width: 100%;
    p {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 7px;
      color: var(--text-color);
    }
  }
}

.footer {
  width: 100%;
  height: 15%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .back-btn {
      display: flex;
      gap: 3px;
      padding: 8px 0px;
      border-radius: 5px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: var(--text-color);

      .back-btn-icon {
        width: 20px;
        height: 20px;
        color: var(--text-color);
      }
    }
  }
  .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .next {
      padding: 8px 15px;
      border-radius: 5px;
      color: rgb(240, 240, 240);
      background-color: var(--brand-color);
    }
  }
}

// media queries
@media only screen and (max-width: 420px) {
  .image-container {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .dialog-image {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
  .content {
    .header {
      h1 {
        font-size: 18px;
      }
    }

    p {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 641px) {
  .content {
    .header {
      h1 {
        font-size: 20px;
      }
    }
    .points {
      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .content {
    .header {
      h1 {
        font-size: 22px;
      }
    }
    .points {
      p {
        font-size: 15px;
      }
    }
  }
}
