.feedback {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.sf-header {
  flex-direction: row;
  align-items: center;
}

.sf-right-side-btns {
  display: flex;
  align-items: center;
}

.sf-textfield-row {
  display: flex;
  flex-direction: column;
}

.sf-textfield {
  padding: 10px;
  border-radius: var(--border-radius);
  background-color: var(--second-white);
}

.sf-textfield,
.sf-textfield > input {
  border: none;
  outline: none;
  line-height: 20px;
  color: var(--heading);
  border: var(--border);
}

.sf-textfield input {
  color: var(--text-color);
}

.sf-textfield input:focus {
  border: none;
}

.sf-msg-cont {
  width: 100%;
  height: 100px;
  padding: 10px;
  line-height: 20px;
  color: var(--heading);
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--second-white);
}

.sf-btns-cont {
  display: flex;
  gap: var(--gap-md);
  margin: 10px 0;
  align-items: center;
  justify-content: flex-end;
}

.sf-msg-btn,
.sf-msg-btn1 {
  height: 30px;
  min-width: 100px;
  padding: 0 1rem;
  font-size: var(--fs-xs);
  color: var(--background);
  background-color: var(--heading);
  cursor: not-allowed;
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  transition: 0.15s ease;
  opacity: 0.5;
}

.sf-msg-btn {
  color: var(--white);
  background-color: var(--brand-color);
}

.sf-msg-btn.sf-btn-active,
.sf-msg-btn1.sf-btn-active {
  opacity: 1;
  cursor: pointer;
  color: var(--white);
}

.sf-msg-btn.sf-btn-active:hover,
.sf-msg-btn1.sf-btn-active:hover {
  opacity: 0.85;
}

@media screen and (min-width: 401px) {
}

@media screen and (min-width: 601px) {
  .sf-main-cont,
  .sf-btns-cont {
    gap: var(--gap-lg);
  }

  .sf-msg-btn,
  .sf-msg-btn1 {
    font-size: var(--fs-xs);
  }
}

@media screen and (min-width: 801px) {
  .sf-textfield,
  .sf-msg-cont {
    padding: var(--fs-xs);
  }

  .sf-msg-btn,
  .sf-msg-btn1 {
    font-size: var(--fs-sm);
    height: 35px;
  }
}

@media screen and (min-width: 1001px) {
  .sf-main-cont,
  .sf-btns-cont {
    gap: var(--gap-xl);
  }
}

@media screen and (min-width: 1201px) {
  .sf-textfield,
  .sf-msg-cont {
    padding: 10px 16px;
  }
}

@media screen and (min-width: 1401px) {
}
