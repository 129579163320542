.container {
  display: flex;
  height: auto;
  gap: var(--gap-md);
  width: 100%;
  min-height: 200px;
  flex-direction: column;

  input {
    width: 100%;
    border-radius: var(--border-radius);
    padding: 0.8rem 1rem;
    padding: 10px 12px;
    color: var(--text-color);
    background-color: var(--second-white);
  }

  .row {
    display: grid;
    gap: var(--gap-md);
    grid-template-columns: repeat(2, 1fr);

    input {
      width: 100%;
    }

    .visibility-displayer {
      display: flex;
      width: 100%;
      border-radius: var(--border-radius);
      text-transform: capitalize;
      background-color: var(--second-white);

      .selected {
        display: flex;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 10px 12px;
        align-items: center;

        .name {
          opacity: 1;
          font-weight: var(--fw-medium);
          font-size: var(--fs-md);
          color: var(--text-color);
        }
      }

      .options {
        opacity: 0;
        z-index: 1;
        top: 100%;
        z-index: 10;
        display: flex;
        overflow: hidden;
        visibility: hidden;
        position: absolute;
        border-radius: var(--border-radius);
        width: -webkit-fill-available;

        > ul {
          display: flex;
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 2px 0;
          list-style: none;
          flex-direction: column;
          transition: 0.25s ease-out;
          background-color: var(--second-white);
          > li {
            display: flex;
            width: auto;
            height: 30px;
            margin: 1px 0;
            cursor: pointer;
            padding: 0 20px;
            font-weight: var(--fw-medium);
            font-size: var(--fs-md);
            border-radius: var(--border-radius);
            align-items: center;
            color: var(--heading);
            text-transform: capitalize;

            &:hover {
              background: var(--background);
            }
          }
        }
      }

      &:hover > .options {
        opacity: 1;
        display: flex;
        visibility: visible;
        animation: fade 0.25s ease-in-out;
      }
    }
  }

  button {
    display: flex;
    opacity: 0.8;
    margin: 10px auto;
    font-size: var(--fs-md);
    font-weight: var(--fw-medium);
    line-height: 1.25;
    padding: 8px 1rem;
    width: fit-content;
    border-radius: var(--border-radius);
    align-items: center;
    justify-content: center;
    color: var(--background);
    background: var(--text-color2);

    &:hover {
      opacity: 1;
    }
  }
}
