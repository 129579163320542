.am-input-box {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 3px;
  /* overflow-y: auto; */
}

.am-input-title {
  font-weight: var(--fw-medium);
  font-size: var(--fs-sm);
  line-height: 1.25;
  color: var(--text-color2);
}

.am-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 5px; */
  align-items: center;
}

.am-input-field {
  resize: none;
  /* line-height: 20px; */
  user-select: auto;
  overflow: auto auto;
  overflow-y: scroll;
  display: flex;
  margin: 5px 0;
  width: 100%;
  height: 200px;
  font-size: var(--fs-sm);
  padding: 10px 12px;
  border: 5px none;
  outline: none;
  border-radius: var(--border-radius);
  color: var(--heading);
  text-transform: none !important;
  background-color: var(--background);
}

.am-input-workcount {
  width: 100%;
  opacity: 0.6;
  text-align: end;
  font-size: var(--fs-xs);
}

.am-input-about {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
}

/* socials input section */
.am-social-input-text {
  display: flex;
  padding: 0 0;
  margin: 10px 0;
  align-items: center;
}
.am-social-input-text h2 {
  font-weight: var(--fw-medium);
  font-size: var(--fs-2xl);
  color: var(--text-color);
}
.am-social-input-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap-lg);
}

.am-social-link {
  display: flex;
  width: 100%;
  /* margin: 5px 0; */
  flex-direction: column;
  position: relative;
}
.am-social-field {
  display: flex;
  padding: 10px;
  margin-top: 4px;
  border-radius: var(--border-radius);
  background-color: var(--background);
}
.am-social-icons {
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
.dark .am-social-icons {
  color: var(--text-color);
}
.am-social-icon {
  padding-right: 5px;
}
.am-social-text {
  border: none;
  margin-left: 7px;
  width: 100%;
  color: var(--text-color);
  background: transparent;
}
/* buttons section */
.am-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-md);
  padding-top: 10px;
  font-weight: var(--fw-medium);
  font-size: var(--fs-sm);
  margin-top: 10px;

  .am-cancel-btn {
    display: flex;
    width: 100px;
    height: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.15s ease;
    color: var(--text-color);
    font-weight: var(--fw-normal);
    background-color: var(--background);
    border-radius: var(--border-radius);
  }

  .am-save-btn {
    display: flex;
    min-width: 100px;
    height: 30px;
    padding: 0 8px;
    font-size: inherit;
    color: var(--white);
    align-items: center;
    transition: 0.15s ease;
    justify-content: center;
    font-weight: var(--fw-medium);
    border-radius: var(--border-radius);
    background-color: var(--brand-color);
  }
  .am-cancel-btn:hover,
  .am-save-btn:hover {
    opacity: 0.85;
  }
}

@media only screen and (max-width: 641px) {
  .am-social-input-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--gap-lg);
  }
}

.errorHeading {
  color: var(--brand-color);
  padding: 2px 0px;
  font-weight: var(--fw-light);
  position: sticky;
  top: 30px;
  width: 100%;
  z-index: 10;
}
