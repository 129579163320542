.pm-input-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 3px;
}

.pm-pass-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-md);
  padding-top: 10px;
  font-size: var(--fs-xs);
}

.pm-cancel-btns {
  display: flex;
  width: 100px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  font-weight: var(--fw-normal);
  border-radius: var(--border-radius);
  cursor: pointer;
  color: white;
  background-color: var(--heading);
  transition: 0.15s ease;
}

.dark .pm-cancel-btns {
  color: black;
}

.pm-save-btns {
  display: flex;
  min-width: 100px;
  height: 30px;
  padding: 0 8px;
  font-size: inherit;
  align-items: center;
  justify-content: center;
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  color: white;
  transition: 0.15s ease;
  background-color: var(--brand-color);
}

.pm-cancel-btns:hover,
.pm-save-btns:hover {
  opacity: 0.85;
}

@media screen and (min-width: 400px) {
  .pm-pass-btns {
    padding-top: 1rem;
    gap: var(--gap-md);
  }
}

@media screen and (min-width: 450px) {
  .pm-pass-btns {
    padding-top: 1.5rem;
  }
}

@media screen and (min-width: 550px) {
  .pm-pass-btns {
    padding-top: 20px;
    gap: var(--gap-lg);
  }
}

/* big laptop screens */
@media only screen and (min-width: 1025px) {
  .pm-pass-btns {
    font-size: var(--fs-sm);
    gap: var(--gap-lg);
  }

  .pm-cancel-btns,
  .pm-save-btns {
    height: 35px;
  }
}
