.container {
  width: 100%;
  padding: 10px;
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .expiration {
    display: flex;
    width: 100%;
    gap: var(--gap-sm);
    align-items: flex-end;
  }

  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 8px;
      background-color: var(--brand-color);
      color: var(--brand-color-text);
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
