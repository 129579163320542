.card {
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  border: var(--border);
  transition: 0.15s ease;
  flex-direction: column;
  border-radius: var(--border-radius);
  background-color: var(--background);

  .anchor {
    height: 100%;
    z-index: 1;
    width: 100%;
    position: absolute;
  }

  .card-figure {
    width: 100%;
    height: auto;
    aspect-ratio: 17/12;
    user-select: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .content {
      top: 20px;
      left: 5px;
      width: 75%;
      padding: 5px 10px;
      position: absolute;
      height: calc(100% - 20px);

      @media screen and (min-width: 641px) {
        top: 25px;
        height: calc(100% - 35px);
      }

      @media screen and (min-width: 961px) {
        top: 20px;
        height: calc(100% - 20px);
      }

      @media screen and (min-width: 1081px) {
        top: 25px;
        height: calc(100% - 25px);
      }

      @media screen and (min-width: 1281px) {
        top: 30px;
        height: calc(100% - 30px);
      }

      @media screen and (min-width: 1400px) {
        top: 45px;
        height: calc(100% - 40px);
      }

      h4 {
        -webkit-box-orient: vertical;
        font-weight: var(--fw-medium);
        line-height: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        font-size: 10px;
        overflow: hidden;
        margin-bottom: 3px;
        color: var(--brand-color);
        @media screen and (min-width: 641px) {
          line-height: 18px;
          margin-bottom: 10px;
          -webkit-line-clamp: 2;
          font-size: var(--fs-md);
        }
        @media screen and (min-width: 961px) {
          line-height: 18px;
          margin-bottom: 5px;
          -webkit-line-clamp: 2;
          font-size: var(--fs-sm);
        }
        @media screen and (min-width: 1081px) {
          line-height: 20px;
          margin-bottom: 8px;
          -webkit-line-clamp: 2;
          font-size: var(--fs-md);
        }
        @media screen and (min-width: 1400px) {
          font-size: var(--fs-lg);
          line-height: 22px;
        }
      }

      .duration {
        display: none;
        color: var(--black);
        @media screen and (min-width: 1281px) {
          display: flex;
          padding: 3px 0;
          font-size: var(--fs-xs);
        }
        @media screen and (min-width: 1400px) {
          font-size: var(--fs-md);
        }
      }

      .box-text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-weight: 600;
        line-height: 15px;
        font-size: var(--fs-xxs);
        font-weight: var(--fw-medium);
        color: var(--black);
        -webkit-box-orient: vertical;

        @media screen and (min-width: 641px) {
          font-size: var(--fs-sm);
          line-height: 18px;
          -webkit-line-clamp: 2;
        }
        @media screen and (min-width: 961px) {
          font-size: var(--fs-sm);
          line-height: 18px;
          -webkit-line-clamp: 2;
        }
        @media screen and (min-width: 1081px) {
          font-size: var(--fs-md);
          line-height: 20px;
          -webkit-line-clamp: 2;
        }
        @media screen and (min-width: 1400px) {
          font-size: var(--fs-lg);
          -webkit-line-clamp: 2;
        }
      }

      .date {
        color: grey;
        bottom: 10px;
        position: absolute;
        font-size: var(--fs-xxs);
        @media screen and (min-width: 641px) {
          font-size: var(--fs-sm);
          bottom: 0px;
        }
        @media screen and (min-width: 961px) {
          font-size: var(--fs-sm);
          bottom: 5px;
        }
        @media screen and (min-width: 1400px) {
          bottom: 30px;
        }
      }
    }
  }
}
