.container {
  width: 50vw;
  display: flex;
  gap: var(--gap-md);
  padding: var(--gap-lg);
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--border-radius);

  .cross-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: var(--gap-md) var(--gap-lg);

    > svg {
      cursor: pointer;
      font-size: var(--fs-2xl);
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  > article {
    height: 100%;
    display: flex;
    align-items: flex-start;

    img {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      object-fit: contain;
      justify-content: center;
      border-right: 1px solid var(--border-color);
    }

    .right {
      width: 100%;
      height: 100%;
      display: flex;
      gap: var(--gap-md);
      flex-direction: column;
      padding: var(--gap-md) var(--gap-lg);

      > h3 {
        font-size: var(--fs-xl);
        color: var(--text-color);
        margin-bottom: var(--gap-md);
      }

      > p {
        font-size: var(--fs-lg);
        color: var(--text-secondary);

        > p {
          color: var(--text-color);
          margin-top: var(--gap-lg);
          font-weight: var(--fw-bold);
        }
      }

      .btn-div {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > button {
          margin-top: 30%;
        }
      }
    }
  }
}
