.card {
  display: flex;
  z-index: 2;
  height: 100%;
  width: inherit;
  overflow: hidden;
  cursor: pointer;
  border: var(--border);
  transition: 0.15s ease;
  flex-direction: column;
  box-sizing: border-box;
  color: var(--text-color);
  border-radius: var(--border-radius);
  background-color: var(--background);

  .anchor-level {
    width: 100%;
    height: 100%;
    z-index: 3;
    position: absolute;
  }

  .header {
    display: flex;
    align-items: center;

    > img {
      height: 160px;
      object-fit: cover;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.1);
      @media screen and (min-width: 481px) {
        opacity: 1;
      }
    }

    .mentor {
      z-index: 2;
      right: 10px;
      bottom: -25px;
      position: absolute;

      > img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
  }

  summary {
    display: flex;
    flex-direction: column;
    padding: 30px 10px 20px;

    .tagline {
      top: 5px;
      position: absolute;
    }

    h2 {
      height: 45px;
      line-clamp: 2;
      max-height: 45px;
      line-height: 22px;
      margin-bottom: 8px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      font-size: var(--fs-lg);
      -webkit-box-orient: vertical;
    }

    p {
      overflow: hidden;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      font-size: var(--fs-sm);
      -webkit-box-orient: vertical;
    }
  }
}
