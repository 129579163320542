.container {
  width: 100%;
  height: 90px;
  position: fixed;
  padding-right: 0px;
  z-index: 98 !important;
  background: var(--profile-cover);

  .box {
    width: 100%;
    height: 100%;
    background-size: 400%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("./ProfileCover.png");

    .content {
      display: flex;
      gap: 10px;
      height: 100%;
      padding: 0px 10px;
      align-items: center;
      justify-content: flex-start;
      background: linear-gradient(
        to right,
        var(--profile-cover) 100px,
        rgba(0, 0, 0, 0.5),
        var(--profile-cover)
      );
      border-bottom: 1px solid var(--border-color);

      @media screen and (min-width: 641px) {
        background: linear-gradient(
          to right,
          var(--profile-cover) 100px,
          transparent,
          var(--profile-cover)
        );
      }
    }

    .user-avatar {
      display: flex;
      flex-direction: column;

      .avatar {
        width: 50px !important;
        height: 50px !important;
      }

      .pencil {
        display: flex;
        bottom: -10px;
        width: 20px;
        height: 20px;
        z-index: 1;
        cursor: pointer;
        margin-left: 15px;
        border-radius: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;
        color: var(--text-color);
        background-color: var(--second-white);
      }

      .pencil:focus {
        outline: none;
        border: none;
      }

      .pencil:hover {
        background-color: rgba(32, 43, 71, 0.9);
      }

      .pencil-icon {
        display: flex;
        width: 10px;
        overflow: hidden;
      }
    }

    .user-details {
      display: flex;
      flex: 1;
      height: 100%;
      justify-content: space-between;

      .detail-box {
        display: flex;
        flex: 1;
        margin-bottom: 7px;
      }

      div {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .greeting {
        display: flex;
        padding: 0 5px;
        font-size: var(--fs-md);
        line-height: 1.25;
        font-weight: normal;
        align-items: center;
        color: var(--text-color2);
      }

      .contact-text {
        margin-top: 4px;
        padding: 0 5px;
        cursor: pointer;
        text-align: start;
        color: var(--blue);
        width: max-content;
        font-size: var(--fs-sm);
      }

      h1 {
        padding: 0 5px;
        font-size: var(--fs-lg);
        cursor: pointer;
        overflow: hidden;
        line-height: 1.25;
        margin-bottom: 2px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        color: var(--text-color2);
        font-weight: var(--fw-bold);
        -webkit-box-orient: vertical;
      }

      .user-cred {
        padding: 0 5px;
        font-size: var(--fs-sm);
        font-weight: var(--fw-medium);
        line-height: 1.25;
        color: var(--text-color);
        text-transform: lowercase;
      }

      .right-side {
        display: flex;
        gap: 8px;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .follower-no {
          cursor: pointer;
          font-size: var(--fs-md);
          font-weight: var(--fw-medium);
          text-align: center;
          color: var(--heading);
        }

        .notif {
          color: var(--heading);
          font-size: var(--fs-sm);
        }

        .follow-btn {
          font-size: var(--fs-md);
          border-radius: var(--border-radius);
          padding: 0.6rem 2rem;
        }

        // social links
        .contact-link-container {
          margin-top: 8px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          gap: 13px;
          align-items: center;
          // test styles
          a {
            color: var(--text-color);
            .contact-link-icon {
              width: 25px;
              height: 25px;
            }
          }
          .link-desabled-btn {
            border: none;
            color: var(--text-color);

            // opacity: 0.5;
            position: relative;
            .contact-link-icon {
              width: 25px;
              height: 25px;
            }
            .contact-empty-link-icon {
              width: 15px;
              height: 15px;
              position: absolute;
              color: red;
              opacity: 1;
              border-radius: 50%;
              background-color: #fff;
              bottom: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 641px) {
    height: 110px;
    .box {
      background-size: cover;
      .user-avatar {
        .avatar {
          width: 65px !important;
          height: 65px !important;
        }
      }

      .user-details {
        .greeting {
          font-size: var(--fs-lg);
        }

        h1 {
          font-size: var(--fs-xl);
        }

        .user-cred {
          font-size: var(--fs-sm);
        }

        .right-side {
          width: auto;
          height: 100px;
          .follower-no {
            font-size: var(--fs-lg);
          }
          .notif {
            font-size: var(--fs-lg);
          }
        }
      }
    }
  }

  @media screen and (min-width: 961px) {
    padding-right: 60px;
    height: 110px;
    .box {
      .content {
        padding: 0 2%;
        .user-avatar {
          .pencil {
            bottom: -10px;
            width: 25px;
            height: 25px;
            margin-left: 22px;
          }

          .pencil-icon {
            width: 14px;
          }
        }
      }

      .user-avatar {
        .avatar {
          width: 70px !important;
          height: 70px !important;
        }
      }

      .user-details {
        .greeting {
          font-size: var(--fs-xl);
        }

        h1 {
          font-size: var(--fs-2xl);
        }

        .user-cred {
          font-size: var(--fs-md);
        }
      }
    }
  }
}
