.container {
  display: flex;
  gap: 15px;
  flex-direction: column;
  color: var(--text-color);

  article {
    display: flex;
    gap: 10px;
    flex-direction: column;
    h2 {
      font-weight: var(--fw-medium);
      text-transform: none;
      -webkit-line-clamp: 2;
      font-size: var(--fs-text);
    }

    p {
      font-size: var(--fs-mini);

      img {
        width: var(--fs-text);
        line-height: 20px;
      }

      a {
        font-weight: var(--fw-medium);
        color: var(--blue);
      }

      ul {
        padding: 8px 20px;
        li {
          line-height: 22px;
        }
      }

      span {
        font-weight: var(--fw-medium);
        color: var(--brand-color);
      }
    }
  }
}

.footer {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 12px;
  justify-content: flex-end;
  .backBtn {
    font-size: 16px;
    border-radius: 4px;
    color: var(--background);
    padding: 8px 12px !important;
    background-color: var(--text-color);
  }
  .nextBtn {
    font-size: 16px;
    color: var(--white);
    border-radius: 4px;
    padding: 8px 12px !important;
    background-color: var(--brand-color);
  }
}
