.formContainer {
  overflow: auto;

  .form {
    display: flex;
    gap: var(--gap-lg);
    margin: auto;
    flex-direction: column;

    @media screen and (min-width: 540px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .inputText {
      background-color: transparent;
      border: 2px solid var(--brand-color);
      > input {
        text-transform: none !important;
      }
    }

    textarea {
      padding: 1rem;
      grid-column-end: 3;
      grid-column-start: 1;
      border-radius: var(--border-radius);
      border: 2px solid var(--brand-color);
    }

    .formBtn {
      flex: 1;
      width: 100%;
      padding: 16px;
      display: flex;
      align-items: center;
      grid-column-end: 3;
      grid-column-start: 1;
      justify-content: center;
      border-radius: var(--border-radius);
      background-color: var(--brand-color);

      > button {
        color: var(--white);
        font-size: var(--fs-md);
      }
    }
  }
}

.inputText {
  color: var(--white);
  background-color: transparent;
}

.option {
  color: var(--black);
}

.dropdown {
  width: 100% !important;
  height: 100%;
  color: var(--black);

  & > div:nth-child(1) {
    gap: 5px;
    height: 100%;
    display: flex;
    cursor: pointer;
    padding: 0 10px;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius);
    border: 2px solid var(--brand-color);
  }

  &:hover {
    & > div:nth-child(2) {
      height: 120px;
      overflow: scroll;
    }
  }

  & > div:nth-child(2) {
    top: 100%;
    z-index: 2;
    height: 0px;
    width: 100%;
    overflow: scroll;
    position: absolute;
    box-shadow: var(--shadow);
    background-color: var(--white);
    transition: 0.1s all ease-in-out;
    border-radius: var(--border-radius);

    & > ul > li {
      padding: 10px;
      cursor: pointer;
      color: var(--black);

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}
