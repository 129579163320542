.card-container {
  display: flex;
  width: 100%;
  border-radius: var(--border-radius);
  align-items: center;
  cursor: pointer;
  padding: 10px 10px;
  gap: var(--gap-md);
  margin: auto;
  height: 100%;

  @media screen and (min-width: 641px) {
    padding: 20px 10px;
    gap: var(--gap-lg);
  }

  > img {
    height: 20px;
    width: auto;
    @media screen and (min-width: 641px) {
      height: 32px;
    }
  }

  > h3 {
    line-height: 15px;
    text-align: center;
    color: var(--white);
    font-size: var(--fs-xs);
    font-weight: var(--fw-medium);
    @media screen and (min-width: 641px) {
      font-size: var(--fs-sm);
    }
  }

  .number {
    color: var(--white);
    line-height: 25px;
    font-size: var(--fs-xs);
    font-weight: var(--fw-medium);
    @media screen and (min-width: 641px) {
      font-size: var(--fs-sm);
    }
  }
  /* portrait tablets */
  @media only screen and (min-width: 641px) and (orientation: portrait) {
    h3 {
      margin-top: 7px;
      line-height: 25px;
      font-size: var(--fs-lg);
    }

    .number {
      line-height: 30px;
      font-size: var(--fs-xl);
    }
  }

  /* big laptop screens */
  @media only screen and (min-width: 1025px) {
    h3 {
      line-height: 25px;
      font-size: var(--fs-lg);
    }

    .number {
      line-height: 30px;
      font-size: var(--fs-xl);
    }
  }
}
