.full-section {
  display: flex;
  width: 100%;
  gap: var(--gap-lg);
  height: auto;
  flex-direction: column;

  //   card styles
  .cards-section {
    display: flex;
    margin-bottom: 10px;
    gap: 5px;

    .cards-left {
      width: 35%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .card {
        // width: 200px;
        // height: 100px;
        padding: 6px;
        border-radius: var(--border-radius);
        background-color: var(--second-white);

        // -webkit-box-shadow: 0px 0px 10px -6px var(--light-shadow);
        // -moz-box-shadow: 0px 0px 10px -6px var(--light-shadow);
        // box-shadow: 0px 0px 10px -6px var(--light-shadow);

        // rest styles
        h5 {
          font-size: var(--fs-md);
          color: var(--text-color);
        }
        p {
          color: var(--text-color);
          font-size: var(--fs-xs);
          @media screen and (min-width: 641px) {
            font-size: var(--fs-sm);
          }

          span {
            font-weight: var(--fw-medium);
            color: var(--brand-color);
          }
        }
      }
    }
    .cards-right {
      width: 65%;
      display: flex;
      gap: 5px;
      justify-content: flex-end;
      align-items: center;
      .card-center {
        padding: 6px;
        border-radius: var(--border-radius);
        background-color: var(--second-white);

        // -webkit-box-shadow: 0px 0px 10px -6px var(--light-shadow);
        // -moz-box-shadow: 0px 0px 10px -6px var(--light-shadow);
        // box-shadow: 0px 0px 10px -6px var(--light-shadow);

        // rest styles
        h5 {
          width: 100%;
          text-align: center;
          color: var(--text-color);
          font-size: var(--fs-md);
        }
        p {
          width: 100%;
          text-align: center;
          color: var(--text-color);
          font-size: var(--fs-xs);
          @media screen and (min-width: 641px) {
            font-size: var(--fs-sm);
          }
        }
      }
    }
  }

  .map-content {
    display: flex;
    padding: 0;
    width: auto;
    margin: 10px 0 0;
    align-items: center;
    justify-content: flex-end;

    span {
      font-size: var(--fs-md);
      padding: 0 8px;
      color: var(--text-color2);
    }

    svg {
      margin: 0 3px;
    }
  }

  @media screen and (min-width: 1200px) {
    .cards-section {
      .card {
        h5 {
          font-size: var(--fs-2xl);
          color: var(--text-color);
        }
        p {
          font-size: var(--fs-md);
        }
      }
    }
  }
}
