.container {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 10px;
  flex-direction: column;
  border-radius: var(--border-radius);
  background-color: var(--background-secondary);

  // &:has(> .field) {
  //   box-shadow: var(--shadow);
  // }

  button {
    display: flex;
    padding: 10px;
    margin: 10px 0;

    border-radius: var(--border-radius);
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      color: var(--text-color);
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--text-color);
    }
  }

  .field {
    display: none;
    opacity: 0;
    padding: 0 10px;
    visibility: hidden;
    transition: 0.5s ease-in-out;
    .title {
      display: flex;
      flex-direction: column;

      input {
        padding: 8px 5px;
        border-radius: var(--border-radius);
        text-transform: none;
        color: var(--text-color2);
        background-color: var(--background);
        border: 2px solid var(--border-color);
      }

      .label {
        display: flex;
        margin: 10px 0;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          gap: 5px;
          align-items: center;
          svg {
            color: var(--text-color);
          }
          p {
            color: var(--text-color);
          }
        }
        .right {
          color: var(--text-color);
        }
      }
    }

    .text {
      display: flex;
      width: 100%;
      height: 160px;
      padding: 5px;
      line-height: 18px;
      border-radius: var(--border-radius);
      text-transform: none;
      color: var(--text-color);
      background-color: var(--background);
      border: 2px solid var(--border-color);
    }

    footer {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;

      .left {
        display: flex;
        gap: var(--gap-md);
        justify-content: center;
        button {
          border-radius: 2px;
          &:hover {
            color: var(--white);
            box-shadow: var(--shadow);
            background-color: var(--black);
          }
        }
        .bold {
          font-weight: 600;
        }
        .italic {
          font-family: "Times New Roman", Times, serif;
          font-style: italic;
        }
      }

      .right {
        display: flex;
        gap: var(--gap-md);
        justify-content: flex-end;
        button {
          color: var(--text-color);
          background-color: var(--background);

          &:hover {
            color: var(--background);
            box-shadow: var(--shadow);
            background-color: var(--text-color);
          }
        }
      }
    }
  }

  .show {
    display: inline;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
