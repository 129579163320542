.uvc-card-box {
  display: flex;
  gap: var(--gap-md);
  width: 100%;
  height: auto;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: var(--background);

  span {
    width: auto !important;
  }
}

.uvc-card-image {
  display: flex;
  height: 140px;
  width: auto;
  overflow: hidden;
  border-radius: var(--border-radius);
  background-color: var(--menu-back);
}

.uvc-card-details {
  display: grid;
  height: auto;
  width: 100%;
  gap: var(--gap-md);
  grid-template-columns: auto 200px;

  .left {
    display: flex;
    flex-direction: column;
    h2 {
      padding: 1px 0;
      font-weight: var(--fw-medium);
      font-size: var(--fs-md);
      line-height: 25px;
      color: var(--text-color2);
    }

    p {
      width: auto;
      opacity: 0.8;
      overflow: hidden;
      text-align: start;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      font-size: var(--fs-sm);
      color: var(--text-color);
      -webkit-box-orient: vertical;
    }
  }
}

.right {
  display: flex;
  flex: 0.15;
  width: auto;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  button {
    display: flex;
    opacity: 0.8;
    width: 60%;
    font-weight: var(--fw-medium);
    line-height: 1.25;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-md);
    color: var(--background);
    background: var(--text-color2);
    border-radius: var(--border-radius);

    &:hover {
      opacity: 1;
    }
  }

  .delete {
    color: var(--white);
    background-color: var(--brand-color);
  }
}

/* smartphone portrait */
@media only screen and (min-width: 320px) and (orientation: portrait) {
  /* hi */
}

/* smartphone landscape */
@media only screen and (min-width: 481px) and (orientation: landscape) {
  /* hi2 */
}

/* portrait tablets */
@media only screen and (min-width: 641px) and (orientation: portrait) {
  /* hi3 */
}

/* tablet landscape */
@media only screen and (min-width: 961px) and (orientation: landscape) {
  /* hi4 */
}

/* big laptop screens */
@media only screen and (min-width: 1025px) {
  /* hi7 */
  // .uvc-card-box {
  //   height: 120px;
  // }
}

/* big laptop screens */
@media only screen and (min-width: 1281px) {
  /* hi7 */
}
