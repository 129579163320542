.switch {
  width: 52px;
  height: 30px;
  font-size: 14px;
  position: relative;
  display: inline-block;

  input {
    width: 0;
    height: 0;
    opacity: 1;
  }
  .slider {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    cursor: pointer;
    transition: 0.4s;
    position: absolute;
    background: #fff;
    border-radius: 30px;
    border: 1px solid #ccc;

    &:before {
      top: 0;
      bottom: 0;
      content: "";
      left: 1.2px;
      width: 1.9em;
      height: 1.9em;
      transition: 0.4s;
      position: absolute;
      border-radius: 16px;
      background-color: white;
      box-shadow: 0 2px 5px #999999;
    }
  }
}

input:checked + .slider {
  border: 1px solid transparent;
  background-color: var(--brand-color);
}

input:checked + .slider:before {
  transform: translateX(1.5em);
}
