.profileModal-input-field-container {
  display: flex;
  width: 100%;
  gap: var(--gap-sm);
  flex-direction: column;

  // for input field
  .input-field {
    display: flex;
    padding: 10px;
    border: var(--border);
    border-radius: var(--border-radius);
    background-color: var(--second-white);
  }
  //Input label style
  .input-label {
    line-height: 1.25;
    font-size: var(--fs-md);
    color: var(--text-color);
    font-weight: var(--fw-medium);
  }

  // input divider
  .input-divider {
    width: 100%;
    display: flex;
    gap: var(--gap-md);
  }
  .input-divider-section-left {
    width: 60%;
  }
  .input-divider-section-right {
    width: 40%;
  }

  .normal-text-input {
    width: 100%;
    border: none;
    margin-left: 7px;
    width: 100%;
    color: var(--text-color);
    background: transparent;
    text-transform: none !important;
  }

  .skills-display {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: var(--gap-md);
  }

  .skill-box {
    background-color: transparent;
    border: 0.01px solid var(--text-color);

    padding: 10px;
    border-radius: var(--border-radius);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    color: var(--text-color);

    .rating {
      padding: 2px;
      background-color: var(--text-box);
      color: var(--text-color);
      border-radius: var(--border-radius);
    }
  }

  .skill-box-btn {
    background-color: transparent;
    color: var(--text-color);
  }
}
