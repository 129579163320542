.react-pdf__Page {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  overflow: scroll;
}

.react-pdf__Page__canvas {
  height: auto !important;
  margin: auto;
  box-shadow: var(--shadow);
  position: relative;
  overflow: scroll;
  width: 100% !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  height: 0px !important;
  width: 0px !important;
  display: none;
}

.react-pdf__Document {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: scroll;
  cursor: all-scroll;
}

.pdf-page canvas {
  background-color: transparent !important; /* Match the container background */
}

.pdf-page-container {
  margin-bottom: 20px;
}

.pdf-page-container.dark {
  filter: invert(1) hue-rotate(180deg);
  background-color: var(--background); /* Dark background */
}

.dark-mode-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.139); /* Semi-transparent overlay */
  pointer-events: none; /* Allow interaction with the PDF */
}

.light-mode-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  background-color: rgba(250, 250, 250, 0.7); /* Semi-transparent overlay */
  pointer-events: none; /* Allow interaction with the PDF */
}

@media screen and (min-width: 641px) {
  .react-pdf__Document {
    height: calc(100vh - 160px);
  }
  .react-pdf__Page {
    width: 100%;
  }
}
