.fc-container {
  display: flex;
  gap: 10px;
  padding: 10px;
  overflow: hidden;
  border: var(--border);
  transition: 0.15s ease;
  // box-shadow: var(--shadow);
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: var(--background);
  border-radius: var(--border-radius);

  .fc-btn-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }

  .fc-main {
    display: flex;
    gap: var(--gap-md);
    align-items: center;

    .fc-profile-pic {
      cursor: pointer;
      width: 50px !important;
      height: 50px !important;
      object-fit: cover;
    }

    .fc-details {
      display: flex;
      gap: 2px;
      flex-direction: column;
      h3 {
        font-weight: var(--fw-bold);
        font-size: var(--fs-sm);
        line-height: 1.25;
        color: var(--heading);

        @media screen and (min-width: 641px) {
          font-size: var(--fs-md);
        }
      }
      p {
        font-weight: var(--fw-medium);
        font-size: var(--fs-xs);
        line-height: 1.25;
        color: var(--sub-heading);
        @media screen and (min-width: 641px) {
          font-size: var(--fs-sm);
        }
      }
    }
  }

  &:hover {
    box-shadow: var(--shadow);
  }
}

@media screen and (min-width: 400px) {
  .fc-container {
    padding: 12px;
  }
}

@media screen and (min-width: 481px) {
  .fc-container {
    padding: 14px;
    flex-direction: column-reverse;
  }
  .fc-main {
    flex-direction: column;
  }
  .fw-category-videos {
    grid-template-columns: 1fr 1fr;
    gap: var(--gap-xl);
  }
  .fc-details {
    align-items: center;
  }
  .fc-btn-row {
    margin: auto;
    justify-content: center;
  }
  .fc-profile-pic {
    height: 80px !important;
    width: 80px !important;
  }
}
