// AlumniCardSkeleton.module.scss

.skeletonCard {
  width: 100%;
  animation: shine 1s infinite;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  border-radius: var(--border-radius);
  padding: 10px;

  @keyframes shine {
    0% {
      opacity: 0.2;
      transition-property: left, top, opacity;
      transition-duration: 0.7s, 0.7s, 0.2s;
      transition-timing-function: ease;
    }
    50% {
      opacity: 0.1;
      transition-property: left, top, opacity;
    }
    100% {
      opacity: 0.2;
      transition-property: left, top, opacity;
    }
  }
}

.skeletonImage {
  width: 100px;
  height: 100px;
  margin: auto;
  background-color: #ccc;
  border-radius: 50%;
  margin-bottom: 10px;
}

.skeletonTitle {
  margin: auto;
  height: 20px;
  width: 200px;
  background-color: #ccc;
  margin-bottom: 5px;
}

.skeletonDescription {
  margin: auto;
  height: 16px;
  width: 70%;
  background-color: #ccc;
  margin-bottom: 10px;
}

.skeletonLinkedInButton {
  width: 120px;
  height: 30px;
  margin: auto;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border-radius: var(--border-radius);
  cursor: pointer;
}
