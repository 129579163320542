.container {
  display: flex;
  width: inherit;
  height: inherit;
  overflow: hidden;
  margin-left: 10px;
  flex-direction: column;

  header {
    display: flex;
    height: auto;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: content-box;
    color: var(--text-color);
    justify-content: space-between;
    border: 1px solid var(--border-color);

    h3 {
      font-weight: var(--fw-medium);
      font-size: var(--fs-sm);
    }

    @media screen and (max-width: 961px) {
      display: none;
    }
  }

  .lists {
    display: flex;
    gap: var(--gap-md);
    padding: 10px;
    overflow: auto;
    margin-bottom: 155px;
    flex-direction: column;

    // button for see more
    .seeMore {
      text-align: left;
      color: var(--text-color2);
    }

    .empty {
      display: flex;
      width: inherit;
      justify-content: center;

      p {
        font-weight: var(--fw-medium);
        font-size: var(--fs-md);
        color: var(--text-color);
      }
    }

    @media screen and (max-width: 961px) {
      margin-bottom: 150px;
    }
  }

  .box {
    display: flex;
    left: 0;
    z-index: 1;
    bottom: 65px;
    width: 100%;
    padding: 0 10px;
    position: absolute;
    flex-direction: column;

    .count {
      width: 100%;
      position: absolute;
      visibility: hidden;
      color: var(--paragraph);
    }

    .limit-cross {
      color: var(--sidebar-tab-active);
    }

    @media screen and (max-width: 1024px) {
      bottom: 8px;
      height: auto;
      width: 320px;
      position: relative;
    }

    @media screen and (max-width: 961px) {
      bottom: -10px;
      width: 100%;
    }
  }
}
