.errorHeading {
  color: var(--brand-color);
  padding: 2px 0px;
  font-weight: var(--fw-light);
  position: sticky;
  top: 30px;
  width: 100%;
  z-index: 10;
}

.profileModal {
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 641px) {
    padding-bottom: 0px;
  }

  // styles for Experience modal
  .durationDates {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .durationDate {
      width: 50%;
    }
  }

  .row {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: 641px) {
    .row {
      flex-direction: row;
    }
  }

  /* buttons section */
  .am-btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-md);
    padding-top: 10px;
    font-weight: var(--fw-medium);
    font-size: var(--fs-sm);
    margin-top: 10px;

    .am-cancel-btn {
      display: flex;
      width: 100px;
      height: 30px;
      cursor: pointer;
      transition: 0.15s ease;
      align-items: center;
      justify-content: center;
      color: var(--text-color);
      font-weight: var(--fw-normal);
      background-color: var(--background);
      border-radius: var(--border-radius);
    }

    .am-save-btn {
      display: flex;
      min-width: 100px;
      height: 30px;
      padding: 0 8px;
      color: var(--white);
      font-size: inherit;
      align-items: center;
      transition: 0.15s ease;
      justify-content: center;
      font-weight: var(--fw-medium);
      border-radius: var(--border-radius);
      background-color: var(--brand-color);
    }
    .am-cancel-btn:hover,
    .am-save-btn:hover {
      opacity: 0.85;
    }
  }
}
