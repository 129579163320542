.tooltip {
  z-index: 999;
  cursor: inherit;
  position: relative;
  display: inline-flex;

  &-content {
    visibility: hidden;
    opacity: 0;
    z-index: 1;
    margin-top: 15px;
    position: absolute;
    color: #fff;
    background-color: rgba(158, 159, 160, 0.795);
    border-radius: var(--border-radius);
    padding: 8px;
    font-size: var(--fs-xxs);
    box-shadow: var(--shadow);
    transition:
      opacity 0.3s,
      transform 0.3s;
    transform-origin: center;

    &::after {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 6px;
      border-color: transparent transparent rgba(158, 159, 160, 0.795)
        transparent;
    }
  }

  &.top .tooltip-content {
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    &::after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.bottom .tooltip-content {
    top: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    &::after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.left .tooltip-content {
    top: 50%;
    right: calc(100% + 8px);
    transform: translateY(-50%);
    &::after {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }
  }

  &.right .tooltip-content {
    top: 50%;
    left: calc(100% + 8px);
    transform: translateY(-50%);
    &::after {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  &:hover .tooltip-content {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0);
  }

  .tooltip-text {
    display: block;
    line-height: 1.5;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
