.container {
  display: block;
  width: auto;
  height: inherit;
  text-align: center;
  color: var(--text-color);
  border-radius: var(--border-radius);
  > svg {
    height: 35px;
    width: auto;
    padding: 3px;
    border-radius: 100%;
    color: var(--text-color);
    border: 2px solid var(--text-color);
  }

  > p {
    font-size: var(--fs-lg);
    color: var(--text-color);
    font-weight: var(--fw-medium);
  }
}
