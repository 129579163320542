.label {
  .inputContainer {
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: var(--border-radius);
  }

  .formInput {
    text-transform: none !important;
    border-radius: var(--border-radius);
    width: 100%;
    padding: 0.7rem;
    background-color: transparent;
    color: var(--text-color);

    &::placeholder {
      color: var(--text-color);
    }
  }

  .labelTitle {
    margin-bottom: 0.5rem;
  }
}

.requiredField {
  color: var(--red-shade);
}
