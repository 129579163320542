.navbox {
  display: grid;
  top: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  position: fixed;
  padding: 0 5px;
  user-select: none;
  align-items: center;
  gap: var(--gap-md);
  color: var(--text-color);
  border-bottom: var(--border);
  background-color: var(--background);
  grid-template-columns: auto 1fr auto;

  @media screen and (min-width: 641px) {
    padding: 0 15px;
  }

  .left-box {
    display: flex;
    align-items: center;
  }

  .navHambar {
    display: none;
  }

  .navBrand {
    display: flex;
    cursor: pointer;
    margin-left: 5px;
    align-items: center;
    text-decoration: none;

    img {
      width: 40px;
      height: 40px;
      padding: 5px;
    }

    h2 {
      color: inherit;
      padding-left: 0px;
      font-size: var(--fs-lg);
      font-weight: var(--fw-medium);
    }
  }

  @media only screen and (min-width: 961px) {
    grid-template-columns: auto 1fr auto;

    .navHambar {
      display: flex;
      width: 56px;
      height: 40px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }

    .navBrand {
      display: flex;
      gap: 5px;
      align-items: center;

      > img {
        width: 45px;
        height: 45px;
      }

      > h1 {
        font-size: var(--fs-xl);
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    .navHambar {
      display: flex;
      width: 56px;
      height: 40px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }
  }
}

.search-container {
  display: flex;
  color: inherit;
  background-color: inherit;
  justify-content: flex-end;

  .search-icon {
    display: flex;
    width: 18px;
    cursor: pointer;
  }

  .search-icon-open {
    display: none;
  }

  .mobile-search {
    display: none;
  }

  .search {
    display: grid;
    top: 0;
    left: 0;
    gap: 5px;
    width: 100%;
    z-index: 3;
    height: 59px;
    color: inherit;
    padding: 0 5px;
    position: fixed;
    align-items: center;
    animation: fade 0.3s;
    background-color: inherit;
    grid-template-columns: 40px auto;
  }

  button {
    display: flex;
    width: 100%;
    border: none;
    outline: none;
    margin: auto 0;
    color: inherit;
    align-items: center;
    justify-content: center;

    svg {
      width: 40px;
      height: auto;
      transform: rotate(90deg);
    }
  }

  @media only screen and (min-width: 961px) {
    .search-icon {
      display: none;
    }

    .mobile-search {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    .search {
      display: flex;
      top: 0;
      left: 0;
      margin: 0;
      width: auto;
      padding-top: 0px;
      padding-bottom: 0;
      position: relative;
    }

    button {
      display: none;
    }
  }
}

.search-box {
  display: grid;
  z-index: 5;
  height: 40px;
  overflow: hidden;
  align-items: center;
  grid-template-columns: auto 65px;
  border: 1px solid rgb(68, 73, 85);
  border-radius: var(--border-radius);

  input {
    width: 100%;
    height: 35px;
    margin: 0 5px;
    border: none;
    color: inherit;
    padding: 0 15px;
    font-size: medium;
    font-weight: var(--fw-medium);
    background-color: transparent;
  }

  input::placeholder {
    opacity: 0.6;
    color: inherit;
    font-weight: var(--fw-normal);
  }

  input:focus {
    outline: none;
  }

  input::-webkit-search-cancel-button {
    right: 10px;
    height: 20px;
    width: 20px;
    color: inherit;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--border-color);
  }

  button {
    display: flex;
    height: 100%;
    padding: 0 10px;
    color: inherit;
    justify-content: center;
    background-color: inherit;

    svg {
      width: 18px;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 961px) {
    grid-template-columns: auto 80px;
    width: 100%;
  }

  @media only screen and (min-width: 1281px) {
    width: 640px;
  }
}

.navRight {
  display: flex;
  z-index: 2;
  gap: var(--gap-sm);
  padding-right: 8px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  .notification {
    display: inline;

    .notification-box {
      display: none;
    }

    .mobile-notification {
      display: flex;
      align-items: flex-start;
    }

    > svg {
      display: flex;
      width: 18px;
      color: inherit;
      cursor: pointer;
    }

    .notification-count {
      width: 20px;
      height: 20px;
      padding: 3px;
      display: flex;
      margin-top: -8px;
      border-radius: 50%;
      color: var(--white);
      align-items: center;
      justify-content: center;
      background-color: var(--brand-color);
      span {
        font-size: var(--fs-xxs);
      }
    }
  }

  .nav-avatar {
    display: none;
    cursor: pointer;
    div {
      display: flex;
      gap: 5px;
      p {
        display: none;
        @media screen and (min-width: 1281px) {
          display: block;
          max-width: 10ch;
          margin: auto 0;
          font-weight: var(--fw-medium);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: var(--fs-sm);
        }
      }
    }
  }

  .watch-points {
    display: none;

    img {
      width: 20px;
      height: 20px;
      padding: 2px;
      margin: 0 3px;
      border-radius: 100%;
      background-color: var(--white);
    }

    h2 {
      font-weight: 600;
      font-size: var(--fs-xl);
      color: var(--brand-color);
    }
  }

  .nav-mode {
    display: none;
    border: 1px solid inherit;
    border-radius: var(--border-radius);

    .icon {
      width: 24px;
      height: 24px;
      color: inherit;
    }
  }
  .nav-mode-null {
    width: 24px;
    height: 24px;
    color: inherit;
  }

  @media only screen and (min-width: 961px) {
    gap: var(--gap-xl);
    flex-direction: row;

    > svg {
      display: flex;
      width: 15px;
      cursor: pointer;
    }

    .notification {
      .notification-box {
        display: flex;
        gap: 2px;
        cursor: pointer;
        align-items: flex-start;
      }

      .mobile-notification {
        display: none;
      }
    }

    .nav-avatar {
      display: inline;
      cursor: pointer;
      color: inherit;
    }

    .watch-points {
      display: flex;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        margin: 0 3px;
        border-radius: 100%;
        background-color: var(--white);
      }

      h2 {
        font-weight: 600;
        color: inherit;
        font-size: var(--fs-xl);
      }
    }

    .nav-mode {
      display: flex;
      cursor: pointer;
      height: 26px;
      width: 26px;
      padding: 2px;
      border: 1px solid;
      align-items: center;
      border-color: inherit;
    }
  }
}
