.skeleton {
  background: var(--heading);
  border-radius: var(--border-radius);
  position: initial;
  animation: shine 1s infinite;
}

.sk-sidebar-follower {
  width: 85%;
  height: 45px;
  margin: 5px auto;
  border-radius: var(--border-radius);
}

.sk-video-player {
  display: flex;
  width: 100%;
  height: auto;
  aspect-ratio: 16/10;
  background: #000 !important;
  border-radius: 0px !important;

  /* smartphone landscape */
  @media only screen and (min-width: 481px) and (orientation: landscape) {
    /* hi2 */
    aspect-ratio: 16/7;
  }

  /* portrait tablets */
  @media only screen and (min-width: 641px) and (orientation: portrait) {
    /* hi3 */
    height: 46vh;
  }

  /* Tablet landscape */
  @media only screen and (min-width: 961px) and (orientation: landscape) {
    aspect-ratio: 16/7;
  }

  @media screen and (min-width: 1025px) {
    height: auto;
    aspect-ratio: 16/8;
  }

  /* big laptop screens */
  @media only screen and (min-width: 1281px) {
    /* hi7 */
    aspect-ratio: 16/9;
  }
}

.sk-video-head1 {
  display: flex;
  width: 100%;
  height: 25px;
  margin: 2px 0;
}

.sk-very-small-circle {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}

.sk-small-circle {
  display: flex;
  width: 25px;
  height: 25px;
  margin: 3px 0;
  border-radius: 100%;
}

.sk-medium-circle {
  display: flex;
  width: 40px;
  height: 40px;
  margin: 3px 0;
  border-radius: 100%;
}

.sk-profile-circle {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.sk-rect {
  display: flex;
  width: 45px;
  height: 55px;
  border-radius: 3px;
}

.sk-notification {
  display: flex;
  width: 100%;
  min-height: 60px;
  border-radius: var(--border-radius);
}

.sk-big-name-text {
  width: 200px;
  height: 25px;
  border-radius: var(--border-radius);
}

.sk-name-text {
  display: flex;
  width: 180px;
  height: 17px;
  margin: 2px 0;
}

.sk-text {
  display: flex;
  width: 100px;
  height: 14px;
  margin: 2px 0;
}

.sk-sentence {
  width: 100%;
  height: 16px;
  margin: 2px 0 !important;
}

.sk-follow-btn {
  display: flex;
  min-width: 90px;
  height: 30px;
  padding: 0 1rem;
  border-radius: var(--border-radius);
}

.sk-about-box {
  display: flex;
  width: 100%;
  height: 100%;
}

/* .sk-side-video-card {
  display: flex;
  width: 300px;
  height: 110px;
  margin: 8px 0;
  overflow: hidden;
    border-radius: var(--border-radius);
} */

.sk-comment-card {
  display: flex;
  width: auto;
  height: 105px;
  margin: 15px 10px;
  border-radius: var(--border-radius);
}

.sk-my-course-card {
  display: flex;
  width: 95%;
  height: 130px;
  margin: 8px 0;
  border-radius: var(--border-radius);
}

.sk-prog-course-card {
  display: flex;
  width: 100%;
  height: 130px;
  margin: 8px 0;
  border-radius: var(--border-radius);
}

.sk-home-video-card {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  margin: 10px auto;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.sk-side-video-card {
  width: 90%;
  min-height: 100px;
  margin: 10px auto;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.sk-enrolled-card {
  display: flex;
  width: auto;
  height: 260px;
  border-radius: var(--border-radius);
  margin: 5px auto;
  box-shadow: 0px 0px 5px 2px rgba(63, 140, 255, 0.15);
}

.sk-instructor-card {
  display: flex;
  width: 100%;
  height: 150px;
  margin: 1em 0;
  border-radius: var(--border-radius);
  justify-content: center;
}

.sk-following-card {
  display: flex;
  width: auto;
  height: 180px;
  border-radius: var(--border-radius);
  flex-direction: column;
}

.sk-assignment-card {
  display: flex;
  width: 95%;
  height: 130px;
  margin: 10px auto;
  border-radius: var(--border-radius);
}

.sk-category-card {
  display: flex;
  width: 100%;
  min-height: 50px;
  border-radius: var(--border-radius);
}

.sk-interest-tab {
  width: 100%;
  height: 40px;
  margin: 3px auto;
  border-radius: var(--border-radius);
}

.sk-assignment-lines {
  width: auto;
  height: 15px;
  margin: 3px 0px;
  border-radius: var(--border-radius);
}

.sk-crousal-headline {
  width: 70%;
  height: 20px;
  margin: 5px 0;
  border-radius: var(--border-radius);
}

.sk-crousal-subheading {
  width: 55%;
  height: 15px;
  margin: 5px 0;
  border-radius: var(--border-radius);
}

.sk-watch-btn {
  width: 80px;
  height: 25px;
  margin: 8px 0;
  border-radius: var(--border-radius);
}

.sk-subject-card {
  display: grid;
  width: 100%;
  margin: 5px 0;
  height: 20px;
  padding: 5px 0;
  border-radius: var(--border-radius);
}

.sk-notif-card {
  display: grid;
  width: 100%;
  margin: 5px 0;
  height: 80px;
  padding: 5px 0;
  border-radius: var(--border-radius);
}

.sk-heat-map-box {
  width: auto;
  height: 150px;
  margin: 5px 10px;
  border-radius: var(--border-radius);
}

.sk-input-box {
  width: 100%;
  height: 60px;
  border-radius: var(--border-radius);
}

.sk-resume-page {
  display: flex;
  width: 100%;
  height: 84vh;
  content: "Loading";
  align-items: center;
  background-color: #fff;
  justify-content: center;
}

.sk-certificate-preview {
  width: 100%;
  aspect-ratio: 5/4;
}

@keyframes shine {
  0% {
    opacity: 0.2;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.2s;
    transition-timing-function: ease;
  }
  50% {
    opacity: 0.1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
  100% {
    opacity: 0.2;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}

/* smartphone portrait */
@media only screen and (min-width: 320px) and (orientation: portrait) {
  /* hi */
}

/* smartphone landscape */
@media only screen and (min-width: 481px) and (orientation: landscape) {
  /* hi2 */
}

/* portrait tablets */
@media only screen and (min-width: 641px) and (orientation: portrait) {
  /* hi3 */
}

/* tablet landscape */
@media only screen and (min-width: 961px) and (orientation: landscape) {
  /* hi4 */
  .sk-cr-crousel {
    min-height: 300px;
  }

  .sk-heat-map-box {
    width: auto;
    height: 160px;
    margin: 5px 10px;
    border-radius: var(--border-radius);
  }
}

/* big laptop screens */
@media only screen and (min-width: 1025px) {
  /* hi7 */

  .sk-video-player {
    border-radius: var(--border-radius);
  }

  .sk-crousal-headline {
    width: 45%;
    height: 35px;
    margin: 8px 0;
    border-radius: var(--border-radius);
  }

  .sk-crousal-subheading {
    width: 30%;
    height: 25px;
    margin: 8px 0;
    border-radius: var(--border-radius);
  }

  .sk-watch-btn {
    width: 120px;
    height: 35px;
    margin: 5px 0;
    border-radius: var(--border-radius);
  }

  .sk-heat-map-box {
    width: auto;
    height: 160px;
    margin: 5px 10px;
    border-radius: var(--border-radius);
  }

  .sk-cr-crousel {
    min-height: 420px;
  }

  .sk-cr-crousel::before {
    min-height: 450px;
  }
}

/* big laptop screens */
@media only screen and (min-width: 1281px) {
  /* hi7 */
}

.sk-alumniCard {
  height: 200px;
  width: 200px;
}
