.menu {
  display: flex;
  width: 120px;
  height: 40px;
  z-index: 97;
  position: relative;
  align-items: center;
  border: var(--border);
  color: var(--text-color);
  justify-content: space-around;
  background: var(--background);
  border-radius: var(--border-radius);

  .filter {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: inherit;
    align-items: center;
    justify-content: space-around;

    p {
      font-size: var(--fs-sm);
      text-transform: capitalize;
      font-weight: var(--fw-medium);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }
    }
  }

  .down-arrow {
    width: 22px;
    height: 22px;
    color: inherit;
  }

  .options {
    display: none;
    width: 100%;
    opacity: 0;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    position: absolute;
    transition: 0.15s ease;
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    background-color: var(--background);

    ul {
      display: flex;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 2px 0;
      z-index: 10000;
      list-style: none;
      flex-direction: column;
      transition: 0.15s ease;

      li {
        display: flex;
        width: 100%;
        margin: 1px 0;
        cursor: pointer;
        padding: 6px 20px;
        align-items: center;
        font-size: var(--fs-sm);
        text-transform: capitalize;
        border-radius: var(--border-radius);

        &:hover {
          background: var(--second-white);
        }

        @media screen and (min-width: 641px) {
          font-size: var(--fs-md);
        }
      }
    }
  }

  &:hover {
    .options {
      opacity: 1;
      display: flex;
      overflow: hidden;
      visibility: visible;
      z-index: 100000;
    }
  }

  @media screen and (min-width: 1024px) {
    width: 150px;
    height: 45px;
  }
}
