/* ---------------date input------------------- */
.date-input {
  width: 100%;
  border: none;
  width: 100%;
  color: var(--text-color);
  background: transparent;
  padding: 6px 10px 5px 30px !important;
  z-index: inherit;
}

.custom-input-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
}

.calendar-icon {
  left: 8px;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  font-size: var(--fs-lg);
  color: var(--text-secondary);
}

.input-label {
  color: var(--text-color);
  margin-bottom: 0.3rem;
}
