.pm-password-modal {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.pm-input-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  gap: 3px;
}

.pm-input-title {
  font-weight: var(--fw-medium);
  font-size: var(--fs-sm);
  line-height: 1.25;
  color: var(--text-color2);
}

.pm-input-field {
  display: flex;
  margin: 5px 0;
  width: 100%;
  height: 40px;
  font-size: var(--fs-xs);
  padding: 0 12px;
  border: none;
  outline: none;
  border-radius: var(--border-radius);
  color: var(--heading);
  text-transform: none !important;
  background-color: var(--background);
}

.pm-input-field::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

.pm-input-field:focus {
  outline: none;
}

.pm-pass-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-md);
  padding-top: 10px;
  font-size: var(--fs-xs);
}

.pm-cancel-btns {
  display: flex;
  width: 100px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  font-weight: var(--fw-normal);
  border-radius: var(--border-radius);
  cursor: pointer;
  color: white;
  background-color: var(--heading);
  transition: 0.15s ease;
}

.dark .pm-cancel-btns {
  color: black;
}

.pm-save-btns {
  display: flex;
  min-width: 100px;
  height: 30px;
  padding: 0 8px;
  font-size: inherit;
  align-items: center;
  justify-content: center;
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  color: white;
  transition: 0.15s ease;
  background-color: var(--brand-color);
}

.pm-cancel-btns:hover,
.pm-save-btns:hover {
  opacity: 0.85;
}

/* Profile Modal css */

.pdm-header {
  display: flex;
  font-size: var(--fs-lg);
  font-weight: var(--fw-medium);
  line-height: 30px;
  color: var(--text-color2);
  justify-content: space-between;
}

.pdm-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px 0;
  flex-direction: column;
  border-bottom: 1px solid var(--text-box);
}

.pdm-left-container {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* border-right: 0.5px solid var(--text-box); */
}

.pdm-user-profile-box {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.pdm-user-profile {
  width: 60px !important;
  height: 60px !important;
}

.pdm-profile-pencil-icon {
  display: flex;
  width: 10px;
  overflow: hidden;
}

.pdm-user-profile-pencil {
  display: flex;
  z-index: 1;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-top: -15px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  background-color: #202b47;
}

.pdm-user-profile-pencil:focus {
  outline: none;
  border: none;
}

.pdm-user-profile-pencil:hover {
  background-color: rgb(32, 43, 71, 0.9);
}

.pdm-right-container {
  display: grid;
  width: 100%;
  height: 100%;
  justify-items: center;
}

.pdm-input-password {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: -35px;
    color: rgb(46, 46, 46);
  }
}

.pdm-input-box {
  display: flex;
  width: 90%;
  height: auto;
  margin: 2px 0;
  justify-content: center;
  flex-direction: column;
}

.pdm-eye-icon {
  display: flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: -35px;
}

.pdm-input-title {
  font-weight: var(--fw-medium);
  padding: 0 5px;
  font-size: var(--fs-sm);
  line-height: 20px;
  color: var(--text-color2);
}

.pdm-input-field {
  display: flex;
  height: 35px;
  border: none;
  font-size: var(--fs-sm);
  padding: 0 10px;
  border-radius: var(--border-radius);
  color: var(--text-color);
  background: var(--text-box);
  box-shadow:
    0px 0px 1px rgba(108, 93, 211, 0.2),
    0px 1px 3px rgba(108, 93, 211, 0.3);
}

.pdm-input-field::placeholder {
  color: var(--text-color);
  opacity: 0.7;
}

.pdm-input-field:focus {
  outline: none;
}

.pdm-login-error {
  display: flex;
  width: 95%;
  height: auto;
}

.pdm-login-error > span {
  font-weight: var(--fw-medium);
  font-size: var(--fs-xs);
  color: var(--sidebar-tab-active);
}

.pm-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 400px) {
  .pm-pass-btns {
    padding-top: 1rem;
    gap: var(--gap-md);
  }
}

@media screen and (min-width: 450px) {
  .pm-pass-btns {
    padding-top: 1.5rem;
  }
}

@media screen and (min-width: 550px) {
  .pm-pass-btns {
    padding-top: 20px;
    gap: var(--gap-lg);
  }
}

/* big laptop screens */
@media only screen and (min-width: 1025px) {
  .pm-input-field {
    font-size: var(--fs-md);
  }

  .pm-pass-btns {
    font-size: var(--fs-sm);
    gap: var(--gap-lg);
  }

  .pm-cancel-btns,
  .pm-save-btns {
    height: 35px;
  }

  /* Profile Modal */

  .pdm-header {
    font-size: var(--fs-xl);
    line-height: 10px;
    padding-bottom: 5px;
  }

  .pdm-container {
    display: grid;
    width: 100%;
    height: 100%;
    padding: 8px 0;
    grid-template-columns: 1.5fr 3fr;
    border-bottom: 1px solid var(--text-box);
  }

  .pdm-left-container {
    border-right: 0.5px solid var(--text-box);
  }

  .pdm-user-profile {
    width: 100px !important;
    height: 100px !important;
  }

  .pdm-profile-pencil-icon {
    width: 14px;
  }

  .pdm-user-profile-pencil {
    width: 25px;
    height: 25px;
  }

  .pdm-login-error {
    width: 80%;
  }

  .pdm-login-error > span {
    font-size: var(--fs-sm);
  }
}
