.content {
  width: 100%;
  border: 1px solid gray;
  display: flex;
  gap: var(--gap-lg);
  flex-direction: column;
  z-index: 1000;

  .add-content {
    display: flex;
    gap: var(--gap-md);

    .btn {
      padding: 10px 15px;
      color: var(--brand-color);
      background-color: transparent;
    }
  }

  .new-lec {
    padding: 8px 5px;
    width: 100px;
  }
}

.container {
  // z-index: 1000;
  display: flex;
  padding: 15px;
  gap: var(--gap-md);
  flex-direction: column;
  background-color: var(--background);
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 6px 1px var(--light-shadow2);
  -moz-box-shadow: 0px 0px 6px 1px var(--light-shadow2);
  -webkit-box-shadow: 0px 0px 6px 1px var(--light-shadow2);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftContent {
      width: max-content;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        color: var(--text-color);
        width: 25px;
        // border: 1px solid red;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: grab;

        .icon {
          font-size: 18px;
        }
      }
    }

    p {
      font-size: var(--fs-md);
      color: var(--text-color);
      font-weight: var(--fw-medium);

      @media screen and (min-width: 1500px) {
        font-size: var(--fs-lg);
      }
      @media screen and (min-width: 2000px) {
        font-size: var(--fs-xl);
      }
    }

    .icon {
      color: var(--text-color);
    }

    .arrow-up {
      transform: rotate(180deg);
      transition: 0.3s ease;
    }
  }

  .sub-container {
    display: flex;
    gap: var(--gap-lg);
    flex-direction: column;

    p {
      color: var(--text-color);
    }

    .input {
      border: 1px solid var(--black);
    }
    .error {
      border: 1px solid var(--red);
    }

    .assignment {
      display: flex;
      gap: var(--gap-md);
      flex-direction: column;
    }

    .action {
      display: flex;
      align-self: end;
      gap: var(--gap-md);

      .cancel {
        padding: 10px 15px;
        color: var(--text-color);
        background-color: var(--background);
      }

      .add {
        padding: 10px 15px;
        color: var(--white);
        background-color: var(--black);
      }
    }

    .thumbnails {
      display: flex;
      padding: 10px;
      gap: var(--gap-md);
      flex-direction: column;
      border: var(--border);
      border-radius: var(--border-radius);
      background-color: var(--background);

      h3 {
        font-size: var(--fs-md);
        font-weight: var(--fw-medium);
        line-height: 1.25;
        color: var(--paragraph);
      }

      .box {
        display: flex;
        gap: var(--gap-lg);
        align-items: center;

        .thumbnail-upload {
          display: flex;
          width: 150px;
          height: auto;
          cursor: pointer;
          overflow: hidden;
          aspect-ratio: 16/9;
          border-radius: var(--border-radius);
          align-items: center;
          flex-direction: column;
          justify-content: center;
          background: var(--second-white);

          .icon {
            position: absolute;
            z-index: 5;
            height: 30px;
            width: auto;
            opacity: 0.8;
            padding: 3px;
            cursor: pointer;
            color: var(--black);
            border-radius: 3rem;
            background-color: var(--white);
            box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.4);

            &:hover {
              color: var(--white);
              background-color: var(--brand-color);
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 16/9;
        }

        .thumbnailImage {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 16/9;
        }

        .selected {
          top: 50%;
          left: 50%;
          width: 30px;
          height: 30px;
          position: absolute;
          border-radius: 50%;
          color: var(--white);
          transform: translate(-50%, -50%);
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .videoDesc {
      font-size: var(--fs-md) !important;
      p,
      li,
      ul {
        color: var(--text-color);
        font-size: var(--fs-md);

        @media screen and (min-width: 1500px) {
          font-size: var(--fs-lg);
        }
      }
    }
  }

  .collapsed {
    display: none !important;
  }
}

.upload {
  width: 40%;
  height: auto;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  aspect-ratio: 16/9;
  position: relative;
  align-items: center;
  border: var(--border);
  flex-direction: column;
  justify-content: center;
  border-radius: var(--border-radius);
  background-color: var(--background);

  &:focus {
    outline: none;
    border: none;
  }

  .icon {
    height: 35px;
    width: auto;
    opacity: 0.75;
    cursor: pointer;
    color: var(--black);
  }

  .cancel {
    z-index: 5;
    height: 40px;
    width: 40px;
    padding: 8px;
    cursor: pointer;
    position: absolute;
    border-radius: 3rem;
    transition: 0.15s ease;
    background-color: var(--white);
    box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.5);

    &:hover {
      opacity: 0.9;
      color: var(--white);
      background-color: var(--brand-color);
    }
  }

  .tagline {
    margin: 0;
    font-size: var(--fs-md);
    font-weight: var(--fw-medium);
    line-height: 1.25;
    margin-top: 20px;
    color: var(--heading);
  }
}

.errorText {
  font-size: var(--fs-sm);
  color: var(--red-shade);
  padding-top: 3px;
}

.requiredField {
  color: var(--red-shade);
}
