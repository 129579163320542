//Search Dropdown

.selectContainer {
  margin: 5px auto;
  z-index: 10;

  .input-field-select {
    background-color: var(--background-secondary) !important;
    color: var(--fs-md) !important;

    &:hover {
      border: 1px solid var(--brand-color);
    }
  }

  .option {
    width: 100%;
    padding: 12px 7px;
    color: var(--fs-md);
    background-color: var(--background-secondary);

    &:hover {
      background-color: var(--background);
      color: var(--text-color);
    }
  }

  .unfocused-input {
    border: 1px solid var(--background-secondary);
    background-color: var(--background-secondary) !important;
    border-radius: 8px;
    box-shadow: none;
  }

  .focused-input {
    background-color: var(--background-secondary) !important;
    color: var(--text-color) !important;
    border-radius: 8px;
    box-shadow: none;
  }

  .valueContainer {
    color: var(--text-color) !important;
  }

  .menu-container {
    background-color: var(--background-secondary) !important;
    color: var(--text-color) !important;
    z-index: 3;
  }

  .search-dropdown {
    background-color: var(--background-secondary);
  }
}

/* common styles */
.container {
  display: flex;
  width: 100%;
  z-index: 0;
  gap: var(--gap-sm);
  flex-direction: column;

  .input-label {
    line-height: 1.25;
    font-size: var(--fs-md);
    color: var(--text-color);
    font-weight: var(--fw-medium);

    .requiredField {
      color: var(--red-shade);
    }
  }

  .input-divider-section-left {
    width: 60%;
  }
  .input-divider-section-right {
    width: 40%;
  }
  .input-field-select {
    padding: 1px;
    margin-top: 4px;
    z-index: 0;
    border-radius: var(--border-radius);
  }

  .input-field-select-error {
    padding: 1px;
    margin-top: 4px;
    border: 1px solid var(--red);
    border-radius: var(--border-radius);
  }

  .control-color {
    background-color: var(--background-secondary);
    border: 0px solid var(--background-secondary);
    color: var(--text-color);
    width: 100%;
  }
  .color-input {
    line-height: 20px;
    color: var(--text-color);
  }
  .color {
    color: var(--text-color);
    background-color: var(--background);
  }
  .color-selected {
    color: var(--text-color);
    background-color: var(--text-box);
  }
}
