.ep-container {
  display: flex;
  gap: var(--gap-md);
  margin-bottom: 10px;
  flex-direction: column;

  @media screen and (max-width: 941px) {
    padding-bottom: 50px;
  }
}

.ep-close-icon {
  height: 30px;
  width: auto !important;
  aspect-ratio: 1/1;
  border-radius: 3rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 5px;
  transition: 0.15s ease;
  cursor: pointer;
}

.ep-close-icon:hover {
  background-color: var(--second-white);
}

.ep-left-container {
  display: flex;
  gap: var(--gap-lg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ep-user-profile-box {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.ep-user-email {
  cursor: not-allowed;
  h4 {
    font-weight: var(--fw-medium);
    color: var(--text-color);
    text-transform: lowercase;
  }
}

.ep-user-profile {
  height: 80px !important;
  width: auto !important;
  aspect-ratio: 1/1 !important;
}

.ep-user-profile-pencil {
  display: flex;
  top: 100%;
  left: 50%;
  z-index: 2;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  transform: translate(-50%, -50%);
  background-color: var(--background);
  box-shadow: var(--card-shadow-hover);
}

.ep-user-profile-pencil:hover .ep-profile-pencil-icon {
  opacity: 0.75;
}

.ep-profile-pencil-icon {
  color: var(--heading);
  height: 1rem;
  width: auto;
  transition: 0.15s ease;
}

.ep-right-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
}

.ep-horizontal {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--gap-md);
}

.ep-input-box {
  width: 100%;
}

.ep-input-title {
  line-height: 1.5;
  font-size: var(--fs-md);
  color: var(--text-color);
  font-weight: var(--fw-medium);
}

.ep-input-username-box {
  width: 100%;
  height: max-content;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

.ep-error-text {
  color: var(--sidebar-tab-active);
  font-size: var(--fs-sm);
}

.ep-input-username-box-left {
  width: 70%;
}

.ep-input-username-box-right {
  display: flex;
  height: 100%;
  margin-top: auto;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
}

.ep-username-btn {
  width: 90%;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  color: var(--background);
  background-color: var(--text-color);
}

.ep-input-field {
  padding: 7px 10px;
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  width: 100%;
  margin-top: 3px;
  line-height: 1.5;
  color: var(--heading);
  font-size: var(--fs-md);
  font-weight: var(--fw-medium);
  background-color: var(--background);
}

.ep-username-sugg-btn {
  display: flex;
  min-width: 100px;
  height: 30px;
  padding: 0 8px;
  font-size: inherit;
  align-items: center;
  justify-content: center;
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  color: white;
  transition: 0.15s ease;
  background-color: var(--brand-color);
}
.ep-username-sugg-btn:hover {
  opacity: 0.85;
}

@media screen and (min-width: 401px) {
  .ep-user-profile {
    height: 100px !important;
  }
}

@media screen and (min-width: 601px) {
  .ep-container {
    /* display: grid; */
    display: flex;
    flex-direction: column;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  .ep-horizontal {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: var(--gap-md);
  }

  .ep-input-box {
    width: 50%;
  }
  .ep-username-btn {
    width: 90%;
    /* height: 90%; */
    padding: 0.75rem;
    border-radius: var(--border-radius);
    background-color: var(--text-color);
  }

  .ep-left-container {
    padding: 30px;
  }

  .ep-right-container {
    width: 100%;
  }

  .ep-user-profile {
    height: 120px !important;
  }

  .ep-input-field,
  .ep-input-title {
    font-size: var(--fs-sm);
  }
  .ep-error-text {
    color: var(--sidebar-tab-active);
    font-size: var(--fs-sm);
  }
}

@media screen and (min-width: 801px) {
  .ep-user-profile {
    height: 150px !important;
  }

  .ep-input-field {
    padding: 10px 16px;
  }

  .ep-right-container {
    gap: var(--gap-lg);
  }
}
