.container {
  display: grid;
  gap: 5px;
  width: auto;
  height: auto;
  padding: 10px;
  cursor: pointer;
  border-radius: var(--border-radius);
  align-items: center;
  color: var(--text-color);
  background: var(--background-secondary);
  grid-template-columns: 40px auto;

  .icon {
    width: 25px;
    height: 25px;
  }
  svg {
    width: 30px;
    height: 30px;
    color: var(--text-color);
  }

  h3 {
    font-size: var(--fs-sm);
    font-weight: var(--fw-medium);
    white-space: initial;
    text-transform: none;

    @media screen and (min-width: 641px) {
      font-size: var(--fs-md);
    }
  }

  .metadata {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 961px) {
      gap: var(--gap-sm);
      flex-direction: column;
      align-items: flex-start;
    }

    @media screen and (min-width: 1081px) {
      display: flex;
      margin-top: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .duration {
      display: flex;
      gap: 5px;
      align-items: center;

      svg {
        color: var(--text-color);
      }
    }
    .resource-container {
      display: flex;
      gap: var(--gap-sm);
      align-items: center;
      @media screen and (min-width: 961px) {
        justify-content: space-between;
        width: 100%;
      }
      @media screen and (min-width: 1081px) {
        justify-content: unset;
        width: auto;
      }

      .resource {
        height: 28px;
        padding: 0px;
        width: 95px;
        padding: 4px 5px;
        justify-content: space-between;
        .filter {
          display: flex;
          gap: var(--gap-sm);
        }
        div {
          width: auto !important;
        }
        ul {
          z-index: 1000;
          width: 130px;
          li {
            font-size: var(--fs-xs) !important;
            text-transform: none !important;
            text-align: center !important;
            max-width: 100% !important;
            overflow-x: hidden;
            display: inline-block;
            padding: 5px;
            color: var(--text-secondary);
            text-overflow: ellipsis !important;
            white-space: nowrap !important;
          }
        }

        p {
          font-size: var(--fs-xs);
          font-weight: var(--fw-light);
        }
        @media screen and (min-width: 641px) {
          p {
            font-size: var(--fs-sm) !important;
            font-weight: var(--fw-light) !important;
            text-transform: none;
          }
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
      color: var(--text-color);
    }

    .checked {
      width: 20px;
      height: 20px;
      color: var(--success-color);
      path {
        stroke: 2px solid black;
      }
    }
  }
}
.selected {
  border: 1px solid var(--brand-color);
  background-color: var(--background);
}

/*--------------------------*/
// .sd-card-container.restricted-stage{
//   background-color: rgba(50, 50, 50, 0.152);
// }

// .sd-card-selected {
//   border-color: var(--brand-color);
//   box-shadow: 0 0 0 0.5px var(--brand-color);
// }

// .restricted-stage .sd-card-image{
//   position: relative;

// }
// .restricted-stage .sd-card-image img{
//   filter: blur(2px);
// }

// .sd-lock-icon, .sd-wait-icon{
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transition: transform 0.3s ease;
//   transform: translate(-50%, -50%);
// }

// .sd-card-image:hover .sd-wait-icon, .sd-card-image:hover .sd-lock-icon {
//   animation: rotateAnimation 0.3s ease forwards;
// }

// @keyframes rotateAnimation {

//   100% {
//     transform: translate(-50%, -50%) rotate(-180deg);
//   }
// }

// .sd-card-image {
//   display: flex;
//   width: 45%;
//   height: 100%;
//   cursor: pointer;
//   background-color: var(--background);
// }

// .sd-image {
//   display: flex;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

// .sd-card-details {
//   display: flex;
//   flex: 1;
//   height: 100%;
//   padding: 10px;
//   flex-direction: column;
// }

// .sd-card-title {
//   display: flex;
//   width: 100%;
//   overflow: hidden;
//   white-space: normal;
//   overflow-wrap: break-word;
// }

// .sd-card-title > span {
//   font-weight : var(--fw-medium);;
//   font-size : var(--fs-md)px;
//   line-height: 1.5;
//   display: -webkit-box;
//   -webkit-line-clamp: 2;
//   color: var(--heading);
//   -webkit-box-orient: vertical;
// }

/* Hide scrollbar for Chrome, Safari and Opera */
.sd-card-title::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sd-card-title {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// .sd-card-subtitle > span {
//   display: none;
//   font-weight : var(--fw-light);;
//   font-size: 13px;
//   line-height: 20px;
//   color: var(--text-color);
// }

// .sd-card-instructor {
//   display: grid;
//   width: 100%;
//   height: 20px;
//   margin-top: auto;
//   align-items: center;
//   grid-template-columns: 1fr auto;
// }

// .sd-instructor-left {
//   display: grid;
//   align-items: center;
//   gap: 5px;
//   grid-template-columns: auto 1fr;
// }

// .sd-card-instructor-profile {
//   width: 15px !important;
//   height: 15px !important;
//   background-color: var(--avatar) !important;
// }

// .sd-card-instructor-name {
//   font-weight: var(--fw-medium);
//   font-size: 12px;
//   line-height: 1.25;
//   color: var(--heading);
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   line-clamp: 1;
//   -webkit-line-clamp: 1;
//   overflow: hidden;
//   white-space: normal;
// }

// .sd-instructor-right {
//   display: flex;
//   color: var(--heading);
//   opacity: 0.2;
// }

// .dark .sd-instructor-right {
//   opacity: 0.3;
// }

// .sd-instructor-right.true {
//   opacity: 1;
//   color: rgb(0, 200, 0);
// }
