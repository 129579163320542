.break-point {
  display: flex;
  width: 100%;
  gap: var(--gap-md);
  color: var(--white);
  flex-direction: column;
  padding: var(--gap-md);
  padding-bottom: 0px;
  border-radius: var(--border-radius);

  @media screen and (min-width: 641px) {
    display: grid;
    height: 170px;
    gap: var(--gap-lg);
    padding: 0 var(--gap-lg);
    grid-template-columns: auto 280px;
  }

  @media screen and (min-width: 961px) {
    height: 170px;
    grid-template-columns: auto 300px;
  }

  @media screen and (min-width: 1500px) {
    height: 170px;
    grid-template-columns: auto 360px;
  }

  .content {
    display: flex;
    width: 100%;
    gap: var(--gap-sm);
    justify-content: center;
    flex-direction: column;

    h3 {
      display: block;
      font-size: var(--fs-lg);
      font-weight: var(--fw-medium);
      text-transform: none !important;
    }

    p {
      font-size: var(--fs-sm);

      span {
        font-weight: var(--fw-medium);
      }
    }

    @media screen and (min-width: 641px) {
      h3 {
        font-size: var(--fs-xl);
      }

      p {
        font-size: var(--fs-md);
      }
    }

    @media screen and (min-width: 1500px) {
      h3 {
        font-size: var(--fs-2xl);
      }
    }
  }

  > figure {
    margin-top: auto;
    text-align: center;
    padding-top: var(--gap-md);
    height: inherit !important;
    img {
      width: 60%;
      height: auto;
    }
    @media screen and (min-width: 641px) {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
