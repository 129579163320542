.container {
  display: flex;
  cursor: pointer;
  height: 145px;
  overflow: hidden;
  margin: 10px 0;
  color: var(--heading);
  align-items: flex-start;

  .avatar-section {
    display: flex;
    width: 100%;
    max-width: 340px;
    min-width: 240px;
    overflow: hidden;
    margin-right: 16px;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    border-radius: var(--border-radius);
  }

  .info-section {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .profile-link {
      display: flex;
      flex-direction: column;

      h3 {
        display: -webkit-inline-box;
        font-size: var(--fs-xl);
        font-weight: var(--fw-medium);
        overflow: hidden;
        margin: 0 0 5px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .meta {
        display: flex;
        margin: 0 0 8px;
        align-items: center;
        color: var(--text-secondary);

        span {
          font-size: var(--fs-sm);
        }

        .saperator {
          margin: 0 5px;
        }
      }

      .about {
        display: -webkit-inline-box;
        overflow: hidden;
        font-size: var(--fs-sm);
        -webkit-line-clamp: 2;
        color: var(--text-secondary);
        -webkit-box-orient: vertical;
      }
    }
  }

  .mb-info-section {
    display: none;
  }

  .action-section {
    display: flex;
    padding: 0 5%;
    align-items: center;

    .follow-btn {
      min-width: 120px;
      padding: 15px auto;
      color: var(--background);
      border-radius: var(--border-radius);
      background-color: var(--text-color);
    }
  }

  @media screen and (max-width: 641px) {
    display: grid;
    height: auto;
    gap: var(--gap-md);
    padding: 0 2%;
    grid-template-columns: 2fr 3fr;

    .avatar-section {
      display: flex;
      max-width: none;
      min-width: unset;
      margin-right: 0;
    }

    .info-section {
      display: none;
    }

    .mb-info-section {
      display: flex;
      flex-direction: column;

      .profile-link {
        display: grid;
        gap: 5px;
        margin: 0 0 8px;
        font-size: var(--fs-sm);
        color: var(--text-secondary);

        h3 {
          display: -webkit-inline-box;
          font-size: var(--fs-md);
          font-weight: var(--fw-medium);
          overflow: hidden;
          color: var(--heading);
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .meta-name {
          display: -webkit-inline-box;
          overflow: hidden;
          line-height: 16px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .action-section {
        padding: 0;
        .follow-btn {
          max-width: 100px;
          padding: 15px auto;
          border-radius: 1000px;
          background-color: var(--heading);

          span {
            font-weight: var(--fw-medium);
            font-size: var(--fs-sm) !important;
            color: var(--profile-cover);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
}

@media screen and (min-width: 850px) {
}

@media screen and (min-width: 1025px) {
}

@media screen and (min-width: 1400px) {
}
