.mini {
  width: 100%;
  color: var(--text-color);
  padding-top: 0px !important;
  background-color: var(--background);
}

.footer {
  width: 100%;
  overflow: hidden;
  padding: 30px 20px;
  color: var(--text-color);
  background-color: var(--second-white);

  @media screen and (min-width: 641px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media screen and (min-width: 1025px) {
    padding-left: 4rem;
    padding-right: 4rem;
    margin-bottom: 0px;
  }
  @media screen and (min-width: 1225px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 90%;
    z-index: 1;
    transform: translateX(-50%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: var(--bg-main2);

    @media screen and (max-width: 1800px) {
      width: 95%;
    }

    @media screen and (max-width: 1460px) {
      width: 97%;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      border-radius: 0;
    }
  }

  > main {
    display: flex;
    z-index: 2;
    gap: 10px;
    margin: 0 auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }
}

.copyright {
  font-size: var(--fs-sm);

  @media screen and (max-width: 800px) {
    font-weight: var(--fw-medium);
  }
}

.links {
  display: flex;
  gap: var(--gap-xl);
  align-items: center;
  justify-content: center;

  svg {
    height: 25px;
    width: auto;
    cursor: pointer;
    opacity: 0.85;
    transition: 0.15s ease;

    &:hover {
      color: var(--brand-color);
      opacity: 1;
    }
  }

  @media screen and (max-width: 600px) {
    gap: var(--gap-lg);
  }
}
