.container {
  display: flex;
  width: 100%;
  padding: 12px;
  cursor: pointer;
  overflow: hidden;
  border: var(--border);
  box-sizing: border-box;
  transition: 0.15s ease;
  flex-direction: column;
  background-color: inherit;
  border-radius: var(--border-radius);

  h2 {
    display: block;
    color: inherit;
    line-height: 1.25;
    white-space: nowrap;
    font-size: var(--fs-md);
    text-overflow: ellipsis;
    font-weight: var(--fw-medium);
  }
}
