.container {
  width: 100%;
  margin-left: 15px;
}

.lists {
  display: flex;
  padding: 10px;
  flex-direction: column;

  .accordian {
    gap: 10px;
    padding: 10px 5px;
    grid-template-columns: auto 20px;
    h3 {
      font-size: var(--fs-sm);
      display: inline;
      text-overflow: ellipsis;
    }
  }

  .link {
    display: flex;
    align-items: center;
    color: var(--text-color);
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      gap: 5px;
      width: auto;
      cursor: pointer;
      font-size: var(--fs-xs);
      padding: 5px 1rem;
      border-radius: var(--border-radius);
      align-items: center;
      color: var(--text-color);
      text-transform: capitalize;
      // box-shadow: var(--shadow);
      background: var(--background);

      svg {
        height: 20px;
        width: 20px;
      }

      &:hover {
        opacity: 0.8;
      }

      p {
        font-weight: var(--fw-medium);
        color: var(--text-color);
      }
    }

    .vd-options-cont {
      display: none;
      width: auto;
      bottom: auto;
      opacity: 0;
      top: 100%;
      right: 0;
      z-index: 10;
      visibility: hidden;
      position: absolute;

      ul {
        display: flex;
        width: 100%;
        z-index: 1;
        height: 100%;
        padding: 8px;
        margin-top: 5px;
        list-style: none;
        overflow: hidden;
        transition: 1s ease;
        flex-direction: column;
        box-shadow: var(--shadow);
        border-radius: var(--border-radius);
        background-color: var(--background);

        li {
          display: flex;
          width: auto;
          cursor: pointer;
          padding: 5px 8px;
          font-size: var(--fs-sm);
          font-weight: var(--fw-medium);
          white-space: nowrap;
          align-items: center;
          text-transform: capitalize;

          a {
            display: flex;
            align-items: center;
          }

          p,
          a {
            color: var(--text-color);
          }

          &:hover {
            background-color: #eeeeee5b;
            svg {
              color: var(--brand-color);
            }
            p,
            a {
              color: var(--brand-color);
            }
          }
        }
      }
    }

    .right:hover > .vd-options-cont {
      opacity: 1;
      display: flex;
      visibility: visible;
      transition: 0.2s ease;
    }
  }
}
