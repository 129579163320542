.content {
  display: grid;
  gap: var(--gap-md);
  width: 100%;
  padding: 5px 0;
  min-height: 60px;
  overflow: hidden;
  margin-right: auto;
  align-items: center;
  grid-template-columns: 40px auto;
  border-bottom: 1px solid rgba(165, 165, 165, 0.514);
}

.body {
  display: grid;
  gap: 5px;
  cursor: pointer;
  align-items: center;
  color: var(--text-color);
  grid-template-columns: auto 80px;

  > span {
    padding-left: 10px;
    font-weight: var(--fw-light);
    font-size: var(--fs-xs);
  }

  > div > p:nth-child(2) {
    color: var(--text-secondary);
  }

  + p {
    width: 240px;
  }
}

.oneliner {
  line-clamp: 1;
  overflow: hidden;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  font-size: var(--fs-sm);
  -webkit-box-orient: vertical;

  span {
    font-weight: var(--fw-medium);
    font-size: var(--fs-md);
  }
}

.twoliner {
  line-clamp: 2;
  overflow: hidden;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  span {
    font-weight: 600;
  }
}
