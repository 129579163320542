.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  z-index: 1000;
  height: 100%;
  gap: var(--padding);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white-shade);
}

.content {
  text-align: center;
  height: 250px;
  width: 250px;

  @media screen and (max-width: 1400px) {
    height: 220px;
    width: 220px;
  }

  @media screen and (max-width: 600px) {
    height: 150px;
    width: 150px;
  }
}

.CircleCont {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ImageCont {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
