.sn-header {
  display: grid !important;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.sn-main-cont {
  height: 100%;
  flex: 1;
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sn-textfield-row {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.sn-textfield {
  padding: 10px;
  border: none;
  outline: none;
  line-height: 1.5;
  border: var(--border);
  color: var(--heading);
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  background-color: var(--second-white);
}

.sn-textfield::placeholder {
  font-size: var(--fs-sm);
}

.sn-textfield input {
  color: var(--heading) !important;
}

.sn-msg-cont {
  height: 100px;
  padding: 10px;
  resize: none;
  outline: none;
  width: 100%;
  line-height: 1.5;
  border: var(--border);
  color: var(--heading);
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  background-color: var(--second-white);
}

.sn-msg-cont::placeholder {
  font-size: var(--fs-sm);
}

@media screen and (min-width: 401px) {
}

@media screen and (min-width: 601px) {
  .sn-textfield-row,
  .sn-main-cont {
    gap: 15px;
  }
}

@media screen and (min-width: 801px) {
  .sn-textfield,
  .sn-msg-cont {
    padding: 13px;
    border-radius: var(--border-radius);
  }
}

.sn-msg-btn,
.fm-msg-btn1 {
  display: flex;
  width: 100px;
  border: none;
  height: 30px;
  opacity: 0.5;
  font-weight: var(--fw-medium);
  cursor: not-allowed;
  border-radius: var(--border-radius);
  align-items: center;
  justify-content: center;
  color: var(--common-text-color);
  background-color: var(--sidebar-tab-active);
}

.fm-msg-btn1 {
  width: 100px;
  background-color: var(--sidebar-tab-active);
}

.sn-textfield:focus,
.sn-msg-btn:focus,
.sn-msg-cont:focus {
  outline: none;
}

.sn-btn-active {
  opacity: 1;
  cursor: pointer;
}

.fm-btns-cont {
  display: flex;
  width: auto;
  margin: 0 auto;
  padding: 10px 0;
  gap: 15px;
}

@media screen and (min-width: 500px) {
  .sn-msg-btn,
  .fm-msg-btn1 {
    height: 32px;
    border-radius: var(--border-radius);
  }

  .fm-btns-cont {
    padding: 10px 0 0;
  }
}

@media screen and (min-width: 801px) {
  .sn-textfield,
  .sn-msg-cont {
    padding: 13px;
    border-radius: var(--border-radius);
  }
}

@media screen and (min-width: 1001px) {
  .sn-textfield-row,
  .sn-main-cont {
    gap: 20px;
  }
}

@media screen and (min-width: 1201px) {
  .sn-textfield,
  .sn-msg-cont {
    padding: 10px 16px;
  }
}

@media screen and (min-width: 1401px) {
}

.ca-underline {
  height: 1px;
  width: 100%;
  margin-bottom: 5px;
  background-color: var(--border-color);
}

.ca-main-cont {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  flex: 1;
  height: 100%;
  overflow: hidden auto;

  @media screen and (min-width: 601px) {
    gap: var(--gap-lg);
  }
  @media screen and (min-width: 701px) {
    gap: var(--gap-xl);
  }
  @media screen and (min-width: 801px) {
    gap: var(--gap-lg);
  }
}

.sf-btns-cont {
  display: flex;
  margin: 10px 0;
  gap: var(--gap-md);
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: 601px) {
    .sf-btns-cont {
      gap: var(--gap-lg);
    }
  }

  @media screen and (min-width: 1001px) {
    .sf-btns-cont {
      gap: var(--gap-xl);
    }
  }
}

.sf-msg-btn,
.sf-msg-btn1 {
  height: 30px;
  border: none;
  opacity: 0.9;
  outline: none;
  min-width: 100px;
  padding: 0 1rem;
  cursor: not-allowed;
  transition: 0.15s ease;
  font-size: var(--fs-xs);
  color: var(--background);
  background-color: var(--heading);
  border-radius: var(--border-radius);
}

.sf-msg-btn {
  color: var(--white);
  background-color: var(--brand-color);
}

.sf-msg-btn.sf-btn-active,
.sf-msg-btn1.sf-btn-active {
  opacity: 1;
  cursor: pointer;
  color: var(--white);
}

.sf-msg-btn.sf-btn-active:hover,
.sf-msg-btn1.sf-btn-active:hover {
  opacity: 0.85;
}
