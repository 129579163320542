.ddm-smenu-container {
  overflow: auto;
  max-height: 400px;
  top: 100% !important;
  right: 0 !important;
  left: auto !important;
  position: absolute !important;
  border-radius: var(--border-radius);
}

.ddm-menu-container {
  right: 0px;
  left: auto !important;
  overflow: auto;
  max-height: 400px;
  border-radius: var(--border-radius);
  width: fit-content;
  top: 100% !important;
  position: absolute !important;
}

.nav-avatar .ddm-menu-container {
  left: 0 !important;
}

.admin .nav-avatar .ddm-menu-container {
  right: 0;
  left: auto !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ddm-menu-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ddm-menu-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ddm-cont {
  color: var(--heading) !important;
  background-color: var(--menu-back) !important;
}

.ddm-menu-item {
  font-weight: var(--fw-light) !important;
  /* border: 1px solid red !important; */
}

#menu-list-grow > .MuiMenuItem-root {
  min-height: auto;
  padding: 3px 16px;
  font-weight: var(--fw-normal) !important;
  font-size: var(--fs-sm);
}

.rpm-cont {
  display: flex;
  top: 50px;
  right: 0;
  z-index: 10;
  padding: 5px 10px;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background: var(--background);
  border-radius: var(--border-radius);

  svg {
    color: var(--text-color);
  }
}

.rpm-box {
  padding: 1em;
  width: 250px;
  display: flex;
  overflow: hidden;
  gap: var(--gap-md);
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.rpm-arrow {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: auto;
  margin-top: -20px;
  color: var(--text-color);
  background-color: var(--background);
}

.rpm-watchpoint {
  width: 20px;
  height: 20px;
  margin: 0 3px;
  border-radius: 100%;
  background-color: var(--common-text-color);
}

.rpm-top {
  display: flex;
  height: 30px;
  gap: 3px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  img {
    width: 25px;
    height: 25px;
  }
}

.rpm-point-title {
  font-weight: 600;
  font-size: var(--fs-xl);
  color: var(--sidebar-tab-active);
}

.rpm-point-text {
  display: flex;
  gap: 5px;
  margin: 0 auto;
  font-size: var(--fs-xs);
  font-weight: var(--fw-light);
  text-align: center;
  margin-bottom: 10px;
  align-items: center;
  color: var(--paragraph);
  justify-content: center;
  text-decoration: underline;
}

.rpm-point-text * {
  font-weight: var(--fw-medium);
}

.rpm-point-text svg {
  height: 15px;
  width: auto;
}

.rpm-cipher-coin {
  width: 20px;
  padding: 0 2px;
}

.rpm-info-text {
  font-size: var(--fs-xxs);
  font-weight: var(--fw-medium);
  color: var(--paragraph);
}

.rpm-redeam-btn {
  display: flex;
  width: 100%;
  font-weight: var(--fw-medium);
  font-size: var(--fs-sm);
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  justify-content: center;
  background: var(--brand-color);
  color: var(--common-text-color);
}

/* Notification */

.ntf-cont {
  display: block;
  top: 40px;
  right: 0;
  overflow: hidden;
  position: absolute;
  box-shadow: var(--shadow);
  background: var(--background);
  border-radius: var(--border-radius);
}

.ntf-header {
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);
  font-size: var(--fs-2xl);
  font-weight: var(--fw-medium);
  color: var(--text-color);
}

.ntf-header > div {
  display: flex;
  justify-content: space-between;
}

.ntf-header > div > svg {
  padding: 5px;
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 1px solid grey;
}

.ntf-nav {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
}

.ntf-nav > button {
  font-size: var(--fs-sm);
  padding-bottom: 3px;
  color: var(--text-color);
}

.nav-active {
  font-weight: var(--fw-medium);
  border-bottom: 2px solid var(--text-color) !important;
}

.ntf-box {
  display: flex;
  gap: 5px;
  width: 450px;
  overflow: auto;
  padding: 0 1.5em;
  height: 380px !important;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--transparent);
}

.ntf-head-btn {
  display: flex;
  width: 100%;
  cursor: pointer;
  font-size: var(--fs-sm);
  font-weight: var(--fw-medium);
  justify-content: flex-end;
  color: var(--sidebar-tab-active);
}

.ntf-empty-box {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > img {
    width: 35px;
    height: auto;
  }

  > span {
    display: flex;
    font-weight: bold;
    line-height: 25px;
    font-size: var(--fs-lg);
    color: #7791a1;
  }
}

.ntf-show-more {
  display: flex;
  width: 100%;
  height: 25px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: var(--sidebar-tab-active);
}

.ntf-footer {
  display: flex;
  padding: 1em;
  justify-content: flex-end;
  border-top: 1px solid #cacaca;
}

.footer-btn-1 {
  font-weight: var(--fw-medium);
  color: var(--sidebar-tab-active);
}

.footer-btn-1 > span {
  padding: 0 5px;
}

.footer-btn-2 {
  padding: 10px;
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  color: var(--common-text-color);
  background-color: var(--sidebar-tab-active);
}

/*--------------------------------------------*/
/*----------Notification Tab------------------*/

.mn-cont {
  display: flex;
  width: 100%;
  padding: 5px;
  margin: 3px 0;
  height: auto;
  cursor: pointer;
  border-radius: var(--border-radius);
}

.mn-cont > img {
  display: inline-flex;
  width: auto;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
}

.mn-unseen {
  background-color: var(--text-box);
}

.mn-box {
  display: flex;
  width: 100%;
  padding: 5px;
  height: auto;
  flex-direction: column;
}

.mn-head {
  display: flex;
  margin-bottom: 3px;
  align-items: center;
  justify-content: space-between;
}

.mn-head > h3 {
  display: -webkit-box;
  width: 100%;
  margin: 0;
  height: auto;
  font-size: var(--fs-sm);
  font-weight: var(--fw-medium);
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  color: var(--text-color);
  -webkit-box-orient: vertical;
}

.mn-head > span {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--sidebar-tab-active);
}

.mn-head > img {
  width: auto;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
}

.ntt-content {
  display: flex;
  padding: 5px;
  width: 100%;
  max-height: 55px;
  margin-left: auto;
  align-items: center;
  border: 1px solid #c0c0c0;
  background-color: var(--common-text-color);
}

.ntt-content > img {
  width: 60px;
  height: 40px;
  margin-right: 8px;
  border-radius: var(--border-radius);
}

.ntt-content > p {
  display: -webkit-box;
  width: 100%;
  margin: 0;
  color: black;
  overflow: hidden;
  line-height: 20px;
  border-radius: var(--border-radius);
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.mn-footer {
  display: flex;
  width: 100%;
  margin: 5px 0 0;
  font-size: var(--fs-sm);
  color: var(--text-color);
  justify-content: space-between;
}

.ddm-menu-container,
.ddm-smenu-container {
  padding: 0;
  margin-top: 18px;
  box-shadow: var(--shadow);
  background-color: var(--background);
}

.ddm-menu-container .MuiPaper-root.ddm-cont,
.ddm-smenu-container .MuiPaper-root.ddm-cont {
  background-color: transparent !important;
  box-shadow: none;
}

.ddm-menu-container #menu-list-grow,
.ddm-smenu-container #menu-list-grow {
  background-color: transparent !important;
  margin: 0 !important;
}

.ddm-menu-container #menu-list-grow .MuiListItem-button:hover,
.ddm-menu-container #menu-list-grow .Mui-focusVisible,
.ddm-smenu-container #menu-list-grow .MuiListItem-button:hover,
.ddm-smenu-container #menu-list-grow .Mui-focusVisible {
  background-color: var(--second-white);
}

@media screen and (max-width: 960px) {
  .ddm-menu-container {
    left: auto !important;
    right: 0 !important;
  }
}

/* big laptop screens */
@media only screen and (min-width: 1025px) {
}

@media only screen and (min-width: 1281px) {
}
