.container {
  display: flex;
  gap: var(--gap-lg);
  flex-direction: column;
  color: var(--text-color);

  article {
    display: flex;
    gap: var(--gap-md);
    flex-direction: column;
    h2 {
      font-weight: var(--fw-medium);
      text-transform: none;
      font-size: var(--fs-md);
    }

    p {
      font-size: var(--fs-sm);

      a {
        font-weight: var(--fw-medium);
        color: var(--blue);
      }

      span {
        font-weight: var(--fw-medium);
        color: var(--brand-color);
      }
    }
  }
}
