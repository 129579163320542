.otp-container {
  display: flex;
  gap: var(--gap-md);

  > input {
    border: 1px solid var(--text-color);
    width: 50px;
    height: 50px;
    outline: none;
    text-align: center;
    font-size: var(--fs-lg);
    border-radius: var(--border-radius);
  }
}
