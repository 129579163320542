.dot-menu {
  display: inline-block;

  .menu-icon {
    cursor: pointer;
    font-size: var(--fs-lg);
    font-weight: var(--fw-medium);
  }

  .menu-options {
    position: absolute;
    top: 24px;
    right: 0;
    z-index: 10;
    padding: 4px 0;
    min-width: 80px;
    border: var(--border);
    box-shadow: var(--shadow1);
    border-radius: var(--border-radius);
    background-color: var(--second-white);

    .menu-option {
      display: flex;
      cursor: pointer;
      padding: 8px 12px;
      align-items: center;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: var(--background-secondary);
      }

      .option-icon {
        margin-right: 8px;
      }

      .option-title {
        flex-grow: 1;
      }
    }
  }
}
