.resources {
  display: flex;
  flex-direction: column;
  gap: 20px;

  article {
    display: flex;
    gap: 8px;
    flex-direction: column;
    a {
      cursor: pointer;
      width: fit-content;
      padding: 8px 12px;
      align-items: center;
      box-shadow: var(--shadow);
      border-radius: var(--border-radius);
      background-color: var(--second-white);

      button {
        display: flex;
        gap: 5px;
        align-items: center;
        color: var(--text-color);

        svg,
        img {
          width: 20px;
          height: 20px;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
