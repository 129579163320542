.cm-container {
  display: flex;
  width: 100%;
  height: auto;
  padding: 12px;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  background-color: var(--background-secondary);
}

.cm-container * {
  box-sizing: border-box;
  color: var(--text-color);
}

.cm-container-highlight {
  border: 1px solid var(--brand-color);
  /* animation-name: highlight;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-timing-function: ease-out; */
}

/* @keyframes highlight {
  from {
    border: solid var(--brand-color);
  }
  to {
    border: none;
  }
} */

.emphasise1 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cm-text-area {
  width: 90%;
  margin-left: 10px;
}

.cm-writer-box {
  display: flex;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
}

.cm-writer-name {
  padding-right: 10px;
  font-weight: bold;
  font-size: var(--border-radius);
  color: var(--paragraph);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 1.25;
}

.cm-writer-name h3 {
  margin: 0;
  font-size: var(--fs-md);
  color: var(--heading);
  line-height: inherit;
  max-width: 145px;
  font-weight: var(--fw-medium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cm-writer-name span {
  line-height: inherit;
  color: inherit;
  font-weight: 400;
  font-size: var(--fs-xs);
}

.cm-box {
  box-sizing: border-box;
  width: 100%;
  margin: 2px 0 0;
  overflow-wrap: anywhere;
  color: var(--paragraph);
  white-space: pre-wrap !important;
  line-height: 1.5;
}

.cm-box {
  .content {
    transition: all 1s ease-in-out;
    display: block;
    flex: 1 0 auto;
    line-height: 20px;
    overflow: hidden;

    p {
      width: 100%;
      line-height: 22px;
      white-space: pre-wrap;

      a {
        word-break: break-all;
        color: var(--blue);
      }
    }
  }

  .body {
    display: block;
    overflow: hidden;
    flex: 1 0 auto;
    transition: all 1s ease-in-out;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      width: 100%;
      line-height: 22px;
      white-space: pre-wrap;
      @media (max-width: 600px) {
        -webkit-line-clamp: 2;
      }

      a {
        word-break: break-all;
        color: var(--blue);
      }
    }
  }

  .link {
    color: gray;
  }
}

.cm-bottom {
  padding-top: 10px;
}

.cm-tool-box {
  display: flex;
  align-items: center;
  gap: var(--gap-md);
  user-select: none;
}

.cm-like-btn-box {
  display: flex;
  height: 22px;
  gap: 5px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  padding: 0 10px;
  cursor: pointer;
}

.cm-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50%;
}

.cm-icon-container svg {
  height: 1rem;
  width: auto;
}

.cm-like-num {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: var(--fs-sm);
  text-align: justify;
}

.cm-icon-container.liked .cm-like-num {
  font-weight: var(--fw-medium);
}

.cm-reply-btn-box {
  display: flex;
  height: 22px;
  gap: 5px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  padding: 0 10px;
  cursor: pointer;
}

.cm-reply {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: var(--fs-xs);
  line-height: 1;
  text-align: justify;
  opacity: 0.8;
  color: var(--text-color);
  font-weight: var(--fw-medium);
}

.rc-show-more {
  display: flex;
  width: 120px;
  cursor: pointer;
  margin: -3px 0;
  margin-bottom: 5px;
}

.rc-show-more > span {
  font-size: var(--fs-xs);
  font-weight: bold;
  color: var(--text-color2);
}

.com-reply-box {
  width: 100%;
  padding-top: 15px;
}

.com-reply-box > * {
  width: 100%;
}

.cm-reply-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-lg);
  width: 100%;
  flex-direction: column-reverse;
}

.com-reply-box .cm-container {
  width: 100%;
  margin: 0;
  padding: 0;
  border-color: transparent;
  background-color: transparent;
}

.com-reply-box .cm-avatar {
  height: 30px !important;
  width: 30px !important;
}

.com-reply-box .cm-writer-name h3 {
  font-size: var(--fs-sm);
}

.com-reply-box .cm-writer-name span {
  font-size: var(--fs-xs);
}
