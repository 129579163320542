.rootCarousel {
  > div {
    overflow: unset !important;
  }
}

.carousel {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  .carausal-laptop {
    display: none;
    border-radius: var(--border-radius);
    > img {
      height: auto;
      aspect-ratio: 32/11;
    }
  }
  .carausal-mobile {
    display: block;
    border-radius: var(--border-radius);
    > img {
      height: auto;
      aspect-ratio: 16/9;
    }
  }
  @media screen and (min-width: 641px) {
    .carausal-laptop {
      display: block;
    }
    .carausal-mobile {
      display: none;
    }
  }

  .content {
    display: flex;
    height: 100%;
    padding: 0px 10px;
    padding-top: 10px;
    text-align: left;
    gap: var(--gap-md);
    flex-direction: column;
    justify-content: center;
    color: var(--text-color);

    @media screen and (min-width: 641px) {
      width: 45%;
      border: 0px;
      padding: 35px;
      position: absolute;
      padding-left: 50px;
      color: var(--white);
      align-items: flex-start;
    }

    @media screen and (min-width: 1400px) {
      padding-left: 70px;
      gap: var(--gap-lg);
    }

    .heading {
      color: inherit;
      font-size: var(--fs-lg);
      line-clamp: 2 !important;
      -webkit-line-clamp: 2 !important;
      text-transform: none !important;
      @media screen and (min-width: 961px) {
        font-size: var(--fs-xl);
      }
      @media screen and (min-width: 1024px) {
        font-size: var(--fs-2xl);
      }
      @media screen and (min-width: 1281px) {
        font-size: var(--fs-3xl);
      }
      @media screen and (min-width: 1400px) {
        font-size: var(--fs-4xl);
      }
      @media screen and (min-width: 1600px) {
        font-size: var(--fs-5xl);
      }
    }
    .para-heading {
      color: inherit;
      font-size: var(--fs-sm);
      @media screen and (min-width: 961px) {
        font-size: var(--fs-md);
      }
      @media screen and (min-width: 1600px) {
        font-size: var(--fs-lg);
      }
    }

    .button {
      padding: 10px;
      min-width: 100px;
      margin-top: auto;
      text-align: center;
      color: var(--heading);
      border: var(--border);
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      border-radius: var(--border-radius);
      background-color: var(--background-secondary);

      @media screen and (min-width: 641px) {
        margin: 0px;
        min-width: 120px;
        color: var(--black);
        font-size: var(--fs-md);
        background-color: var(--white);
      }

      @media screen and (min-width: 1400px) {
        font-size: var(--fs-lg);
      }
    }
  }
}

.nav {
  height: 30px;
  width: 30px;
  z-index: 2;
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 3rem;
  align-items: center;
  top: calc(30% - 15px);
  justify-content: center;
  box-shadow: var(--shadow);
  background-color: var(--text-color);

  svg {
    width: auto;
    height: 25px;
    color: var(--background);
  }

  &.left {
    left: 0;
    transform: translateX(-10%);
    @media screen and (min-width: 641px) {
      transform: translateX(-50%);
    }
  }

  &.right {
    right: 0;
    transform: translateX(10%);
    @media screen and (min-width: 641px) {
      transform: translateX(50%);
    }
  }

  @media screen and (min-width: 641px) {
    height: 40px;
    width: 40px;
    top: calc(50% - 20px);
  }
}

// .arrow-right {
//   right: -10px;
//   z-index: 1;
//   cursor: pointer;
//   position: absolute;
//   border-radius: 50%;
//   top: calc(30% - 10px);
//   color: var(--background);
//   background-color: var(--text-color);
//   @media screen and (min-width: 641px) {
//     right: -20px;
//     top: calc(50% - 10px);
//   }
// }
// .arrow-left {
//   left: -10px;
//   z-index: 1;
//   cursor: pointer;
//   position: absolute;
//   border-radius: 50%;
//   top: calc(30% - 10px);
//   color: var(--background);
//   background-color: var(--text-color);
//   @media screen and (min-width: 641px) {
//     left: -20px;
//     top: calc(50% - 10px);
//   }
// }
.svg {
  width: 30px;
  height: auto;
  @media screen and (min-width: 641px) {
    width: 30px;
    height: auto;
  }
  @media screen and (min-width: 1400px) {
    width: 45px;
    height: auto;
  }
}

.cr-skeleton {
  display: flex;
  height: auto;
  flex-direction: column;
  @media screen and (min-width: 641px) {
    justify-content: center;
  }

  .carausal-image {
    display: flex;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;

    @media screen and (min-width: 641px) {
      aspect-ratio: 32/10;
    }
  }

  .mbcontent {
    display: block;
    @media screen and (min-width: 641px) {
      display: none;
    }
  }

  .dskcontent {
    display: none;
    position: absolute;
    @media screen and (min-width: 641px) {
      width: 50%;
      height: 40%;
      display: grid;
      padding: 0 10px;
    }
    @media screen and (min-width: 1281px) {
      width: 80%;
      height: 40%;
      display: grid;
      padding: 0 30px;
    }
  }
}
