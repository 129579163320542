.container {
  display: grid;
  width: 100%;
  margin: 3% 0;
  text-align: center;
  grid-column: span 4;
  justify-self: center;
  white-space: pre-wrap;
  justify-content: center;

  h3 {
    opacity: 0.2;
    display: block;
    font-weight: bold;
    font-size: var(--fs-4xl);
    color: var(--text-color);
    text-transform: none !important;

    @media screen and (min-width: 641px) {
      font-size: var(--fs-5xl);
    }
  }
}
