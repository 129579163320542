.container {
  display: grid;
  width: 100%;
  gap: var(--gap-lg);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 60px;
}

.pagination-container {
  display: flex;
  align-items: center;
  gap: var(--gap-md);
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;
  padding: 10px 0;
  padding-bottom: 20px;
}

.pagination-item {
  height: 2rem;
  width: 2rem;
  border-radius: var(--border-radius);
  background-color: var(--background);
  box-shadow: var(--card-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: var(--fs-sm);
  font-weight: 400;
  transition: 0.15s ease;
  cursor: pointer;
  border: 1px solid var(--border-color);
  color: var(--heading);
}

.pagination-item:not(.disabled):hover {
  border-color: var(--brand-color);
}

.pagination-item.true {
  background-color: var(--brand-color);
  color: white;
  font-weight: var(--fw-medium);
}

.pagination-item.side svg {
  height: 1rem;
  width: auto;
  color: var(--heading);
}

.pagination-item.disabled {
  opacity: 0.5;
  cursor: default;
}

.pagination-dot {
  color: var(--heading);
  opacity: 0.4;
}
