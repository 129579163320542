.card-cont {
  display: flex;
  height: 240px;
  margin: 10px 0;
  align-items: center;
  background: #f1f1f1;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 10px 3px rgba(108, 93, 211, 0.2);
}

.card-img-cont {
  display: flex;
  width: auto;
  margin: 20px;
  overflow: hidden;
  min-height: 160px;
  max-height: 160px;
  background-color: #ffffff;
  border-radius: var(--border-radius);

  > img {
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
}

.card-person-name {
  width: 90%;
  display: flex;
  max-height: 40px;
  margin: -15px 0 0 0;
  justify-content: center;
}

.card-person-name > span {
  text-transform: capitalize;
  font-weight: var(--fw-medium);
  font-size: var(--fs-md);
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.card-person-role {
  display: flex;
  width: 90%;
  max-height: auto;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.card-person-role > span {
  font-weight: 600;
  font-size: var(--fs-sm);
  margin-top: 5px;
  text-align: center;
  color: #808191;
}

@media (max-width: 425px) {
  .card-cont {
    width: calc(94% / 2);
    height: 150px;
    box-shadow: 0px 0px 5px 3px rgba(108, 93, 211, 0.2);
  }

  .card-img-cont {
    width: 85%;
    min-height: 90px;
    max-height: 90px;
    margin: 8px 15px;
  }

  .card-person-name {
    margin: -3px 0;
  }

  .card-person-role {
    margin: 3px 0;
    overflow: hidden;
  }

  .card-person-role > span {
    font-size: var(--fs-xs) !important;
    line-height: 1.5 !important;
    font-weight: var(--fw-light);
    color: #51525c;
  }
}

@media (min-width: 425px) and (max-width: 780px) {
  .card-cont {
    width: 45%;
  }
}

@media (min-width: 1600px) {
  .card-img-cont {
    margin: 20px 30px;
  }
}
