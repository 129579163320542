.redeemModal {
  display: flex;
  padding: 10px 22px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: var(--text-color);

  p {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    span {
      color: var(--brand-color);
    }
  }

  footer {
    display: flex;
    width: 100%;
    margin-top: 10px;
    gap: var(--gap-md);
    flex-direction: row-reverse;

    .btn {
      border-radius: var(--border-radius);
      padding: 10px 15px;
      font-size: var(--fs-sm);
      background-color: var(--black);
      &:first-child {
        background-color: var(--brand-color);
      }
    }
  }

  button {
    background-color: var(--sub-heading);
  }
}
