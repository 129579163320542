.vidcardsk-container {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  background-color: var(--background);
  box-shadow: var(--card-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.vidcardsk-image {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  background-color: var(--brand-color-shade);
}

.vidcardsk-tag {
  height: 12px;
  width: 50%;
  margin-top: 5px;
}
