.react-calendar-heatmap text {
  font-size: 10px;
  fill: var(--text-color);
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
   * Default color scale
   */

.react-calendar-heatmap .color-empty {
  fill: var(--background);
}

.react-calendar-heatmap .color-filled {
  fill: var(--brand-color);
}

/*
   * CipherSchools color scale
   */

.react-calendar-heatmap .color-cs-0,
.mp-heat-map-foot svg:nth-child(2) rect {
  fill: var(--background);
}
.react-calendar-heatmap .color-cs-dark,
.mp-heat-map-foot svg:nth-child(2) rect {
  fill: rgb(150, 150, 150);
  opacity: 0.1;
}
.react-calendar-heatmap .color-cs-1,
.mp-heat-map-foot svg:nth-child(3) rect {
  fill: #fce4cb;
}
.react-calendar-heatmap .color-cs-2,
.mp-heat-map-foot svg:nth-child(4) rect {
  fill: #f9c897;
}
.react-calendar-heatmap .color-cs-3,
.mp-heat-map-foot svg:nth-child(5) rect {
  fill: #f6ad62;
}
.react-calendar-heatmap .color-cs-4,
.mp-heat-map-foot svg:nth-child(6) rect {
  fill: #f3912e;
}

@media screen and (min-width: 961px) {
  .react-calendar-heatmap text {
    font-size: 7px;
  }
}
