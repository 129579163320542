/* common styles */
.profileModal-input-field-container {
  display: flex;
  width: 100%;
  gap: var(--gap-sm);
  flex-direction: column;

  // for input field
  .input-field {
    display: flex;
    padding: 10px;
    border: var(--border);
    border-radius: var(--border-radius);
    background-color: var(--second-white);

    .date-input {
      width: 100%;
      border: none;
      margin-left: 7px;
      width: 100%;
      color: var(--text-color);
      background: var(--second-white);
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      color: red;
    }
  }
  //Input label style
  .input-label {
    line-height: 1.25;
    font-size: var(--fs-md);
    color: var(--text-color);
    font-weight: var(--fw-medium);
  }
}
