.fc-follow-btn {
  display: flex;
  width: auto;
  opacity: 0.8;
  cursor: pointer;
  line-height: 1.25;
  padding: 8px 1.3rem;
  transition: 0.15s ease;
  justify-content: center;
  font-size: var(--fs-md);
  color: var(--text-color);
  box-shadow: var(--shadow);
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  background-color: var(--background);
  &:hover {
    opacity: 1;
  }

  &.followed {
    color: var(--background) !important;
    background: var(--text-color) !important;
  }

  @media screen and (max-width: 500px) {
    padding: 8px 1.2rem;
    font-size: var(--fs-sm);
  }
}

.loadingBtn {
  display: flex;
  width: 100%;
  color: #fff;
  border: none;
  opacity: 0.9;
  height: inherit;
  cursor: not-allowed;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-md);
  font-weight: var(--fw-light);
  background-color: var(--primary);
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }
}
