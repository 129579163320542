.rpm-box {
  padding: 1em;
  width: 250px;
  display: flex;
  overflow: hidden;
  gap: var(--gap-md);
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.rpm-watchpoint {
  width: 20px;
  height: 20px;
  margin: 0 3px;
  border-radius: 100%;
  background-color: var(--common-text-color);
}

.rpm-top {
  display: flex;
  height: 30px;
  gap: 3px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;

  img {
    width: 25px;
    height: 25px;
  }
}

.rpm-title {
  font-weight: 600;
  font-size: var(--fs-xl);
  color: var(--sidebar-tab-active);
}

.rpm-text {
  display: flex;
  gap: 5px;
  margin: 0 auto;
  font-size: var(--fs-xs);
  font-weight: var(--fw-light);
  text-align: center;
  margin-bottom: 10px;
  align-items: center;
  color: var(--paragraph);
  justify-content: center;
  text-decoration: underline;
}

.rpm-point-text {
  font-weight: var(--fw-medium);
}

.rpm-info-text {
  font-size: var(--fs-xxs);
  font-weight: var(--fw-medium);
  color: var(--paragraph);
}

.rpm-redeam-btn {
  display: flex;
  width: 100%;
  font-weight: var(--fw-medium);
  font-size: var(--fs-sm);
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  justify-content: center;
  background: var(--brand-color);
  color: var(--common-text-color);
}
