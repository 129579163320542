.container {
  width: 100%;
  display: flex;
  margin-top: 60px;
  gap: var(--gap-md);
  padding: 10px 20px;
  flex-direction: column;
  height: calc(100vh - 60px);
  border-right: var(--border);
  // box-shadow: 5px 0px 12px -4px var(--light-shadow2);
  // -webkit-box-shadow: 5px 0px 12px -4px var(--light-shadow2);
  // -moz-box-shadow: 5px 0px 12px -4px var(--light-shadow2);
}

.btn {
  text-align: left;
  padding: 10px 15px;
  align-items: center;
  color: var(--text-color);
  font-weight: var(--fw-normal);
  &.active {
    color: var(--white);
    border-radius: var(--border-radius);
    background-color: var(--brand-color);
  }

  .btn-content {
    display: flex;
    gap: var(--gap-sm);
    align-items: center;

    .icon-checked {
      width: 20px;
      height: 20px;
      color: var(--green);
    }

    .icon-unchecked {
      width: 20px;
      height: 20px;
      color: gray;
    }
  }
}

.publishBtn {
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    color: var(--white);
  }
}

.publishEnabled {
  background-color: var(--blue);
  cursor: pointer;
}

.publishDisabled {
  background-color: lightgray;
  cursor: not-allowed;
}
