.content {
  width: 100%;
  border: 1px solid gray;
  display: flex;
  gap: var(--gap-lg);
  flex-direction: column;
  z-index: 1000;

  .add-content {
    display: flex;
    gap: var(--gap-md);

    .btn {
      padding: 10px 15px;
      color: var(--brand-color);
      background-color: transparent;
    }
  }

  .new-lec {
    padding: 8px 5px;
    width: 100px;
  }
}

.container {
  // z-index: 1000;
  display: flex;
  padding: 15px;
  gap: var(--gap-md);
  flex-direction: column;
  background-color: var(--background);
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 6px 1px var(--light-shadow2);
  -moz-box-shadow: 0px 0px 6px 1px var(--light-shadow2);
  -webkit-box-shadow: 0px 0px 6px 1px var(--light-shadow2);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftContent {
      width: max-content;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        color: var(--text-color);
        width: 25px;
        // border: 1px solid red;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: grab;

        .icon {
          font-size: 18px;
        }
      }
    }

    p {
      font-size: var(--fs-md);
      color: var(--text-color);
      font-weight: var(--fw-medium);

      @media screen and (min-width: 1500px) {
        font-size: var(--fs-lg);
      }
      @media screen and (min-width: 2000px) {
        font-size: var(--fs-xl);
      }
    }

    .icon {
      color: var(--text-color);
    }

    .arrow-up {
      transform: rotate(180deg);
      transition: 0.3s ease;
    }
  }

  .sub-container {
    display: flex;
    gap: var(--gap-lg);
    flex-direction: column;

    .input {
      border: 1px solid var(--black);
    }
    .error {
      border: 1px solid var(--red);
    }

    .assignment {
      display: flex;
      gap: var(--gap-md);
      flex-direction: column;
    }

    .action {
      display: flex;
      align-self: end;
      gap: var(--gap-md);

      .cancel {
        padding: 10px 15px;
        color: var(--text-color);
        background-color: var(--background);
      }

      .add {
        padding: 10px 15px;
        color: var(--white);
        background-color: var(--black);
      }
    }
  }

  .collapsed {
    display: none !important;
  }
}

.requiredField {
  color: var(--red-shade);
}

.description {
  font-size: var(--fs-md) !important;

  p,
  li,
  ul {
    color: var(--text-color);
    font-size: var(--fs-md);
    // font-weight: var(--fw-medium);

    @media screen and (min-width: 1500px) {
      font-size: var(--fs-lg);
    }
  }
}

.drop-down {
  z-index: 1;
}
