.container {
  display: flex;
  width: inherit;
  height: inherit;
  overflow: hidden;
  padding: 5px 10px;
  flex-direction: column;

  > div {
    overflow: auto;
  }

  header {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px;
    margin-left: 5px;
    gap: var(--gap-md);
    flex-direction: column;
    color: var(--text-color);
    justify-content: space-between;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);

    p {
      font-size: var(--fs-md);
    }

    h3 {
      font-size: var(--fs-lg);
      white-space: break-spaces;
      font-weight: var(--fw-medium);
    }

    @media screen and (max-width: 941px) {
      display: none;
    }
  }

  .lists {
    display: flex;
    gap: var(--gap-md);
    flex: 1;
    margin-top: 10px;
    padding: 0 5px;
    overflow: hidden;
    padding-bottom: 80px;
    flex-direction: column;
    justify-content: flex-start;

    .headerVideoSideBar {
      grid-template-columns: auto 60px;
      background-color: var(--background-secondary);
    }

    .infinite-scroll-lists {
      display: flex;
      gap: var(--gap-md);
      flex: 1;
      margin-top: 10px;
      padding: 0 5px;
      overflow: hidden !important;
      padding-bottom: 0px;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
