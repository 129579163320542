.container {
  padding: 1rem;
  color: var(--black);
  background-color: var(--background);

  @media screen and (min-width: 1025px) {
    padding-bottom: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media screen and (min-width: 1225px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  & > main {
    display: grid;
    gap: var(--gap-card);
    gap: var(--gap-xl);
    grid-template-columns: repeat(5, 1fr);
    width: 100%;

    @media screen and (max-width: 1050px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: var(--gap-xl);
    }

    @media screen and (max-width: 600px) {
      row-gap: var(--gap-lg);
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h1 {
    color: var(--black);
    font-size: var(--fs-xl);
    font-weight: var(--fw-medium);
    display: flex;
    align-items: center;
  }

  > img {
    width: 40px;
    height: 40px;

    @media screen and (min-width: 961px) {
      width: 50px;
      height: 50px;
    }
  }
}

.part {
  overflow: hidden;

  > div {
    display: flex;
    align-items: center;

    h2 {
      font-size: var(--fs-lg);
      font-weight: var(--fw-light);

      @media screen and (min-width: 961px) {
        font-size: var(--fs-xl);
      }
      @media screen and (min-width: 1081px) {
        font-size: var(--fs-2xl);
      }
    }
  }
}

.part1 {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.container .title {
  font-size: var(--fs-lg);
  font-weight: var(--fw-medium);
  margin-bottom: var(--gap-md);

  &[data-type="info"] {
    text-align: right;

    @media screen and (max-width: 1050px) {
      text-align: left;
    }
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 18px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 400px) {
    margin-bottom: 10px;
  }
}

.links {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-md);

  &[data-type="info"] {
    text-align: left;
    align-items: flex-start;

    .link {
      display: flex;
      gap: 10px;
      font-size: 14px;
      align-items: center;
      gap: var(--gap-md);

      svg {
        color: inherit;
        height: 20px;
        width: auto;

        &[class~="call-icon"] {
          transform: scale(1.05);
        }

        &[class~="map-icon"] {
          transform: scale(1.05);
          flex-shrink: 0;
        }
      }

      &:hover {
        color: var(--fc-para);
        opacity: 1;
      }

      @media screen and (min-width: 941px) {
        font-size: 15px;
      }
    }

    @media screen and (max-width: 1050px) {
      text-align: left;
      align-items: flex-start;
    }
  }
}

.link {
  padding: 3px 5px;
  padding-left: 0;
  font-size: var(--fs-sm);
  font-weight: var(--fw-medium);
  cursor: pointer;
  transition: 0.15s ease;
  opacity: 0.95;

  &:hover {
    opacity: 1;

    &:not([data-name="call"]):not([data-name="email"])::before {
      margin-right: 10px;
    }
  }

  &:not([data-name="call"]):not([data-name="email"])::before {
    content: "\2192";
    position: relative;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    line-height: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin-right: 5px;
    cursor: pointer;
    transition: margin-right 0.15s ease;
  }

  @media screen and (min-width: 941px) {
    font-size: 15px;
  }
}

.branding {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  grid-column: span 2;

  .links {
    gap: 5px;
  }

  :global {
    .lazy-load-image-loaded {
      width: auto !important;
      height: 48px !important;
    }
  }

  p {
    font-size: var(--fs-sm);
    line-height: 1.5;
    opacity: 0.95;
    max-width: 500px;
    font-weight: var(--fw-medium);
  }

  @media screen and (max-width: 500px) {
    grid-column: span 2;
  }

  @media screen and (max-width: 450px) {
    :global {
      .lazy-load-image-loaded {
        height: 43px !important;
      }
    }
  }

  @media screen and (max-width: 400px) {
    :global {
      .lazy-load-image-loaded {
        height: 38px !important;
      }
    }
  }
}

.appsContainer {
  display: flex;
  width: 100%;
  gap: var(--gap-lg);
  margin-top: 2rem;
  flex-direction: column;

  @media screen and (min-width: 641px) {
    flex-direction: row;
    gap: 1rem;
  }

  & > div {
    display: flex;
    gap: 1rem;
  }

  .downloadButton {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    color: var(--white) !important;
    border-radius: var(--border-radius);

    .downloadIcon {
      font-size: 1.5em;
    }
    p {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: var(--white) !important;
      span {
        font-size: 0.6rem;
      }
    }
  }
}
