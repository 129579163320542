.unwrap {
  display: none;
  gap: var(--gap-md);
  align-items: center;
  padding: var(--gap-xl);
  border: 1px solid orange;
  grid-template-columns: auto 260px;
  border-radius: var(--border-radius);

  @media screen and (min-width: 1281px) {
    display: grid;
    grid-template-columns: auto 300px;
  }

  @media screen and (min-width: 1500px) {
    grid-template-columns: auto 360px;
  }

  .content {
    width: 100%;
    display: flex;
    gap: var(--gap-md);
    flex-direction: column;
    color: var(--text-color);
    text-transform: none !important;

    h2 {
      display: block;
      text-transform: none;
      font-size: var(--fs-lg);
      font-weight: var(--fw-medium);
    }

    .lec-btn {
      display: flex;
      gap: var(--gap-md);
      .button {
        width: auto;
        padding: 8px 15px;
        font-size: var(--fs-sm);
        color: var(--text-color);
        border: 1px solid var(--black);
        background-color: var(--border-color);
        &:hover {
          color: var(--white);
          background-color: var(--brand-color);
        }
        &.active {
          color: var(--white);
          background-color: var(--brand-color);
        }

        @media screen and (min-width: 1281px) {
          padding: 8px 20px;
          font-size: var(--fs-md);
        }
      }
    }

    h3 {
      display: block;
      text-transform: none;
      font-size: var(--fs-md);
      font-weight: var(--fw-light);

      @media screen and (min-width: 1281px) {
        font-size: var(--fs-lg);
      }
    }

    p {
      line-clamp: 2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      font-size: var(--fs-sm);
      -webkit-box-orient: vertical;
      @media screen and (min-width: 1281px) {
        font-size: var(--fs-md);
      }
    }

    a {
      color: var(--blue);
      cursor: pointer;
      font-size: var(--fs-sm);

      @media screen and (min-width: 1281px) {
        font-size: var(--fs-md);
      }
    }
  }

  .image {
    display: flex;
    width: 100%;
    overflow: hidden;
    border-radius: var(--border-radius);

    > img {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

    svg {
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      color: var(--brand-color);
      background-color: var(--white);
      transform: translate(-50%, -50%);
    }
  }
}
