.background {
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10000;
  height: 100vh !important;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 65px);
  background-color: rgba(0, 0, 0, 0.3);

  @media screen and (min-width: 941px) {
    height: 100%;
  }

  .modalContainer {
    padding: 10px 0;
    overflow-y: scroll;
    animation-duration: 0.5s;
    box-shadow: var(--shadow);
    max-height: calc(100% - 60px);
    animation-name: modalAnimation;
    background-color: var(--background);
    border-radius: var(--border-radius);

    .header {
      display: grid;
      top: 0px;
      z-index: 300;
      height: 50px;
      padding: 0 12px;
      position: sticky;
      margin-bottom: 5px;
      align-content: center;
      background-color: inherit;
      position: -webkit-sticky; /* Safari */
      grid-template-columns: auto 40px;

      .cross-left {
        display: block;
        z-index: 100;
        color: inherit;
        cursor: pointer;
        aspect-ratio: 1/1;

        svg {
          width: auto;
          height: 35px;
          color: inherit;
        }

        &:hover {
          border-radius: 50%;
          background-color: rgba(240, 240, 240, 0.4);

          svg {
            color: var(--black);
          }
        }
      }

      .heading {
        display: flex;
        padding: 0 10px;
        color: inherit;
        align-items: center;
        text-transform: none;
        font-size: var(--fs-lg);
        font-weight: var(--fw-medium);
      }
    }

    .no-true {
      grid-template-columns: none;
    }

    > section {
      height: 100%;
      padding: 0 16px;
      padding-top: 10px;

      @media screen and (min-width: 941px) {
        padding: 0 24px;
        padding-top: 10px;
      }
    }

    .footer {
      display: flex;
      width: 100%;
      bottom: 0px;
      z-index: 1;
      min-height: 15px;
      position: sticky;
      background-color: transparent;
    }

    @media only screen and (max-width: 641px) {
      padding-top: 0;
      max-height: calc(100vh - 55px);
    }

    @keyframes modalAnimation {
      from {
        transform: translateY(-200px);
        opacity: 0.7;
      }

      to {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }
}

.top {
  padding-top: 0;
  @media screen and (min-width: 641px) {
    padding-top: max(8vh, 65px);
  }
}

.center {
  align-items: center;
}

.modal-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
