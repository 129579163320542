.skeletons {
  display: grid;
  place-content: center;
  gap: var(--gap-section);

  grid-template-columns: 1fr 1fr;
  @media screen and (min-width: 641px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (min-width: 1025px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
