.fileUploader {
  display: flex;
  position: relative;
  align-items: center;

  .fileContainer {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
    & > div {
      display: flex;
      flex-direction: column;

      h3 {
        color: black;
      }

      p {
        font-size: var(--fs-sm);
        color: #737373;
      }
    }
  }

  .fileUploaderIcon {
    padding: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--white);
    background-color: var(--brand-color);
  }

  input {
    display: block;
    width: 120px;
    height: 120px;
    top: 50%;
    bottom: 0;
    right: 0%;
    left: 50%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.imageContainer {
  height: 120px;
  display: flex;
  margin: auto;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  div {
    z-index: 2;
    left: 50%;
    padding: 5px;
    display: flex;
    bottom: -10px;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    background-color: var(--brand-color);

    .fileUploaderIcon {
      border-radius: 50%;
      color: var(--white);
      font-size: var(--fs-xl);
    }
  }
}

.borderNone {
  border: none;
}
