.list-item {
  display: flex;
  height: 50px;
  color: inherit;
  width: 100% !important;
  margin: 5px 0px !important;
  justify-content: center !important;
  border-radius: var(--border-radius) !important;

  &:hover {
    background-color: var(--sidebar-tab-hover);
  }

  .item-icon {
    z-index: 1;
    display: flex;
    line-height: 1;
    flex-direction: column;
    justify-content: center;
    color: inherit !important;
    align-items: center !important;

    .ms-ripple {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 33px;
      width: 33px;
      border-radius: 100%;
    }

    .ms-ripple span {
      position: relative;
    }

    .ms-ripple::after {
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      height: 100%;
      width: 100%;
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: 3s;
      animation-delay: 0s;
      border: 8px solid #f3912e9c;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
      z-index: -1;
    }

    .ms-ripple::before {
      opacity: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 0;
      content: "";
      height: 100%;
      width: 100%;
      z-index: -1;
      border-radius: 100%;
      animation-name: ripple;
      animation-duration: 3s;
      animation-delay: 0.3s;
      border: 8px solid rgba(240, 80, 70, 0.612);
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    }

    @keyframes ripple {
      from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
      }

      to {
        opacity: 0;
        transform: scale3d(1.5, 1.5, 1);
      }
    }

    div > span > svg {
      width: 20px;
    }
  }

  .item-text {
    width: 55px;
    color: inherit;
    margin-top: 0px;
    line-height: 1.25;
    text-align: center;
    white-space: normal;
    padding-bottom: 3px;
    font-size: var(--fs-xxs);

    > span {
      display: flex;
      width: 100%;
      color: inherit;
      justify-content: space-between;
    }
  }
}

.active {
  color: var(--white);
  background-color: var(--brand-color) !important;

  .item-icon {
    color: var(--white);
  }
}
