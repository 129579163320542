/* common styles */
.profileModal-input-field-container {
  display: flex;
  width: 100%;
  gap: var(--gap-sm);
  flex-direction: column;

  // for input field
  .input-field {
    display: flex;
    padding: 10px;
    border: var(--border);
    border-radius: var(--border-radius);
    background-color: var(--second-white);
  }
  //Input label style
  .input-label {
    line-height: 1.25;
    font-size: var(--fs-md);
    color: var(--text-color);
    font-weight: var(--fw-medium);
  }

  // input divider
  .input-divider {
    width: 100%;
    display: flex;
    gap: var(--gap-md);
  }
  .input-divider-section-left {
    width: 60%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .input-divider-section-right {
    width: 40%;
  }
  // for select input field
  .input-field-select {
    padding: 1px;
    margin-top: 4px;
    border: var(--border);
    border-radius: var(--border-radius);
  }

  .control-color {
    background-color: var(--second-white);
    border: 0px solid var(--second-white);
    color: var(--text-color);
    width: 100%;
  }
  .color-input {
    line-height: 20px;
    color: var(--text-color);
  }
  .color {
    background-color: var(--background);
    color: var(--text-color);
  }
  .color-selected {
    background-color: var(--text-box);
    color: var(--text-color);
  }

  /* ------------TextInput styles ------------ */
  .left-icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 100%;
    z-index: 2;
    color: var(--text-color);
  }

  .normal-text-input {
    width: 100%;
    border: none;
    margin-left: 7px;
    width: 100%;
    color: var(--text-color);
    background: transparent;
    text-transform: none !important;
  }
}
