.container {
  flex: 1;
  display: flex;
  padding-top: 20px;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 50px);
  border-radius: var(--border-radius);

  .cross-left {
    top: 20px;
    left: 20px;
    z-index: 1;
    position: absolute;

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
      color: var(--text-color);
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--gap-md);
  }

  header {
    width: 100%;
    text-align: center;

    img {
      width: 40px;
      height: 40px;
    }

    > h2 {
      margin-bottom: 0;
      color: var(--heading);
      font-size: var(--fs-lg);
      font-weight: var(--fw-medium);
    }

    > p {
      margin-top: 5px;
      color: var(--paragraph);
      font-size: var(--fs-md);
    }

    @media screen and (min-width: 641px) {
      h2 {
        font-size: var(--fs-xl);
      }
      p {
        font-size: var(--fs-lg);
      }
    }
  }

  main {
    gap: 5px;
    width: 80%;
    display: flex;
    max-height: auto;
    padding-bottom: 10px;
    flex-direction: column;
    margin-top: var(--gap-md);

    .input {
      padding: var(--gap-md) 0px;

      .checkbox {
        padding: var(--gap-xsm) 0px;
      }
    }

    p {
      width: auto;
      color: var(--red);
      text-align: center;
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
    }

    .eye {
      top: 50%;
      width: 18px;
      right: 10px;
      height: 18px;
      cursor: pointer;
      position: absolute;
      color: var(--text-secondary);
      transform: translateY(-50%);

      @media screen and (min-width: 641px) {
        width: 20px;
        height: 20px;
      }
    }

    .forget-password {
      padding: 2px 0;
      cursor: pointer;
      line-height: 22px;
      user-select: none;
      margin-left: auto;
      font-size: var(--fs-md);
      color: var(--brand-color);
      font-weight: var(--fw-medium);
    }

    .login-btn {
      width: 100%;
      height: 40px;
      display: flex;
      cursor: pointer;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      background: var(--brand-color);
      border-radius: var(--border-radius);

      button {
        width: 100%;
        border: none;
        height: 100%;
        color: var(--white);
        font-size: var(--fs-md);
      }
    }

    .signup-text {
      margin: 0 auto;
      margin-top: 5px;
      line-height: 22px;
      font-size: var(--fs-sm);
      letter-spacing: -0.33px;
      color: var(--text-color);

      span {
        margin: 0 auto;
        padding: 0 3px;
        cursor: pointer;
        font-size: var(--fs-sm);
        color: var(--brand-color);
        font-weight: var(--fw-medium);
      }

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);

        span {
          font-size: var(--fs-md);
        }
      }
    }

    .row {
      display: flex;
      gap: var(--gap-md);
      flex-direction: column;

      @media screen and (min-width: 641px) {
        flex-direction: row;
      }
    }

    .saperator {
      width: 70%;
      height: 1px;
      display: flex;
      margin: 10px auto;
      margin-bottom: var(--gap-md);
      border: 1px solid var(--select-cont);
      > span {
        padding: 0 8px;
        line-height: 20px;
        margin: -10px auto;
        font-size: var(--fs-md);
        color: var(--text-color);
        font-weight: var(--fw-bold);
        background-color: var(--background);
      }

      ~ div {
        display: flex;
        justify-content: center;
      }
    }

    .google-btn {
      display: flex;
      margin: 0 auto;
      padding: 10px 15px;
      gap: var(--gap-md);
      width: fit-content;
      border: var(--border);
      font-size: var(--fs-md);
      color: var(--text-color);
      background-color: var(--background);
      border-radius: var(--border-radius);

      svg {
        width: var(--fs-lg);
        height: var(--fs-lg);
      }

      &:hover {
        background-color: var(--background-secondary);
      }
    }
  }

  .otp-main {
    width: 100%;
    display: flex;
    gap: var(--gap-md);
    align-items: center;
    flex-direction: column;
    height: calc(100% - 50px);
    padding: var(--gap-lg) 0px;
    border-radius: var(--border-radius);

    .back-left {
      top: -15px;
      left: 0px;
      z-index: 1;
      display: flex;
      position: absolute;
      align-items: center;
      color: var(--text-color);

      > svg {
        width: 25px;
        height: 25px;
        cursor: pointer;
        color: var(--text-color);
      }
    }

    .error {
      color: var(--red);
    }

    .success {
      color: green;
    }

    > p {
      font-size: var(--fs-md);
      color: var(--text-color);

      @media screen and (min-width: 961px) {
        font-size: var(--fs-lg);
      }
    }

    .input-container {
      width: 100%;
      display: flex;
      gap: var(--gap-lg);
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin: var(--gap-md) 0px;

      span {
        color: var(--text-secondary);
      }
    }

    .resend-div {
      display: flex;
      gap: var(--gap-md);
      align-items: center;
      font-size: var(--fs-sm);
      justify-content: center;

      .resend-btn {
        color: var(--brand-color);
      }

      > p {
        color: gray;
      }

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }
    }
  }
}
