.container {
  padding: 15px 20px;
  border-radius: 8px;
  color: var(--text-color);
  background-color: var(--background);

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 18px;
      font-weight: var(--fw-medium);
      text-transform: none;
      @media screen and (min-width: 641px) {
        font-size: 20px;
      }
    }

    .icon {
      cursor: pointer;
    }
  }

  .textContainer {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .feedbackInput {
      padding: 5px;
      border: 2px solid #e0e0e0;
      input {
        padding: 5px;
        background-color: var(--background);
      }
    }

    .icon2 {
      font-size: 24px;
      color: var(--brand-color);
    }
  }

  .btnContainer {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
    justify-content: flex-end;
  }

  button {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--brand-color);
  }

  .controlBtn {
    color: var(--background);
    background-color: var(--text-color2);
  }
}

.show {
  visibility: visible;
}
