.im-modal {
  display: flex;
  margin: auto;
  overflow: auto;
  align-items: center;
  justify-content: center;
}

.im-modal > :first-child {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.im-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  max-height: 350px;
  overflow: hidden auto;
}

/* .im-container {
  display: flex;
  width: 100%;
  overflow: auto;
  max-height: 100%;
  justify-content: space-between;
  border-bottom: 1px solid var(--select-cont);
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.im-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.im-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.im-item-container {
  display: grid;
  max-width: 100% !important;
}

@media screen and (max-width: 600px) {
  .im-container {
    gap: 12px;
  }
}

@media screen and (max-width: 400px) {
  .im-container {
    gap: 10px;
  }
}
