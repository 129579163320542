.input {
  display: flex;
  gap: var(--gap-md);
  align-items: flex-start;
  label {
    color: var(--text-color);
    font-size: var(--fs-sm);
    opacity: 0.8;

    @media screen and (min-width: 1081px) {
      font-size: var(--fs-md);
    }
  }
}
