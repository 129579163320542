.banner {
  display: flex;
  // width: 100% !important;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  .close {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    color: var(--text-color2);
  }
  .image {
    display: flex;
    padding: 10px;
    justify-content: center;

    img {
      max-width: 50%;
      height: auto;
    }
  }

  .content {
    padding: 20px;
    p {
      text-transform: none !important;
      font-size: var(--fs-sm);
      color: var(--text-color2);

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }
      @media screen and (min-width: 961px) {
        font-size: var(--fs-lg);
      }
      @media screen and (min-width: 1600px) {
        font-size: var(--fs-xl);
      }
    }

    .featureBtn {
      padding: 0.8rem;
      border-radius: 0.35rem;
      font-size: var(--fs-md);
      margin-left: auto;
      margin-top: 30px;
      @media screen and (min-width: 1600px) {
        font-size: var(--fs-xl);
      }
      @media screen and (min-width: 2000px) {
        font-size: var(--fs-2xl);
      }
    }
  }

  .advertisementModal {
    background-color: var(--background);
    border-radius: 5px;
    padding: 2rem;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 360px;
    @media screen and (min-width: 641px) {
      width: 460px;
    }
  }
}
