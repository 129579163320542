.button {
  padding: 1rem;
  display: flex;
  color: var(--white);
  align-items: center;
  justify-content: center;
  font-size: var(--fs-md);
  font-weight: var(--fw-medium);
  background-color: var(--brand-color);
  border-radius: var(--border-radius);

  &:disabled {
    cursor: not-allowed !important;
  }
}
