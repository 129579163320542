.wa-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
  visibility: visible;
  transform: scale(1);
  animation: fadeWa 0.25s ease forwards;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.15s ease;
}

.white {
  color: white;
}

.black {
  color: black;
}

@keyframes fadeWa {
  to {
    opacity: 1;
  }
}

.wa-top-circle {
  display: flex;
  margin: 0 auto;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  justify-content: center;
  /* background-color: var(--background); */
}

.wa-top-icon {
  display: flex;
  width: 80px;
}

.wa-content {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 10px;
  width: 400px;
  z-index: 10000;
  border-radius: var(--border-radius);
  background: var(--background);
  transform: translate(-50%, -50%);
  box-shadow: var(--card-shadow-hover);
}

.wa-back {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
  border: none;
  outline: none;
  transform: rotate(159.57deg);
}

.wa-details-box {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.wa-details-head {
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: var(--fs-2xl);
  text-align: center;
  color: var(--text-color);
  /* text-transform: uppercase; */
  /* color: var(--sidebar-tab-active); */
}

.wa-details-subhead1 {
  margin: 1px 0;
  font-weight: var(--fw-medium);
  font-size: var(--fs-sm);
  line-height: 20px;
  text-align: center;
  color: var(--text-color);
}

.wa-details-btn {
  display: flex;
  width: 140px;
  height: 35px;
  border: none;
  margin: 20px 0;
  cursor: pointer;
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  align-items: center;
  justify-content: center;
  color: var(--background);
  text-transform: capitalize;
  /* color: var(--common-text-color); */
  background: var(--text-color);
}

.wa-details-btn:hover {
  opacity: 0.9;
}

.wa-details-btn:focus {
  outline: none;
}

.wa-top-circle > .CircularProgressbar {
  width: 100% !important;
}

.wa-top-circle > .CircularProgressbar > .CircularProgressbar-text {
  stroke: var(--sidebar-tab-active) !important;
  font-size: var(--fs-xl) !important;
}

.wa-top-circle > .CircularProgressbar > .CircularProgressbar-path {
  width: 100% !important;
  stroke: var(--sidebar-tab-active) !important;
}

.close-icon {
  top: 15px;
  right: 15px;
  cursor: pointer;
  position: absolute;
  color: var(--text-color);
}
