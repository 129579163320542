.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 0px !important;

  .body {
    width: 100%;

    p {
      font-size: var(--fs-md);
    }
  }

  .footer {
    width: 100%;
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    .btn {
      padding: 7px 15px !important;
      cursor: pointer !important;
    }

    .cancel {
      background-color: gray;
    }
  }
}
