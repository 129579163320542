.container {
  display: flex;
  gap: 5px;
  height: 100%;
  cursor: pointer;
  padding: 5px 10px;
  border: var(--border);
  flex-direction: column-reverse;
  border-radius: var(--border-radius);
  background-color: var(--background);

  .left {
    display: flex;
    flex-direction: column;
    color: var(--text-color);
    .top {
      .date {
        font-size: var(--fs-mini);
      }

      h2 {
        margin: 5px 0;
        font-weight: var(--fw-medium);
        font-size: var(--fs-text);
      }

      .category {
        display: none;
      }
    }

    .footer {
      display: none;
      gap: 10px;

      button {
        border-radius: 4px;
        padding: 5px 10px;
        color: var(--background);
        background-color: var(--text-color);
      }
    }
  }

  .right {
    width: auto;
    height: auto;
    overflow: hidden;

    img {
      // height: 120px;
      object-fit: contain;
    }

    .action {
      display: flex;
      top: 5px;
      gap: 5px;
      right: 5px;
      position: absolute;

      svg {
        padding: 5px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        color: var(--text-color);
        box-shadow: var(--shadow);
        background-color: var(--background);
      }
    }
  }

  @media screen and (min-width: 961px) {
    .left {
      margin: 10px 0;

      .top {
        .category {
          display: flex;
          padding: 0 5px;
          margin-bottom: 8px;
          width: fit-content;
          color: var(--text-color);
          font-size: var(--fs-sm);
          font-weight: var(--fw-medium);
          background-color: var(--brand-color-shade);
        }
      }

      .footer {
        display: flex;
        margin-top: auto;
      }
    }

    .right {
      border: 1px solid lightgray;
      height: auto;
      img {
        width: 100%;
        object-fit: cover;
        object-position: top;
      }

      .action {
        display: none;
      }
    }
  }
}
