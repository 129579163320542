.title {
  font-size: var(--fs-lg);
  color: var(--text-color);
  font-weight: var(--fw-medium);
  @media screen and (min-width: 1081px) {
    font-size: var(--fs-xl);
  }

  @media screen and (min-width: 1681px) {
    font-size: var(--fs-2xl);
  }
}

// for divider line
.divider {
  width: 93%;
  height: 1px;
  margin: 10px auto;
  border-radius: 0.5px;
  background: var(--border-color);
}

.suggestions {
  display: none;
  color: var(--text-color);

  @media screen and (min-width: 961px) {
    display: grid;
    width: 25%;
    top: 0;
    gap: var(--gap-lg);
    padding-right: 5px;
    border-radius: var(--border-radius);
    grid-template-rows: minmax(38%, 1fr) 4fr;
  }
}

/* ------SuggestedUser Card--------*/

.card {
  display: flex;
  width: 100%;
  gap: var(--gap-md);
  cursor: pointer;
  padding: 13px 3px;
  align-items: center;
  justify-content: flex-start;

  .details {
    h6 {
      font-weight: 400;
      font-size: var(--fs-sm);

      @media screen and (min-width: 961px) {
        font-size: var(--fs-md);
      }
    }
    p {
      opacity: 0.7;
      color: var(--text-color);
      font-size: var(--fs-sm);
    }
  }

  .image {
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
}

//Top Learners
.container {
  height: 100%;
  border: 2px solid var(--border-color);

  h3 {
    font-weight: var(--fw-medium);
    font-size: var(--fs-md);
  }

  .cards {
    height: 100%;
    overflow: auto;
  }

  @media screen and (min-width: 961px) {
    height: auto;
    overflow: hidden;
    padding: 5px 5px;
    background-color: var(--background);
    .divider {
      width: 100%;
      height: 0.3px;
      opacity: 0.3;
      background-color: var(--text-color);
    }
  }

  @media screen and (min-width: 1300px) {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 0 15px;
    border-radius: var(--border-radius);
    box-shadow: 1px 1px 5px var(--shadow);

    h2 {
      font-size: var(--fs-lg);
      text-transform: none;
    }
  }

  .divider {
    width: 100%;
    height: 0.3px;
    opacity: 0.3;
    background-color: var(--text-color2);
  }
}
