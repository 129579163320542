.card {
  display: flex;
  width: 100%;
  gap: var(--gap-md);
  height: auto;
  margin: 8px 0;
  overflow: hidden;
  border-radius: var(--border-radius);
  padding-bottom: 15px;
  flex-direction: column;
  background-color: var(--background-secondary);

  header {
    display: grid;
    width: 100%;
    padding: 0 10px;
    padding-top: 10px;
    grid-column-gap: 5px;
    grid-template-columns: auto 40px;

    h3 {
      width: 100%;
      font-size: var(--fs-md);
      display: inline;
      font-weight: var(--fw-medium);
      color: var(--text-color);
      text-overflow: ellipsis;
    }

    .right {
      display: flex;
      gap: 5px;
      svg {
        display: flex;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        border-radius: var(--border-radius);
        color: var(--text-color);
        &:hover {
          color: var(--brand-color);
          box-shadow: var(--shadow);
        }
      }
    }
  }

  .notes_text {
    display: block;
    width: 100%;
    padding: 5px 10px;
    font-weight: var(--fw-light);
    text-align: left;
    overflow: hidden;
    user-select: text;
    font-size: var(--fs-sm);
    line-height: 20px;
    white-space: pre-wrap;
    color: var(--text-color);

    p {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .expand {
    p {
      -webkit-line-clamp: unset;
    }
  }

  footer {
    display: flex;
    cursor: pointer;
    padding: 10px;
    margin-top: -50px;
    align-items: center;
    box-shadow: var(--shadow);
    transform: translateY(50px);
    justify-content: space-between;
    background-color: var(--card-normal);

    button {
      display: flex;
      gap: 5px;
      color: var(--black);

      &:hover {
        color: var(--brand-color);
      }
    }
  }

  &:hover,
  &:focus {
    footer {
      transform: translateY(15px);
      transition: transform 0.3s ease-in;
    }
  }
}
