.main {
  display: none;
  @media only screen and (min-width: 961px) and (orientation: landscape) {
    display: inline-block;
  }

  @media screen and (min-width: 1025px) {
    display: inline-block;
  }

  .drawer {
    width: 75px;
    height: 100vh;
    z-index: 97 !important;

    &.true {
      width: 240px;
    }

    .drawer-open {
      width: 240px;
      padding: 0 10px;
      color: var(--text-color);
      background-color: var(--background);
    }
    .drawer-close {
      width: 75px;
      padding: 0 2px;
      overflow: hidden;
      color: var(--text-color);
      background-color: var(--background);
    }
  }
}

.close-box {
  display: flex;
  position: fixed;
  height: 40px;
  width: 30px;
  top: 170px;
  cursor: pointer;
  margin-left: -40px;
  align-items: center;
  border: var(--border);
  justify-content: center;
  background: var(--background);
  svg {
    width: 15px;
    height: 15px;
    color: var(--text-color);
  }

  &:has(.open) {
    margin-left: -40px;
  }

  &:has(.close) {
    margin-left: -30px;
  }
}

.open {
  transform: rotate(0deg);
  transition: 0.1s ease-in-out;
}

.close {
  transform: rotate(-180deg);
  transition: 0.1s ease-in-out;
}

.content-box {
  width: 100%;
  height: 100%;
  margin-top: 160px;

  .item-content {
    overflow: hidden auto;

    .divider {
      width: 100%;
      height: 1px;
      margin-bottom: 8px;
      background-color: var(--border-color);
    }

    .divider-close {
      width: 55px;
      margin: 0 auto;
    }
  }
}
