.container {
  width: auto;
  height: 100%;
  display: none;

  @media screen and (min-width: 961px) {
    display: grid;
    grid-template-columns: 5.2rem auto;
  }

  .saperator {
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    height: calc(100vh - 60px);
    background: var(--background);
    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);

    .toggle-btn {
      display: flex;
      right: 0;
      z-index: 1;
      width: 20px;
      height: 25px;
      position: fixed;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background: var(--background);
      border-radius: 5px 0px 0px 5px;

      &.toggle-close {
        right: 5.2rem;
        // border-radius: ;
      }
    }

    .open {
      transform: rotate(0deg);
      transition: 0.1s ease-in-out;
    }

    .close {
      transform: rotate(-180deg);
      transition: 0.1s ease-in-out;
    }

    .icons-list {
      display: flex;
      width: auto;
      height: 100%;
      z-index: 100;
      gap: 10px;
      padding: 0 5px;
      position: fixed;
      padding-top: 10px;
      flex-direction: column;
      justify-content: flex-start;
      background-color: var(--background-secondary);

      .icon-box {
        display: flex;
        width: auto;
        height: 38px;
        padding: 0 2px;
        cursor: pointer;
        line-height: 15px;
        border-radius: var(--border-radius);
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .icon-title {
          display: flex;
          font-size: var(--fs-sm);
          font-weight: var(--fw-medium);
        }
      }
    }
  }

  @media only screen and (min-width: 961px) {
    position: relative;

    .saperator {
      display: flex;

      .toggle-btn {
        height: 40px;
        border-left: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
      }

      .icons-list {
        .icon-box {
          width: 65px;
          height: 65px;
          border-radius: var(--border-radius);

          .icon-title {
            font-size: 9px;
            line-height: 10px;
          }
        }
      }
    }
  }
}

.visible {
  display: flex;
}

.hidden {
  display: none;
}

.sub-section {
  display: grid;
  height: 100%;
  overflow: auto;
  grid-template-rows: repeat(2, 1fr);
}

.video-sidebar {
  display: none;
  @media screen and (min-width: 961px) {
    display: flex;
  }
}

.drawer {
  width: 320px;
  // height: 100vh;
  // position: fixed;
  // z-index: 99 !important;

  .drawer-open {
    width: 320px;
    right: 20px;
    z-index: 98;
    margin-top: 60px;
    border-left: none;
    margin-left: 10px;
    background: var(--second-white);
  }
  .drawer-close {
    right: 20px;
    width: 0px;
    z-index: 98;
    margin-top: 60px;
    border-left: none;
    overflow-x: hidden;
    color: var(--text-color);
    background: var(--second-white);
  }

  @media (min-width: 1025px) {
    width: 360px;
    .drawer-open {
      width: 360px;
    }
    .drawer-close {
      width: 0px;
    }
  }
  @media (min-width: 1281px) {
    width: 400px;

    .drawer-open {
      width: 400px;
    }
    .drawer-close {
      width: 0px;
    }
  }
  @media (min-width: 1400px) {
    width: 440px;

    .drawer-open {
      width: 440px;
    }
    .drawer-close {
      width: 0px;
    }
  }

  &.close {
    width: 0px;
  }
}
