.container {
  display: flex;
  gap: var(--gap-lg);
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 681px) {
    justify-content: flex-start;
  }

  .btn {
    padding: 10px 5px;
  }
}
