.ql-picker-label {
  color: var(--text-color);
}

.ql-picker-label svg {
  color: var(--text-color);
}

.ql {
  color: var(--black);
}
.ql1 {
  border: 1px solid var(--red);
}

.ql-editor {
  height: 200px;
}
#formatter .requiredField {
  color: var(--red-shade);
}

#formatter p {
  color: var(--text-color);
}
.ql-container p {
  color: var(--text-color);
}
.ql-snow .ql-stroke {
  stroke: var(--text-color);
}
.ql-toolbar.ql-snow {
  border-color: var(--black);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}
.ql-container.ql-snow {
  border-color: var(--black);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.ql-editor.ql-blank::before {
  opacity: 0.6;
  color: var(--text-color);
}
