// Accordion styles

.accordion-item {
  width: 99%;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--shadow);
  }
}

.accordion-title {
  display: grid;
  top: 0;
  z-index: 2;
  width: 100%;
  position: sticky;
  padding: 20px 10px;
  border: var(--border);
  color: var(--text-color);
  grid-template-columns: auto 80px;
  border-radius: var(--border-radius);
  background-color: var(--background);

  h3 {
    display: block;
    font-size: var(--fs-md);
    font-weight: var(--fw-medium);
  }

  svg {
    width: 20px;
    height: 20px;
    color: var(--text-color);
  }

  .arrow {
    transform: rotate(180deg);
  }

  &.open {
    ~ .accordion-content {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    ~ ul > .accordion-content {
      display: flex;
      width: 100%;
    }
  }

  .accordian-right {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media screen and (min-width: 961px) {
      justify-content: center;
    }
  }
}

.hidden {
  opacity: 0.6;
}

.accordion-content {
  padding: 0;
  z-index: 1;
  margin: 10px 0;
  display: none;
  background-color: transparent;
}
