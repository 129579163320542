.container {
  display: flex;
  align-items: center;
  height: 76vh !important;
  width: 65vw !important;

  .img-container {
    width: 50%;
    display: flex;
    align-items: center;
    padding-bottom: 0px;
    justify-content: center;
    height: 100% !important;
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    border-right: 1px solid var(--border-color);

    img {
      width: 90%;
      height: 100%;
      display: flex;
      align-items: center;
      object-fit: contain;
      justify-content: center;
    }
  }

  .content {
    height: 100%;
    display: flex;
    padding: 20px;
    width: 50% !important;
    flex-direction: column;

    .content-container {
      overflow: scroll;
      scroll-behavior: auto;
      height: 75%;
      display: flex;
      justify-content: center;
    }

    .sub-container {
      // padding: 10px;
      display: flex;
      gap: var(--gap-lg);
      flex-direction: column;

      .test {
        display: flex;
        gap: var(--gap-sm);
        flex-direction: column;
        color: var(--text-color);
      }

      h2 {
        line-clamp: 2;
        text-transform: none;
        -webkit-line-clamp: 2;
        font-size: var(--fs-lg);
        @media screen and (min-width: 1500px) {
          font-size: var(--fs-xl);
        }
      }

      .description {
        font-size: var(--fs-sm);
        color: var(--text-secondary);
        @media screen and (min-width: 1500px) {
          font-size: var(--fs-lg);
        }
      }

      .test-details {
        p {
          font-weight: var(--fs-md);
          color: var(--text-secondary);
          @media screen and (min-width: 1500px) {
            font-size: var(--fs-lg);
          }
        }
      }

      .about-platform {
        display: flex;
        gap: var(--gap-sm);
        flex-direction: column;
        color: var(--text-color);

        .title {
          text-transform: none;
        }

        ul {
          display: flex;
          padding-left: 15px;
          gap: var(--gap-sm);
          flex-direction: column;
          align-items: flex-start;

          li {
            font-weight: var(--fs-md);
            color: var(--text-secondary);
            line-height: 1.5;
            @media screen and (min-width: 1500px) {
              font-size: var(--fs-lg);
            }
          }
        }
      }
    }

    .header {
      width: 100%;
      height: 10%;
      display: flex;
      align-self: flex-end;
      justify-content: flex-end;
      color: var(--text-color);

      .icon {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }

    .second-screen {
      max-height: 75%;
      width: 100%;
      display: flex;
      gap: var(--gap-section);
      flex-direction: column;

      .second-container {
        display: flex;
        gap: var(--gap-xl);
        flex-direction: column;
        color: var(--text-color);
        width: 100%;

        .contents {
          display: flex;
          gap: var(--gap-md);
          flex-direction: column;
          color: var(--text-color);
          padding: 0px;
          // width: 100%;

          .title {
            line-clamp: 2;
            text-transform: none;
            -webkit-line-clamp: 2;
            font-size: var(--fs-lg);

            @media screen and (min-width: 1500px) {
              font-size: var(--fs-xl);
            }
          }
          .eligibility {
            display: flex;
            gap: var(--gap-sm);
            align-items: center;

            .approvalText {
              color: var(--green);
            }

            .icon {
              width: 20px;
              height: 20px;
              color: red;
            }
            .icon {
              width: 20px;
              height: 20px;
              color: var(--text-color);
            }
            p {
              font-size: var(--fs-sm);
              font-weight: var(--fw-medium);
              color: var(--text-secondary);
              @media screen and (min-width: 1500px) {
                font-size: var(--fs-md);
              }
            }
            .checked {
              // width: 20px;
              // height: 20px;
              color: var(--success-color);
              path {
                stroke: 2px solid black;
              }
            }
            .corssed {
              // width: 20px;
              // height: 20px;
              color: red;
              path {
                stroke: 2px solid black;
              }
            }
          }
        }
      }
      .about-platform {
        display: flex;
        gap: var(--gap-md);
        flex-direction: column;
        color: var(--text-color);

        .platform-title {
          line-clamp: 2;
          -webkit-line-clamp: 2;
          font-size: var(--fs-lg);
          @media screen and (min-width: 1500px) {
            font-size: var(--fs-xl);
          }
        }

        ul {
          display: flex;
          gap: var(--gap-md);
          padding-left: 15px;
          flex-direction: column;
          align-items: flex-start;
          color: var(--text-secondary);

          li {
            font-size: var(--fs-sm);
            @media screen and (min-width: 1500px) {
              font-size: var(--fs-md);
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      height: 15%;
      gap: var(--gap-md);
      flex-direction: column;
      justify-content: flex-end;
      // padding: 0px 10px;

      .progress-container {
        width: 100%;
        height: 1%;
        background-color: transparent;

        .progressbar {
          height: 2px;
          background-color: var(--brand-color);
          transition: all 0.2s ease-in-out;
        }
      }
      .btn-container {
        display: flex;
        gap: var(--gap-md);
        align-self: flex-end;

        .prev-btn {
          display: flex;
          padding: 8px 12px;
          align-self: flex-end;
          color: var(--background);
          background-color: var(--text-color);
        }

        .btn {
          display: flex;
          padding: 8px 18px;
          align-self: flex-end;
        }
      }
    }
  }
}
