.player {
  display: flex;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.seekButtons {
  display: none;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  // border: 1px solid red;
  background: rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);

  div {
    display: flex;
    width: 90%;
    z-index: 1;
    height: 40px;
    margin: auto;
    align-items: center;
    justify-content: space-evenly;
  }

  svg {
    width: 4em;
    height: 4em;
    padding: 5px;
    z-index: 2;
    cursor: pointer;
    border-radius: 50%;
    color: var(--white);
    box-shadow: var(--shadow);
    // background-color: var(--white);
    background: linear-gradient(to top, #333333ab, #d3d2d2);

    &:focus {
      color: var(--brand-color);
    }
  }
}

.non-auth-timer-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  // background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 0px 0px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

video:focus ~ .seekButtons {
  display: flex;
  transition: ease 0.3s;

  @media screen and (min-width: 641px) {
    display: none;
  }
}

.vjs-matrix {
  width: 100% !important;
  height: auto !important;
  aspect-ratio: 16/10 !important;

  /* smartphone landscape */
  @media only screen and (min-width: 481px) and (orientation: landscape) {
    /* hi2 */
    aspect-ratio: 16/7 !important;
  }

  /* portrait tablets */
  @media only screen and (min-width: 641px) and (orientation: portrait) {
    /* hi3 */
    height: auto !important;
  }

  /* Tablet landscape */
  @media only screen and (min-width: 961px) and (orientation: landscape) {
    aspect-ratio: 16/9 !important;
  }

  @media screen and (min-width: 1025px) {
    height: auto !important;
    aspect-ratio: 16/9 !important;
  }

  @media only screen and (min-width: 1281px) {
    /* hi7 */
    height: 60vh !important;
  }

  @media only screen and (min-width: 1700px) {
    /* hi7 */
    height: 78vh !important;
  }

  /* big Tablet screens */
  @media only screen and (min-width: 1281px) and (min-height: 1024px) {
    /* hi7 */
    height: auto !important;
    aspect-ratio: 16/9 !important;
  }
}

/* Change the border of the big play button. */
.vjs-matrix .vjs-big-play-button {
  &::before {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }

  width: 2em !important;
  height: 2em !important;
  border-radius: 50% !important;
  color: var(--brand-color) !important;
  border-color: var(--text-color2) !important;

  .vjs-icon-placeholder {
    display: flex;
    align-items: center;
    &::before {
      position: relative;
      font-size: 1em;

      @media screen and (min-width: 641px) {
        font-size: 2em;
      }
    }
  }

  @media screen and (min-width: 641px) {
    width: 3em;
    height: 3em;
  }
}

.vjs-default-skin {
  overflow: hidden;
}

.vjs-default-skin .vjs-control {
  width: 4em;
  align-items: center;

  @media screen and (min-width: 641px) {
    width: 5em;
  }
}

.vjs-default-skin.vjs-has-started .vjs-control-bar {
  -webkit-transition: bottom 0.3s;
  transition: bottom 0.3s;
}
.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing
  .vjs-control-bar {
  opacity: 1;
  bottom: -35px;
  visibility: visible;
}
.vjs-default-skin.vjs-user-inactive.vjs-playing:hover .vjs-progress-control {
  /* Force using small progress when vjs-control-bar is hidden */
  font-size: 0.3em;
}
.vjs-default-skin.vjs-using-native-controls .vjs-control-bar,
.vjs-default-skin.vjs-controls-disabled .vjs-control-bar,
.vjs-default-skin.vjs-error .vjs-control-bar {
  visibility: hidden;
}

/* Style the control bar background */
.vjs-default-skin .vjs-control-bar {
  height: 45px;
  background: linear-gradient(to bottom, transparent, #333333);
}

/* Style the control bar icons and buttons */
.vjs-default-skin .vjs-control-bar button {
  color: #fff; /* YouTube control bar icon color */
  position: relative;

  .vjs-icon-placeholder {
    font-size: var(--fs-sm) !important;
    @media screen and (min-width: 641px) {
      font-size: var(--fs-sm) !important;
    }
  }
}

/* Style the play/pause button */
.vjs-default-skin .vjs-play-control:before {
  font-size: var(--fs-lg) !important;
}

/* Style the volume and mute button */
.vjs-default-skin .vjs-volume-menu-button:before,
.vjs-default-skin .vjs-mute-control:before {
  font-size: var(--fs-lg);
}

/* Style the progress bar */
.vjs-default-skin .vjs-progress-control {
  height: 10px;
  width: 100%;
  z-index: 2;
  position: absolute;
}

.vjs-default-skin .vjs-progress-holder {
  background: #333; /* YouTube progress bar background color */
}

.vjs-default-skin .vjs-load-progress,
.vjs-default-skin .vjs-play-progress {
  background: #ff8000; /* YouTube progress bar color */

  &::before {
    font-size: var(--fs-xl);
    box-shadow: var(--shadow);
  }
}

/* Style the time display */
.vjs-default-skin .vjs-time-control {
  color: #fff !important; /* YouTube time display color */
  font-size: var(--fs-sm) !important;
}

.vjs-default-skin .vjs-current-time,
.vjs-default-skin .vjs-duration {
  display: flex !important;
}

/* Style the fullscreen button */
.vjs-default-skin .vjs-fullscreen-control:before {
  font-size: var(--fs-lg) !important;
}

/* Style the playrate button */
.vjs-default-skin .vjs-playback-rate {
  .vjs-playback-rate-value {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    font-size: var(--fs-lg) !important;
    justify-content: center !important;

    @media screen and (min-width: 641px) {
      font-size: var(--fs-lg) !important;
    }
  }
}

/* Style the Menu */
.vjs-menu {
  z-index: 3;
  margin-bottom: 2em !important;
  ul {
    border-radius: 5px;
    max-height: 100px;

    li {
      padding: 5px;

      &:hover {
        color: var(--brand-color);
        background-color: rgba(51, 51, 51, 0.4);
      }
    }
  }
}

.vjs-menu-item-text {
  font-weight: var(--fw-medium) !important;
}

/* Style the duration button */
.vjs-time-control {
  min-width: auto !important;
  display: block !important;
  padding: 0 3px !important;
  width: auto !important;
  .vjs-duration {
    display: block;
  }
}

.vjs-current-time {
  margin-left: 0.5em;
}

.vjs-time-divider {
  display: flex !important;
  align-items: center !important;
}

.vjs-remaining-time {
  display: none !important;
}

/* Hide the big play button in the center of the video */
// .vjs-big-play-button {
//   display: none;
// }

/* Style the error display */
.vjs-error-display {
  color: #fff;
  background: #d9534f; /* Bootstrap's error alert background color */
  border: 1px solid #d9534f; /* Bootstrap's error alert border color */
}

.vjs-button > .vjs-icon-placeholder:before {
  font-size: var(--fs-lg);
  position: relative;

  @media screen and (min-width: 641px) {
    font-size: var(--fs-2xl);
  }
}

.video-js .vjs-custom-control-spacer {
  display: block;
}

.vjs-spacer {
  flex: 1;
}

// media queries

@media screen and (max-width: 615px) {
  .video-js .vjs-slider {
    height: 5px;
  }
}

// /* Add this CSS to your stylesheet or in a <style> tag in the HTML */
// .vjs-default-skin .vjs-play-control,
// .vjs-default-skin .vjs-pause-control {
//   transition: opacity 0.2s ease-in-out;
// }

// .vjs-paused .vjs-play-control {
//   opacity: 1;
// }

// .vjs-playing .vjs-play-control,
// .vjs-paused .vjs-pause-control {
//   opacity: 0;
// }

// .video-js,
// .vjs-poster {
//   color: var(--heading) !important;
//   background-color: transparent !important;
// }

// .vjs-matrix .vjs-tech {
//   /* object-fit: cover; */
//   object-fit: contain;
// }

// .vjs-matrix .vjs-control-block {
//   display: block;
// }

// .vjs-matrix .vjs-control:before {
//   font-family: VideoJS;
//   font-size: var(--fs-2xl);
//   line-height: 2;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   text-align: center;
//   text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
// }

// .vjs-matrix.video-js {
//   color: var(--text-color);
// }

// .vjs-matrix .vjs-menu {
//   max-height: 100px;
// }

// .vjs-matrix .vjs-menu-item-text {
//   font-weight : var(--fw-medium);;
// }

// /* Change the border of the big play button. */
// .vjs-matrix .vjs-big-play-button {
//   border-color: var(--text-color2);
//   left: 50%;
//   top: 50%;
//   width: 60px;
//   height: 60px;
//   border-radius: 50%;
//   color: var(--sidebar-tab-active);
//   transform: translate(-50%, -50%);
// }

// /* Change the play btn size */
// .vjs-matrix .vjs-icon-placeholder:before {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: var(--fs-2xl);
// }

// /* Change the color of various "bars". */
// .vjs-matrix .vjs-volume-level,
// .vjs-matrix .vjs-play-progress,
// .vjs-matrix .vjs-slider-bar {
//   background: var(--sidebar-tab-active);
// }

// .vjs-matrix .vjs-watermark-content {
//   opacity: 0;
// }

// .vjs-matrix .vjs-watermark-content img {
//   object-fit: cover;
//   width: 170px;
// }

// .vjs-matrix .vjs-control {
//   width: 25px;
// }

// .vjs-matrix .vjs-picture-in-picture-control {
//   display: none;
// }

// .vjs-matrix .vjs-control-bar {
//   display: flex;
//   width: 94%;
//   height: 3em;
//   margin: 4px 3%;
//   align-items: center;
//   border-radius: 10px;
//   background-color: var(--vd-control-bar);
// }

// .vjs-progress-control .vjs-load-progress,
// .vjs-progress-control .vjs-play-progress {
//   border-radius: 4px;
// }

// .vjs-matrix .vjs-progress-control > .vjs-slider {
//   height: 15px;
//   z-index: 49;
//   border-radius: 4px;
// }

// .vjs-matrix .vjs-progress-control:hover {
//   z-index: 55;
//   font-size: var(--fs-md);
//   visibility: visible;
// }

// .vjs-matrix .vjs-play-progress:before {
//   content: none;
// }

// .vjs-matrix .vjs-load-progress div {
//   background-color: var(--sidebar-tab-hover);
//   z-index: 49;
// }

// .vjs-matrix .vjs-time-control {
//   display: inline;
//   min-width: 0px;
//   padding: 2px 1px;
//   left: 65px;
//   position: absolute;
//   font-weight: bold;
//   font-size: 10px;
//   z-index: 52;
//   top: 0;
//   height: 0px;
//   user-select: none;
//   color: var(--common-text-color);
// }

// .vjs-menu-content {
//   width: 75% !important;
//   padding: 5px 2px;
//   border-radius: 5px;
//   color: var(--common-text-color);
// }

// /* Hide scrollbar for Chrome, Safari and Opera */
// .vjs-menu-content::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// .vjs-menu-content {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

// .vjs-matrix .vjs-current-time::after {
//   padding-right: 0;
// }

// .vjs-matrix .vjs-time-control,
// .vjs-matrix .vjs-time-control * {
//   user-select: none;
//   pointer-events: none;
// }

// .vjs-modal-dialog-content {
//   color: rgba(255, 255, 255, 0.95);
//   font-size: 5rem;
// }

// @media screen and (max-width: 1024px) {
//   .vjs-matrix .vjs-duration::before {
//     content: "/";
//     display: flex;
//     margin: 0 10px;
//     margin-top: -0.8px;
//     text-shadow: none;
//     box-shadow: none;
//   }

//   .vjs-matrix .vjs-duration {
//     left: 80px;
//   }

//   .vjs-matrix .vjs-duration-display {
//     padding-left: 14px;
//   }
// }

// .vjs-matrix .vjs-remaining-time {
//   display: none;
// }

// .vjs-matrix .vjs-time-divider {
//   display: none;
// }

// .vjs-matrix .vjs-current-time,
// /* .vjs-matrix .vjs-time-divider, */
// .vjs-matrix .vjs-duration {
//   display: inline;
//   margin: -1.5px 1px;
// }

// .vjs-matrix .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover,
// .video-js .vjs-volume-panel.vjs-volume-panel-horizontal:active,
// .video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
//   width: unset;
// }

// .vjs-matrix .vjs-volume-control {
//   display: none;
// }

// .vjs-matrix .vjs-playback-rate-value {
//   display: flex;
//   width: inherit;
//   height: inherit;
//   align-items: center;
//   justify-content: center;
// }

// .vjs-matrix .vjs-poster {
//   background-position: center;
//   background-size: cover;
//   object-fit: cover;
// }
