/* common styles */
.profileModal-input-field-container {
  display: flex;
  width: 100%;
  gap: var(--gap-sm);
  flex-direction: column;

  //Input label style
  .input-label {
    line-height: 1.25;
    font-size: var(--fs-md);
    color: var(--text-color);
    font-weight: var(--fw-medium);
  }

  // input divider
  .input-divider {
    width: 100%;
    display: flex;
    gap: var(--gap-md);
  }
  .input-divider-section-left {
    width: 60%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .input-divider-section-right {
    width: 40%;
  }
  // for select input field
  .input-field-select {
    padding: 1px;
    margin-top: 4px;
    border: var(--border);
    border-radius: var(--border-radius);
  }

  .control-color {
    background-color: var(--second-white);
    border: 0px solid var(--second-white);
    color: var(--text-color);
    width: 100%;
  }
  .color-input {
    line-height: 20px;
    color: var(--text-color);
  }
  .color {
    background-color: var(--background);
    color: var(--text-color);
  }
  .color-selected {
    background-color: var(--text-box);
    color: var(--text-color);
  }

  /* ------------TextArea styles ------------ */
  .text-area-container {
    display: flex;
    width: 100%;
    margin-top: 4px;
    align-items: center;
    border: var(--border);
    flex-direction: column;
    border-radius: var(--border-radius);
  }

  .text-area-input {
    resize: none;
    line-height: 20px;
    user-select: auto;
    overflow: auto auto;
    overflow-y: scroll;
    display: flex;
    width: 100%;
    height: 200px;
    outline: none;
    border: 5px none;
    padding: 10px 12px;
    font-size: var(--fs-md);
    border-radius: var(--border-radius);
    color: var(--heading);
    text-transform: none !important;
    background-color: var(--second-white);
  }
  .text-area-wordCount {
    width: 100%;
    opacity: 0.8;
    text-align: end;
    font-size: var(--fs-sm);
    color: var(--text-color);
  }
}
