.cr-main-cont {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  flex: 1;
  height: 100%;
  overflow: hidden auto;
}

.cr-rating-row {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.cr-rating-row h3,
.cr-textfield-msg h3 {
  color: var(--heading);
  line-height: 1.25;
}

.cr-rating-cont {
  display: flex;
  align-items: center;
  gap: 2px;
}

.cr-rating-cont svg {
  height: 21px;
  width: auto;
  fill: var(--paragraph);
  opacity: 0.85;
}

.cr-rating-cont svg.cr-rated {
  fill: var(--brand-color);
  opacity: 1;
}

.cr-textfield-msg {
  margin-top: 10px;
}

.cr-msg-cont {
  height: 100px;
  width: 100%;
  border-radius: var(--border-radius);
  font-family: inherit;
  background-color: var(--second-white);
  color: var(--heading);
  line-height: 1.5;
  margin-top: 8px;
  border: none;
  outline: none;
  resize: none;
  padding: 10px;
  overflow: hidden auto;
}

@media screen and (min-width: 401px) {
}

@media screen and (min-width: 601px) {
  .cr-main-cont {
    gap: var(--gap-lg);
  }
}

@media screen and (min-width: 801px) {
}

@media screen and (min-width: 1001px) {
  .cr-main-cont {
    gap: var(--gap-xl);
  }
}

@media screen and (min-width: 1201px) {
}

@media screen and (min-width: 1401px) {
}

.sn-textfield-row {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.sn-textfield {
  padding: 10px;
  border: none;
  outline: none;
  line-height: 1.5;
  border: var(--border);
  color: var(--heading);
  font-weight: var(--fw-medium);
  border-radius: var(--border-radius);
  background-color: var(--second-white);
}

.sn-textfield::placeholder {
  font-size: var(--fs-sm);
}

.sn-textfield input {
  color: var(--heading) !important;
}

@media screen and (min-width: 601px) {
  .sn-textfield-row {
    gap: 15px;
  }
}

@media screen and (min-width: 801px) {
  .sn-textfield {
    padding: 13px;
    border-radius: var(--border-radius);
  }
}

.sn-textfield:focus {
  outline: none;
}

.sn-btn-active {
  opacity: 1;
  cursor: pointer;
}

@media screen and (min-width: 500px) {
  .sn-msg-btn {
    height: 32px;
    border-radius: var(--border-radius);
  }
}

@media screen and (min-width: 801px) {
  .sn-textfield {
    padding: 13px;
    border-radius: var(--border-radius);
  }
}

@media screen and (min-width: 1001px) {
  .sn-textfield-row {
    gap: 20px;
  }
}

@media screen and (min-width: 1201px) {
  .sn-textfiel {
    padding: 10px 16px;
  }
}

@media screen and (min-width: 1401px) {
}

.sf-btns-cont {
  display: flex;
  gap: var(--gap-md);
  margin: 10px 0;
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: 601px) {
    .sf-btns-cont {
      gap: var(--gap-lg);
    }
  }

  @media screen and (min-width: 1001px) {
    .sf-btns-cont {
      gap: var(--gap-xl);
    }
  }
}
