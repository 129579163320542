.card {
  display: flex;
  gap: var(--gap-md);
  padding: 10px 20px;
  height: fit-content;
  border: var(--border);
  flex-direction: column;
  color: var(--text-color);
  border-radius: var(--border-radius);
  background-color: var(--background);

  .mainContent {
    display: flex;
    gap: var(--gap-sm);
    flex-direction: column;

    header {
      display: flex;
      align-items: center;
      font-size: var(--fs-sm);
      justify-content: space-between;

      .mentor {
        display: flex;
        gap: var(--gap-sm);
        align-items: center;
        border-radius: 50%;

        > img {
          width: 30px;
          height: 30px;
          border-radius: 50%;

          @media screen and (min-width: 641px) {
            width: 35px;
            height: 35px;
          }

          @media screen and (min-width: 961px) {
            width: 40px;
            height: 40px;
          }
        }

        p {
          display: flex;
          flex-direction: column;
          width: 100%;
          line-height: 22px;

          @media screen and (min-width: 641px) {
            display: inline-block;
          }

          span {
            line-height: 20px;
            font-size: var(--fs-sm);
            color: var(--brand-color);
            font-weight: var(--fw-medium);
            @media screen and (min-width: 641px) {
              font-size: var(--fs-md);
            }
          }
        }
      }

      .difficultyLevel {
        width: 70px;
        text-align: center;
        padding: var(--gap-sm);
        font-weight: var(--fw-medium);
        border-radius: var(--border-radius);
      }

      .easy {
        color: rgb(0, 148, 0);
      }

      .medium {
        color: var(--brand-color);
      }

      .hard {
        color: var(--red);
      }

      .status {
        padding: 5px 10px;
        color: var(--white);
        border: var(--border);
        text-transform: capitalize;
        border-radius: var(--border-radius);
        background-color: var(--second-white);

        &[aria-label="submitted"] {
          background-color: var(--green);
        }

        &[aria-label="pending"] {
          background-color: var(--yellow);
          color: var(--black);
        }

        &[aria-label="expired"] {
          background-color: var(--red);
        }
      }

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }

      @media screen and (min-width: 961px) {
        font-size: var(--fs-lg);
      }
    }

    > h2 {
      line-clamp: 2;
      margin: 5px 0;
      max-height: 40px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      font-size: var(--fs-md);
      -webkit-box-orient: vertical;

      @media screen and (min-width: 641px) {
        font-size: var(--fs-lg);
      }
    }

    p {
      line-clamp: 2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      white-space: pre-line;
      font-size: var(--fs-sm);
      -webkit-box-orient: vertical;

      &.showMore {
        line-clamp: unset;
        -webkit-line-clamp: unset;
      }

      & ~ span {
        cursor: pointer;
        margin: 3px 0px;
        width: fit-content;
        font-size: var(--fs-sm);
        color: var(--text-secondary);
        font-weight: var(--fw-medium);

        @media screen and (min-width: 641px) {
          font-size: var(--fs-md);
        }
      }

      a {
        color: var(--link-color);
        cursor: pointer;
      }

      @media screen and (min-width: 641px) {
        font-size: var(--fs-md);
      }
    }

    .compiler-div {
      display: flex;
      gap: var(--gap-sm);
      flex-direction: column;
      margin: var(--gap-sm) 0px;

      > a {
        display: flex;
        width: fit-content;
        align-items: center;
        padding: var(--gap-sm);
        font-size: var(--fs-sm);
        justify-content: center;
        color: var(--background);
        border-radius: var(--border-radius);
        background-color: var(--text-secondary);

        @media screen and (min-width: 641px) {
          font-size: var(--fs-md);
          padding: var(--gap-sm) var(--gap-md);
        }

        > svg {
          font-size: var(--fs-xl);

          @media screen and (min-width: 641px) {
            font-size: var(--fs-2xl);
          }
        }
      }

      > p {
        color: rgb(206, 80, 80);
        > a {
          color: rgb(206, 80, 80);
          text-decoration: underline;
        }
      }
    }

    .submit-action {
      display: flex;
      max-width: 40%;
      gap: var(--gap-sm);
    }

    .btn {
      min-width: 80px;
      padding: 5px 10px;
      gap: var(--gap-sm);
      border: var(--border);
      font-size: var(--fs-sm);
      color: var(--text-color);
      border-radius: var(--border-radius);
      background-color: var(--second-white);

      .icon {
        color: var(--brand-color);
      }
    }

    .form {
      display: flex;
      width: 100%;
      gap: var(--gap-md);
      flex-direction: column;
      justify-content: center;

      * {
        font-size: var(--fs-sm);
        @media screen and (min-width: 641px) {
          font-size: var(--fs-md);
        }
      }

      @media screen and (min-width: 961px) {
        width: 60%;
      }

      @media screen and (min-width: 1281px) {
        width: 50%;
      }

      @media screen and (min-width: 1681px) {
        width: 40%;
      }

      .btns {
        display: flex;
        width: 100%;
        gap: var(--gap-md);
        justify-content: center;

        .submit {
          background-color: var(--brand-color);
        }
      }

      .suggestions {
        textarea {
          height: 120px;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    justify-content: space-between;

    p {
      display: flex;
      line-height: 20px;
      font-size: var(--fs-sm);
      font-weight: var(--fw-medium);

      > svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
