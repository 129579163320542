.PaidModal {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  div {
    display: flex;
    justify-content: flex-end;
    .btn {
      border: 1px solid var(--brand-color);
      background-color: transparent;
      color: var(--brand-color);
      border-radius: var(--border-radius);
      margin-top: 10px;
      padding: 10px;
    }
  }
}
