.profileModal-input-field-container {
  display: flex;
  width: 100%;
  gap: var(--gap-sm);
  flex-direction: column;

  // for input field
  .input-field {
    display: flex;
    padding: 10px;
    border: var(--border);
    border-radius: var(--border-radius);
    background-color: var(--second-white);
  }
  //Input label style
  .input-label {
    line-height: 1.25;
    font-size: var(--fs-md);
    color: var(--text-color);
    font-weight: var(--fw-medium);
  }

  .media-input {
    color: var(--text-color2);
  }
  .media-input::file-selector-button {
    background-color: var(--text-box);
    border: none;
    color: var(--text-color2);
    padding: 5px;
    border-radius: var(--border-radius);
  }
  .media-display {
    width: 100%;
    margin-top: 10px;
    margin-left: 10px;
    height: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--gap-md);

    .media {
      width: 25%;
      height: 100%;
      position: relative;

      > img {
        width: 100%;
        height: 100%;
        border-radius: var(--border-radius);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .media-delete {
        width: 100%;
        height: 20%;
        top: 0;
        right: 0;
        color: var(--red);

        .media-icon {
          width: 20px;
          height: 20px;
          position: absolute;
          color: var(--text-color);
          color: red;
          border-radius: var(--border-radius);
          background-color: var(--text-box);
          background-color: red;
          top: 0;
          right: 0;

          .icon {
            width: 20px;
            height: 20px;
            color: red;
          }
        }
      }
    }
  }
}
