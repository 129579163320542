.player {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: #000000;
  /* box-shadow: 0px 10px 0px -3px rgba(0, 0, 0, 0.2); */
}
.player > video {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.controls {
  padding: 0;
  width: 100%;
  height: 48px;
  bottom: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(37, 37, 37, 0) 10%,
    rgba(37, 37, 37, 0.6) 80%
  );
  transition: all 0.2s ease-in 5s;
}

.player:hover .controls {
  bottom: 0;
  transition: all 0.2s ease-out;
}

.controls-main {
  width: calc(100% - 40px);
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.play-btn:hover.play-btn:before,
.play-btn:hover.play-btn:after {
  background: var(--brand-color);
}

.play-btn {
  width: 30px;
  height: 30px;
  position: relative;
  margin: auto;
  transform: rotate(-90deg) scale(0.8);
  transition:
    -webkit-clip-path 0.3s ease-in 0.1s,
    shape-inside 0.3s ease-in 0.1s,
    transform 0.8s cubic-bezier(0.85, -0.25, 0.25, 1.425);
}
.play-btn.paused {
  transform: rotate(0deg);
}

.play-icon {
  color: #fff;
}
