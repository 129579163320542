.container {
  display: flex;
  cursor: pointer;
  height: 185px;
  overflow: hidden;
  color: var(--heading);
  align-items: flex-start;

  .entityImage {
    height: 100%;
    margin-right: 16px;
    img {
      display: flex;
      max-width: 340px;
      min-width: 240px;
      height: auto;
      overflow: hidden;
      aspect-ratio: 16 / 9;
      border-radius: var(--border-radius);
    }
  }

  .mb-text-wrapper {
    display: none;
  }

  .text-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;

    a {
      .meta {
        .title-wrapper {
          display: grid;
          grid-template-columns: auto 80px;

          .entity-title {
            display: -webkit-inline-box;
            font-size: var(--fs-xl);
            font-weight: var(--fw-medium);
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .menu {
            svg {
              display: none;
              width: 25px;
              height: 25px;
              color: var(--heading);
            }
          }
        }
      }
      .description {
        margin-top: 10px;
        display: -webkit-inline-box;
        overflow: hidden;
        font-size: var(--fs-sm);
        -webkit-line-clamp: 1;
        color: var(--text-secondary);
        -webkit-box-orient: vertical;
      }
    }

    &:hover {
      .meta {
        .menu {
          svg {
            display: block;
          }
        }
      }
    }

    .byline-saperator {
      display: flex;
      margin-top: 5px;
      width: auto;
      flex-direction: column;

      .metadata {
        display: flex;
        margin: 0 0 8px;
        align-items: center;
        color: var(--text-secondary);

        span {
          font-size: var(--fs-xs);
        }
      }
      .channel-info {
        display: flex;
        margin: 0 0 5px;
        align-items: center;
        img,
        svg {
          margin-right: 10px;
        }
        h5 {
          font-size: var(--fs-sm);
          font-weight: 400;
          color: var(--text-secondary);
        }
      }

      .saperator {
        margin: 0 8px;
      }

      .badge {
        max-width: 220px;
        width: fit-content;
        padding: 3px 10px;
        border-radius: var(--border-radius);
        color: var(--heading);
        background-color: var(--text-box);
        h4 {
          font-size: var(--fs-sm);
          font-weight: var(--fw-medium);
          display: -webkit-inline-box;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  @media screen and (max-width: 641px) {
    height: auto;
    flex-direction: column;

    .entityImage {
      width: 100%;
      margin: 0;
      span {
        max-width: 100%;
        border-radius: 0px;
        aspect-ratio: 16 / 9;
      }
    }

    .text-wrapper {
      display: none;
    }

    .mb-text-wrapper {
      display: grid;
      gap: var(--gap-md);
      width: 100%;
      padding: 5px 2%;
      grid-template-columns: 32px auto;

      .title-wrapper {
        display: grid;
        gap: var(--gap-md);
        grid-template-columns: auto 20px;

        .entity-title {
          display: -webkit-inline-box;
          font-size: var(--fs-md);
          font-weight: var(--fw-medium);
          overflow: hidden;
          margin-bottom: 5px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .menu {
          svg {
            display: block;
            width: 18px;
            height: 18px;
            color: var(--heading);
          }
        }
      }

      .metadata {
        display: flex;
        width: 100%;
        gap: 5px;
        margin: 0 0 5px;
        align-items: center;
        color: var(--text-secondary);

        span {
          display: -webkit-inline-box;
          overflow: hidden;
          max-width: 120px;
          line-height: 14px;
          -webkit-line-clamp: 1;
          font-size: var(--fs-xs);
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
