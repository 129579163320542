.field-container {
  display: flex;
  width: 100%;
  border-radius: var(--border-radius);
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid var(--heading);

  .tag {
    left: 0;
    z-index: 2;
    width: 100%;
    bottom: 100%;
    padding: 10px;
    overflow: auto;
    position: absolute;
    margin-bottom: 5px;
    max-height: 300px;
    border-radius: var(--border-radius);
    color: var(--text-color);
    background-color: var(--background);
    -webkit-box-shadow: 0px -2px 5px 1px var(--light-shadow);
    -moz-box-shadow: 0px -2px 5px 1px var(--light-shadow);
    box-shadow: 0px -2px 5px 1px var(--light-shadow);

    .user {
      width: 100%;
      padding: 8px 0px;
      display: flex;
      justify-content: flex-start;
      color: var(--text-color);
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .image {
        // width: 40px;
        // height: 40px;
        // border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info {
        h1,
        p {
          color: var(--text-color);
        }
      }
    }

    hr {
      width: 100%;
      margin-bottom: 7px;
      margin-top: 7px;
      height: 0.2px;
      opacity: 0.6;
      background-color: var(--text-color2);
    }

    @media screen and (max-width: 641px) and (orientation: portrait) {
      top: 100%;
      bottom: auto;
    }

    @media screen and (max-width: 1025px) {
      top: 100%;
      bottom: auto;
    }
  }

  .face {
    display: flex;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    margin: 5px;
    position: absolute;
  }
  .field {
    display: flex;
    border: none;
    resize: none;
    height: 60px;
    flex: 1;
    padding: 5px;
    line-height: 1.25;
    margin-right: 5px;
    user-select: all;
    color: var(--text-color2);

    @media screen and (min-width: 941px) {
      height: 85px;
    }
  }
  #comment-text-input {
    -moz-user-select: all !important;
    -webkit-user-select: all !important;
    -khtml-user-select: all !important;
    user-select: all !important;
  }

  .field::placeholder {
    color: var(--text-color);
    opacity: 0.8;
  }

  .field:focus {
    outline: none;
  }

  button {
    display: flex;
    width: 30px;
    height: 30px;
    border: none;
    padding: 3px;
    cursor: pointer;
    right: 0;
    bottom: 0;
    margin: 5px;
    position: absolute;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background: var(--brand-color);
  }

  button:focus {
    outline: none;
  }

  @media screen and (max-width: 961px) {
    // box-shadow: var(--shadow);
  }
}
