.title {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  color: var(--black);
  align-items: center;
  justify-content: center;
  text-transform: none !important;

  font-size: var(--fs-3xl);

  @media screen and (min-width: 641px) {
    font-size: var(--fs-3xl);
    text-align: left;
  }
  @media screen and (min-width: 1025px) {
    font-size: var(--fs-4xl);
  }

  @media screen and (min-width: 1400px) {
    font-size: var(--fs-5xl);
  }

  span {
    font-size: inherit;
    font-family: inherit;
    font-weight: bolder;
    margin: 0 0.2rem;
    color: var(--brand-color);
    padding-bottom: 0 !important;
  }
}

.para-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: var(--fs-sm);
  margin-top: 0.4rem;
  color: black;

  @media screen and (min-width: 641px) {
    font-size: var(--fs-md);
  }

  @media screen and (min-width: 961px) {
    justify-content: flex-start;
  }
  @media screen and (min-width: 1600px) {
    font-size: var(--fs-lg);
  }

  span {
    margin: 0 8px;
    font-size: inherit;
    font-family: inherit;
    font-weight: bolder;
  }
}
