.container {
  display: flex;
  // margin-top: 10px;
  gap: var(--gap-sm);
  flex-direction: column;
  width: 85vw !important;
  // height: auto !important;

  @media screen and (min-width: 641px) {
    width: 53vw !important;
    gap: var(--gap-md);
  }
  @media screen and (min-width: 781px) {
    width: 58vw !important;
  }

  @media screen and (min-width: 1024px) {
    width: 45vw !important;
  }

  @media screen and (min-width: 1081px) {
    width: 35vw !important;
  }

  @media screen and (min-width: 1500px) {
    width: 30vw !important;
  }
  @media screen and (min-width: 2000px) {
    width: 25vw !important;
  }

  .sub-container {
    display: flex;
    gap: var(--gap-md);
    padding: 5px 15px;
    height: 170px;
    font-size: var(--fs-sm);
    overflow: scroll;
    flex-direction: column;
    scroll-behavior: smooth;

    .header {
      width: 100%;
      display: flex;
      align-self: flex-start;
      font-size: var(--fs-md);
      color: var(--text-color);
      font-weight: var(--fw-medium);

      h2 {
        width: 100%;
        line-clamp: 2;
        text-transform: none;
        -webkit-line-clamp: 2;
      }

      @media screen and (min-width: 641px) {
        // padding: 0px 30px;
        font-size: var(--fs-lg);
        font-weight: var(--fw-medium);
      }

      @media screen and (min-width: 1500px) {
        font-size: var(--fs-xl);
        font-weight: var(--fw-medium);
      }
    }

    p {
      color: var(--text-color);
      font-size: var(--fs-md);
      font-weight: var(--fw-light);

      @media screen and (min-width: 1500px) {
        font-size: var(--fs-lg);
      }
    }

    ul {
      display: flex;
      gap: var(--gap-md);
      text-align: justify;
      flex-direction: column;

      li {
        display: flex;
        line-height: 1.2;
        font-size: var(--fs-sm);
        align-items: flex-start;
        color: var(--text-color);
        justify-content: flex-start;

        @media screen and (min-width: 641px) {
          font-size: var(--fs-md);
        }
        @media screen and (min-width: 1500px) {
          font-size: var(--fs-lg);
        }
      }
    }

    @media screen and (min-width: 412px) {
      height: 145x;
      gap: var(--gap-lg);
    }
    @media screen and (min-width: 641px) {
      height: 140px;
      font-size: var(--fs-md);
    }
    @media screen and (min-width: 1500px) {
      height: 150px;
    }
  }

  .img {
    width: 100%;
    align-self: center;

    > img {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
    }
  }

  .footer {
    height: 15%;
    width: 100%;
    display: flex;
    gap: var(--gap-md);
    flex-direction: column;
    padding: 12px 15px;
    justify-content: flex-end;

    .progress-container {
      width: 100%;
      height: 1%;
      background-color: transparent;

      .progressbar {
        height: 2px;
        background-color: var(--brand-color);
        transition: all 0.2s ease-in-out;
      }
    }
    .btn-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 8px;
      gap: var(--gap-md);
      align-self: flex-end;

      .skip-btn {
        display: flex;
        align-items: center;
        padding: 6px 10px;
        color: var(--text-secondary);
        background-color: transparent;
        font-size: var(--fs-sm);
        padding: 0px;

        @media screen and (min-width: 641px) {
          padding: 8px 12px;
          font-size: var(--fs-md);
        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--gap-md);

        .prev-btn {
          display: flex;
          padding: 6px 10px;
          align-self: flex-end;
          color: var(--background);
          background-color: var(--text-color);
          font-size: var(--fs-sm);

          @media screen and (min-width: 641px) {
            padding: 8px 12px;
            font-size: var(--fs-md);
          }
        }

        .btn {
          display: flex;
          padding: 6px 10px;
          align-self: flex-end;
          font-size: var(--fs-sm);
          @media screen and (min-width: 641px) {
            padding: 8px 12px;
            font-size: var(--fs-md);
          }
        }
      }
    }
  }
}
